import React from "react";
import styles from "./RoomProfile.module.css";
import { avatars } from "../../../common/mediaConstants";
import { useDispatch, useSelector } from "react-redux";
import useGetNameCookie from "../../../common/getNameCookie";
import {
  AddWorld,
  EmailSvgIcon,
  LocationSvgIcon,
} from "../../../common/svgIcon";
import CDashboard from "../../dashboard/creator/CDashboard";
import MetaverseWorld from "../Metaverse World/MetaverseWorld";
import {
  openModal,
  setModalFor,
} from "../../../features/EnterWorldPopUp/EnterWorldSlice";
import {
  callCreateMetaverseClickCheck,
  callDeactivateRoomVisitor,
  callLogout,
} from "../../../common/axiosCalls";
import { useNavigate } from "react-router";
import {
  setAuthentication,
  setUserDetails,
} from "../../../features/Authentication/AuthenticationSlice";
import { emptyLikesData } from "../../../features/UserLikes/UserLikesSlice";

const RoomProfile = ({
  isOpen,
  setIsOpen,
  outSideRoom,
  setShowProfileInNav,
  logoutHandler, 
}) => {
  const authentication = useSelector((store) => store.authentication);
  const roomInfo = useSelector((store) => store.roomInfo);
  const cookies = useGetNameCookie();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const editInfoHandler = () => {
    if (outSideRoom) {
      setShowProfileInNav(null);
    } else {
      setIsOpen(null);
    }

    dispatch(
      setModalFor({
        purpose: "edit_info",
        data: {},
      })
    );
    dispatch(openModal());
  };

  const createWorldHandler = () => {
    if (authentication.isAuthenticated) {
      if (outSideRoom) {
        navigate("/create-room");
      } else {
        callDeactivateRoomVisitor({ room_name: roomInfo.room_name }).then(
          (response) => {
            navigate("/create-room");
          }
        );
      }
    } else {
      let registrationID = 10000000;
      const cookies = document.cookie
        .split(";")
        .map((item) => {
          return item.split("=");
        })
        .filter((item) => item[0].trim() === "registration_id");
      registrationID = cookies.length === 0 ? 10000000 : cookies[0][1];

      callCreateMetaverseClickCheck({
        id: registrationID,
      });
      navigate("/create-room");
    }
  };

  const RoomlogoutHandler = async () => {
    document.cookie = `avtar_name=avtarName; expires=${new Date(
      2000,
      0,
      1
    ).toUTCString()}`;
    document.cookie = `avtar_id=15; expires=${new Date(
      2000,
      0,
      1
    ).toUTCString()}`;


    try {
      await callDeactivateRoomVisitor({
        room_name: roomInfo.room_name,
      });

      const responseLogout = await callLogout();
      if (responseLogout) {
        localStorage.removeItem("token");
        dispatch(setAuthentication(false));
        dispatch(setUserDetails(null));
        dispatch(emptyLikesData());
      }
      navigate("/"); 
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <>
      <div className={styles["main_profile_overlay"]}>
        <div className={styles["profile_wrapper"]}>
          <button
            title="Close"
            className={styles["closeBtn"]}
            onClick={() =>
              outSideRoom ? setShowProfileInNav(null) : setIsOpen(null)
            }
          >
            <svg
              className={styles["close-button-svg"]}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
          </button>

          <div className={styles["main_side_wrapper"]}>
            <div className={styles["profile_info"]}>
              <div className={styles["profile_img"]}>
                <img
                  src={
                    avatars[
                      authentication.isAuthenticated
                        ? +authentication.userDetails?.avtar_id - 1
                        : cookies.avtar_id - 1
                    ]
                  }
                  alt="profile"
                />
              </div>
              <div className={styles["profile_details"]}>
                <ul>
                  <li className={styles["avtar_name"]}>
                    {authentication.isAuthenticated
                      ? authentication?.userDetails?.avtar_name
                      : cookies?.avtar_name}
                  </li>
                  <li className={styles["emailAndCountry"]}>
                    <EmailSvgIcon />
                    {authentication.isAuthenticated
                      ? authentication.userDetails.email
                      : null}
                  </li>
                  <li className={styles["emailAndCountry"]}>
                    <LocationSvgIcon />
                    {authentication.isAuthenticated
                      ? authentication.userDetails.ip_country
                      : null}
                  </li>
                </ul>
              </div>
            </div>

            {authentication.isAuthenticated && (
              <div className="right_side_anyalitics">
                <CDashboard />
                <div className={styles["btn_edit"]}>
                  <button
                    className={styles["edit_profile"]}
                    onClick={editInfoHandler}
                  >
                    Edit Profile
                  </button>
                  <button
                    className={styles["edit_profile"]}
                    onClick={() =>
                      outSideRoom ? logoutHandler() : RoomlogoutHandler()
                    }
                  >
                    Logout
                  </button>
                </div>
              </div>
            )}
          </div>

          <hr />
          <div className={styles["meta_world_room"]}>
            <div className={styles["meta_room"]}>
              <MetaverseWorld outSideRoom={outSideRoom} setIsOpen={setIsOpen} />
            </div>
            {/* <div className={styles["meta_create"]} onClick={createWorldHandler}>
              <span>
                <AddWorld />
              </span>
              <span className={styles["create_btn_world"]}>Create World</span>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default RoomProfile;
