import React from "react";
import styles from "./LoginPopup.module.css";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const LoginPopup = ({ isOpen, setIsOpen, isExplore }) => {
  const navigate = useNavigate();
  const loginHandler = () => {
    if (!isExplore) {
      navigate("/login");
    }
    else {
      navigate("/login");
    }

  };

  const ClosePopup = () => {
    setIsOpen(null)
  };
  return (
    <>
      <div className={styles["main_1"]}>
        <div className={styles["explore_wrapper"]}>
          <div className={styles["main_section"]}>
            <h3 className={styles["confirm_Box_heading"]}>
              You are not logged in. Please Login.
            </h3>
            <div className={styles["confirm__box_buttons"]}>

              <button
                className={styles["confirm_yes"]}
                onClick={ClosePopup}
              >
                Cancel
              </button>
              <button
                className={styles["confirm_cancel"]}
                onClick={loginHandler}
              >
                sign in
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPopup;
