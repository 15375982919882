import "./App.css";
import { useInitialLoad } from "./Web/common/initialload";
import ModalBackDrop from "./Web/common/ModalBackDrop";
import EnterWorldPopup from "./Web/components/postCard/EnterWorldPopup/EnterWorldPopup";
import { RoutesComp } from "./Web/routes/Routes";
import { useSelector } from "react-redux";
import "./Web/common/axiosCalls";
import ToastContainer from "./Web/components/ErrorHandling/ToastContainer";
import InfoPopup from "./Web/components/InfoPopup/InfoPopup";

function App() {
  useInitialLoad();
  const enterWorld = useSelector((store) => store.enterWorld);

  return (
    <div className="App">
      {enterWorld.showModal && <ModalBackDrop />}
      {enterWorld.showModal && <EnterWorldPopup />}
      <RoutesComp />
      <ToastContainer />
    </div>
  );
}

export default App;