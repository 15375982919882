import React, { useState } from "react";
import ImageFeature from "./ImageFeature";
import TextFeature from "./TextFeature";
import Styles from "./Features.module.css";

const Features = ({ index, feature ,diveInHandler }) => {

  const [innerwidth, setInnerwidth] = useState(window.innerWidth);
  let columnStyle = {};
  if(innerwidth <= 1100 && !((index % 2) === 0)) {
    columnStyle.flexDirection = 'column-reverse'
  }

  return (
    <>
      <div className={Styles["card-wrapper"]} style={columnStyle}>
        {index % 2 === 0 ? (
          <ImageFeature feature={feature}   />
        ) : (
          <TextFeature feature={feature} diveInHandler={diveInHandler} />
        )}
        {index % 2 === 0 ? (
          <TextFeature feature={feature}  diveInHandler={diveInHandler} />
        ) : (
          <ImageFeature feature={feature}  />
        )}
      </div>
    </>
  );
};

export default Features;
