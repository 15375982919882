import React, { useState } from "react";
import styles from "./RegistrationPopup.module.css";
import useGetNameCookie from "../../../../../common/getNameCookie";
import { avtarBodies, screens } from "../../../../../common/mediaConstants";
import {
  callDecodeToken,
  callLogin,
  callNewRegistration,
  callVisitRoom,
} from "../../../../../common/axiosCalls";
import { useDispatch } from "react-redux";
import {
  setAuthentication,
  setUserDetails,
} from "../../../../../features/Authentication/AuthenticationSlice";
import { setLikesArray } from "../../../../../features/UserLikes/UserLikesSlice";
import { useNavigate } from "react-router";
import { setRoomInfo } from "../../../../../features/RoomInfo/RoomInfoDataSlice";
import { setToast } from "../../../../../features/ErrorHandling/ErrorHandlingSlice";
import {
  openModal,
  setModalFor,
} from "../../../../../features/EnterWorldPopUp/EnterWorldSlice";
import {
  EyeCloseSvgIcon,
  EyeOpenSvgIcon,
  PencileEdit,
} from "../../../../../common/svgIcon";

const RegistrationPopup = ({ show, setShow, roomType }) => {
  const [isLoading, setIsLoading] = useState(false);
  const cookies = useGetNameCookie();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [showPassword, setShowPassword] = useState(null);

  const [formValue, setFormValue] = useState({
    room_name: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    if (e.target.name === "room_name") {
      // if (e.target.value[e.target.value.length - 1] === " ") {
      //   dispatch(
      //     setToast({
      //       type: "error",
      //       message: "Cannot use space in the room name field",
      //     })
      //   );
      //   return
      // };
      if (e.target.value.length > 18) {
        dispatch(
          setToast({
            type: "error",
            message: "Room Name must be at least 18 characters long.",
          })
        );
        return;
      }
    }

    if (e.target.name === "email") {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      setEmailIsValid(emailRegex.test(e.target.value));
      setFormValue({
        ...formValue,
        [e.target.name]: e.target.value.toLowerCase(),
      });
    } else {
      setFormValue({ ...formValue, [e.target.name]: e.target.value });
    }
  };

  const inputFields = [
    { name: "room_name", type: "text", placeholder: "Enter Metaverse Name" },
    { name: "email", type: "email", placeholder: "Enter Email" },
    {
      name: "password",
      type: showPassword ? "text" : "password",
      placeholder: "Enter Password",
    },
  ];

  // const mainWrapperClasses = `${styles["main_wrapper"]} ${
  //   show === true ? styles["main_wrapper--slide"] : ""
  // }`;

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!emailIsValid) {
      dispatch(
        setToast({
          type: "error",
          message: "Email format is invalid 1",
        })
      );
      return;
    }

    try {
      setIsLoading(true);
      const registrationData = await callNewRegistration({
        email: formValue.email,
        password: formValue.password,
        avtar_id: cookies.avtar_id,
        avtar_name: cookies.avtar_name,
        room_name: formValue.room_name,
        room_type: roomType,
      });
      localStorage.setItem("token", registrationData.data.data);
      await callLogin({ email: formValue.email, password: formValue.password });
      const decodeTokenData = await callDecodeToken();
      dispatch(setAuthentication(true));
      dispatch(setUserDetails(decodeTokenData.data.Data));
      dispatch(setLikesArray(decodeTokenData.data.rooms_liked_by_me));
      await callVisitRoom({ room_name: formValue.room_name });
      const RoomId = registrationData.data.room_id;
      dispatch(
        setRoomInfo({
          avtar_id: cookies.avtar_id,
          avtar_name: cookies.avtar_name,
          room_type: roomType,
          room_name: formValue.room_name,
          room_id: RoomId,
          is_admin: true,
          scene_name: roomType,
          socket_name: cookies.avtar_name,
        })
      );

      dispatch(
        setToast({
          type: "success",
          message: `${formValue.room_name} has been created successfully`,
        })
      );

      navigate(`/room/${roomType}/${formValue.room_name}/${RoomId}`);
      setIsLoading(false);
      setShow(null);
    } catch (error) {
      setIsLoading(false);
      dispatch(
        setToast({
          type: "error",
          message: error.message,
        })
      );
    }
  };
  const editProfileInfo = () => {
    dispatch(
      setModalFor({
        purpose: "update_info",
        data: {},
      })
    );
    dispatch(openModal());
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className={`${styles["main_1"]} `}>
        <div
          className={styles["registration-form"]}
          style={{ display: show ? "flex" : "none" }}
        >
          {isLoading && (
            <div className={styles["loading_wrapper"]}>
              <div className={styles["loading-div"]} />;
            </div>
          )}

          <div className={styles["reg-main-section"]}>
            <img src={screens[roomType]} alt="Metaverse Room" />
            <div className={styles["sub-section"]}>
              <img src={avtarBodies[cookies.avtar_id - 1]} alt="avtar" />
              <p className={styles["profileEdit"]} onClick={editProfileInfo}>
                <span>
                  <PencileEdit />
                </span>
              </p>
              <h4>{cookies.avtar_name}</h4>
            </div>
          </div>

          <div
            className={styles["svg_wrap"]}
            onClick={() => {
              setShow(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
          </div>
          <div className={styles["registration_wrapper"]}>
            {/* 1 */}

            {inputFields.map((field, index) => {
              return (
                <div className={styles["inputForm"]}>
                  <input
                    type={field.type}
                    name={field.name}
                    className={styles["input"]}
                    onChange={handleChange}
                    value={formValue[field.name]}
                    placeholder={field.placeholder}
                  />
                </div>
              );
            })}

            <span className={styles["showPassword"]} onClick={togglePassword}>
              {showPassword ? <EyeCloseSvgIcon /> : <EyeOpenSvgIcon />}
            </span>


            <button
              title="Create Metaverse"
              type="submit"
              className={styles["button-submit"]}
              onClick={submitHandler}
            >
              Create metaverse
            </button>
            <p className={styles["Already-registaer"]}>
              Already have an account?{" "}
              <span
                className={styles["span"]}
                onClick={() => navigate("/login")}
              >
                Sign in
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationPopup;
