import { useSelector } from "react-redux";
import { channelParameters } from "../../voice/voice";
import { socket } from "./SocketManager";

const useDeleteMyPlayer = () => {
  const roomInfo = useSelector((store) => store.roomInfo);
  let roomName = roomInfo.room_name;
  let socketName = roomInfo.avtar_name;
  channelParameters &&
    channelParameters.localAudioTrack &&
    channelParameters.localAudioTrack.setEnabled(false);

  return () => {
    if (roomName && socketName) {
      socket.emit("deleteMyPlayer", {
        roomName: roomInfo.room_name,
        socketName: roomInfo.avtar_name,
      });
    }
  }
};

export { useDeleteMyPlayer };
