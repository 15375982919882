import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPageNo } from '../../features/PaginationData/PaginationDataSlice';
import styles from "./pagination.module.css"

const SliderButton = (props) => {
  const pagination = useSelector(store => store.pagination);
  const dispatch = useDispatch();

  const sliderHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    switch(props.type) {
      case "increase": {
        pagination.pageNo !== pagination.totalPages 
        ? dispatch(setPageNo(pagination.pageNo + 1)) 
        : dispatch(setPageNo(pagination.pageNo));
        break;
      }
      case "decrease": {
        pagination.pageNo !== 1
        ? dispatch(setPageNo(pagination.pageNo - 1)) 
        : dispatch(setPageNo(pagination.pageNo));
        break;
      }
      default: {
        break;
      }
    }
  }

  return (
    <div onClick={sliderHandler} className={styles["slider_btn"]}>{props.children}</div>
  )
}

export default SliderButton