import React from "react";
import ToastMessage from "./ToastMessage";
import "./Toast.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const ToastContainer = () => {
  const toasts = useSelector((store) => store.errorHandling.toastArray);

  useEffect(() => {}, []);

  return (
    <section className="toast_container">
      {toasts?.map((toast, i) => {
        return <ToastMessage key={i} toast={toast} />;
      })}
    </section>
  );
};

export default ToastContainer;
