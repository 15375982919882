import { sceneObject } from "../../Files/CommonVariables";

   // ground={{
            //   height: envMapHeight,
            //   radius: envMapRadius,
            //   scale: envMapScale,
            // }}
            
const environmentMapPosition = {
    [sceneObject["mr"]]: { 
        height: 325,
        radius: 928,
        scale: 360,
    },
    [sceneObject["mr2"]]: { 
        height: 5.4,
        radius: 928,
        scale: 360,
    },
    [sceneObject["mr3"]]: { 
        height: 5.4,
        radius: 928,
        scale: 360,
    },
    [sceneObject["school"]]: { 
        height: 5.4,
        radius: 928,
        scale: 360,
    },
    [sceneObject["hr"]]: { 
        height: 5.4,
        radius: 928,
        scale: 360,
    },
    [sceneObject["hr"]]: { 
        height: 5.4,
        radius: 928,
        scale: 360,
    },
    [sceneObject["hall"]]: { 
        height: -155,
        radius: 1000,
        scale: 440,
    },
    [sceneObject["hall2"]]: { 
        height: -155,
        radius: 1000,
        scale: 440,
    },
    [sceneObject["tourism"]]: { 
        height: -155,
        radius: 1000,
        scale: 440,
    },
    [sceneObject["showroom"]]: { 
        height: -155,
        radius: 1000,
        scale: 440,
    },
    [sceneObject["showroom2"]]: { 
        height: -155,
        radius: 1000,
        scale: 440,
    },
  };
  export {environmentMapPosition}