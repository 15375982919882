import React from "react";
import styles from "./VisitorProfile.module.css";
import { useNavigate } from "react-router";
import useGetNameCookie from "../../common/getNameCookie";
import { avatars } from "../../common/mediaConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  openModal,
  setModalFor,
} from "../../features/EnterWorldPopUp/EnterWorldSlice";
import { callCreateMetaverseClickCheck } from "../../common/axiosCalls";

const VisitorProfile = ({ setIsOpen, isOpen }) => {
  const dispatch = useDispatch();

  const cookies = useGetNameCookie();
  const authentication = useSelector((store) => store.authentication);
  const navigate = useNavigate();

  const createWorldHandler = () => {
    if (authentication.isAuthenticated) {
      callDeactivateRoomVisitor({ room_name }).then((response) => {
        navigate("/create-room");
      });
    } else {
      let registrationID = 10000000;
      const cookies = document.cookie
        .split(";")
        .map((item) => {
          return item.split("=");
        })
        .filter((item) => item[0].trim() === "registration_id");
      registrationID = cookies.length === 0 ? 10000000 : cookies[0][1];

      callCreateMetaverseClickCheck({
        id: registrationID,
      });
      navigate("/create-room");
    }
  };

  const editInfoHandler = (purpose) => {
    dispatch(
      setModalFor({
        purpose: purpose,
        data: {},
      })
    );
    dispatch(openModal());
    setIsOpen(null);
  };

  return (
    <>
      <div title="Profile" className={styles["profile-logo"]}>
        <button
          className={styles["ProfileNew"]}
          onClick={() => setIsOpen("profile")}
        >
          <img
            className={styles["imgbtn"]}
            src={
              avatars[
                authentication.isAuthenticated
                  ? authentication.userDetails?.avtar_id - 1
                  : cookies.avtar_id - 1
              ]
            }
            alt="profile"
          />
        </button>
      </div>

      {isOpen === "profile" && (
        <div className={styles["main-1"]}>
          <div className={styles["profileContainer"]}>
            <button
              title="Close"
              className={styles["closeBtn"]}
              onClick={() => {
                setIsOpen(null);
              }}
            >
              <svg
                className={styles["close-button-svg"]}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M12 4.5v15m7.5-7.5h-15" />
              </svg>
            </button>
            {authentication.isAuthenticated ? (
              <p
                className={styles["edit_info"]}
                onClick={() => editInfoHandler("edit_info")}
              >
                Edit info
              </p>
            ) : (
              <p
                className={styles["edit_info"]}
                onClick={() => editInfoHandler("edit_avtar")}
              >
                Edit Avtar
              </p>
            )}
            <img
              src={
                avatars[
                  authentication.isAuthenticated
                    ? +authentication.userDetails?.avtar_id - 1
                    : cookies.avtar_id - 1
                ]
              }
              alt="profile"
            />
            <h3>
              {authentication.isAuthenticated
                ? authentication?.userDetails?.avtar_name
                : cookies?.avtar_name}
            </h3>

            <div className={styles["button_set"]}>
              <button
                title="Create World"
                className={`${styles["navbtn_profile"]} ${styles["btn-5"]}`}
                onClick={createWorldHandler}
              >
                <div className={styles["btn_text_1"]}>
                  <span>
                    <i className={`${styles["nav__icon"]} material-icons`}>
                      create_new_folder
                    </i>
                  </span>
                  <span className={styles["btn_heading"]}>Create World</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VisitorProfile;
