import { useInitialLoad } from "../../common/initialload";
import "./welcome/Welcome.css";
import VideoSlider from "../../components/videoSlider/VideoSlider";
import MeetingRoomCards from "../../components/world/meetingRoom/meetingRoomCards/MeetingRoomCards";

const Home = (props) => {
  useInitialLoad();
  return (
    <>
      <VideoSlider forRoom={props.forRoom} />

      {props.forRoom === "meeting-room" ? (
        <MeetingRoomCards forRoom={props.forRoom} />
      ) : null}
    </>
  );
};

export default Home;
