import { useEffect } from "react";
import { useDeleteMyPlayer } from "../socketManager/DeleteMyPlayer";
import { socket } from "../socketManager/SocketManager";

const DeletePlayer = () => {
  const deleteMyPlayer = useDeleteMyPlayer();

  useEffect(() => {
    //delete player when component unmount
    return () => {
      if (socket.connected) {
        deleteMyPlayer();
      }
    };
  }, []);

  
  return null;
};

export { DeletePlayer };
