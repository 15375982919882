import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import "./Login.css";
import useGetNameCookie from "../../common/getNameCookie";
import {
  openModal,
  setRoomName,
  setRoomType,
} from "../../features/EnterWorldPopUp/EnterWorldSlice";
import {
  setAuthentication,
  setUserDetails,
} from "../../features/Authentication/AuthenticationSlice";
import { callDecodeToken, callLogin } from "../../common/axiosCalls";
import { setLikesArray } from "../../features/UserLikes/UserLikesSlice";
import { setRoomInfo } from "../../features/RoomInfo/RoomInfoDataSlice";
import { setToast } from "../../features/ErrorHandling/ErrorHandlingSlice";
import Header from "../../layouts/header/Header";
import {
  EyeCloseSvgIcon,
  EyeOpenSvgIcon,
  FacebookLogoSvg,
  GoogleLogoSvg,
  LoginEmailIconSvg,
  LoginPasswordIconSvg,
} from "../../common/svgIcon";

function Login() {
  const location = useLocation();
  const cookies = useGetNameCookie();
  const roomInfo = useSelector((store) => store.roomInfo);
  const [showPassword, setShowPassword] = useState(null);

  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });
  const [submitError, setSubmitError] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginDetailHandler = (e) => {

    const { name, value } = e.target;
    if (name === "password" && value.length > 25) {
      dispatch(
        setToast({
          type: "error",
          message: "Password must be at least 15 characters long.",
        })
      );
      return;
    }
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
  };

  const registerNowHandler = () => {
    if (!cookies) {
      dispatch(openModal());
      dispatch(setRoomName(null));
      dispatch(setRoomType(null));
    } else {
      if (location?.state?.isRoomLoggedIn) {
        navigate("/register", {
          state: {
            isRoomLoggedIn: true,
          },
        });
      } else if (!location?.state?.isRoomLoggedIn) {
        navigate("/register", {
          state: {
            isRoomLoggedIn: false,
          },
        });
      } else {
        navigate("/register");
      }
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const handleError = (error) => {
      console.log(error);
      dispatch(
        setToast({
          type: "error",
          message: error.message,
        })
      );
    };



    try {
      const loginData = await callLogin(loginDetails);
      localStorage.setItem("token", loginData.data.result);
      const decodeTokenData = await callDecodeToken();
      dispatch(setAuthentication(true));
      dispatch(setUserDetails(decodeTokenData.data.Data));
      dispatch(setLikesArray(decodeTokenData.data.rooms_liked_by_me));

      if (location?.state?.isRoomLoggedIn) {
        const { room_name, room_type, avtarId, avatarname, room_id } = roomInfo;
        dispatch(
          setRoomInfo({
            avtar_id: avtarId,
            avtar_name: avatarname,
            room_type: room_type,
            room_id: room_id,
            room_name: room_name,
            is_admin: true,
          })
        );
        navigate(`/room/${room_type}/${room_name}/${room_id}`);
      } else {
        if (!location?.state?.isRoomLoggedIn) {
          navigate("/my-metaverse");
        } else {
          const { metaverseName, roomType, avtarId, avatarname, room_id } =
            location.state;
          dispatch(
            setRoomInfo({
              avtar_id: avtarId,
              avtar_name: avatarname,
              room_type: roomType,
              room_id: room_id,
              room_name: metaverseName.replaceAll("%20", " "),
              is_admin: true,
            })
          );

          navigate(`/room/${roomType}/${metaverseName}/${room_id}`);
        }
      }
    } catch (error) {
      handleError(error);
    }
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <Header />

      <div className="login-container">
        <div className="login_body">
          <form className="form_login" onSubmit={submitHandler}>
            <div className="login_heading_Main">Welcome, User !</div>
            <p className="login_heading">Please log in</p>
            <div className="flex-column">
              <label>Email </label>
            </div>
            <div className="inputForm">
              <LoginEmailIconSvg />
              <input
                className="login_input"
                type="email"
                placeholder="Registerd Email Id"
                value={loginDetails.userName}
                onChange={loginDetailHandler}
                name="email"
              />
            </div>
            <div className="flex-column">
              <label>Password </label>
            </div>
            <div className="inputForm">
              <LoginPasswordIconSvg />
              <input
                className="login_input"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={loginDetails.password}
                onChange={loginDetailHandler}
                name="password"
              />

              <span className="showPassword" onClick={togglePassword}>
                {showPassword ? <EyeCloseSvgIcon /> : <EyeOpenSvgIcon />}
              </span>

            </div>

            <button className="button-submit">Sign In</button>
            <p className="p">
              Don't have an account?
              <span
                className="span"
                title="Register Now"
                onClick={registerNowHandler}
                type="button"
              >
                Sign Up
              </span>
            </p>
            <p className="p line">Or With</p>
            {submitError && <p style={{ color: "red" }}>{submitError}</p>}
            <div className="flex-row">
              <a href="https://accounts.google.com/o/oauth2/v2/auth?client_id=645993481613-03ulaf6tu8tjjbsmq8q980kfj14q51jm.apps.googleusercontent.com&redirect_uri=https://worldbeyond.live/login-with-sm&scope=email+profile&response_type=code">
                <button className="btn_login" type="button">
                  <GoogleLogoSvg />
                  Google
                </button>
              </a>
              <a
                // href="#"
                title="feature temporarily disabled"
                href="https://www.facebook.com/v17.0/dialog/oauth?client_id=305769621974861&redirect_uri=https://www.worldbeyond.live/login-with-fb&scope=email,public_profile"
              >
                <div className="btn_login fb-login-btn">
                  <FacebookLogoSvg />
                  Facebook
                </div>
              </a>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default Login;
