import * as THREE from "three";
import { useRef, useEffect, useMemo, useState } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { SkeletonUtils } from "three-stdlib";
import { Html, useAnimations, useFBX } from "@react-three/drei";
import { dotPath } from "../../Files/CommonVariables";
import "./AddMultiPlayer.css";
import { useAtom } from "jotai";
import { allAnimationsAtom } from "../../Files/Jotai";

const AddMultiPlayer = ({ playerAtom }) => {
  const [allPlayerM] = useAtom(playerAtom);
  //animation
  let playerData = allPlayerM;

  //jotai
  const [allAnimationsA, setAllAnimationA] = useAtom(allAnimationsAtom);
  const { scene } = useLoader(
    GLTFLoader,
    `${dotPath}/R3F/avtar/${playerData.avtarId}.glb`
  );
  const clone = useMemo(() => SkeletonUtils.clone(scene), [scene]);
  //ref
  const otherPlayer = useRef();
  let { actions } = useAnimations(allAnimationsA, otherPlayer);
  //ani
  const [animationName, setAnimationName] = useState("Idle");

  let myAnimation = useAnimations(allAnimationsA, clone);
  // add animatin code here
  useEffect(() => {
    let action = myAnimation.actions[animationName];
    if (!action) return;
    action.reset();
    action.fadeIn(0.5).play();

    if (animationName === "HandShake" || animationName === "HandRaise") {
      action.loop = THREE.LoopOnce;
      action.clampWhenFinished = true;
    } else {
      action.loop = THREE.LoopRepeat;
    }
    return () => {
      action.fadeOut(0.5);
    };
  }, [animationName, allAnimationsA]);

  useFrame((state, delta) => {
    // let deltaTime2 = delta * deltaTime;

    if (playerData.rotation) {
      otherPlayer.current.rotation.copy(playerData.rotation);
    }
    if (playerData.moveType === "normal" && playerData.position) {
      otherPlayer.current.position.copy(playerData.position);
      setAnimationName(playerData.animationName);
    }
    if (playerData.moveType === "direct") {
      otherPlayer.current.position.copy(playerData.position);
      setAnimationName(playerData.animationName);
      return;
    }
  });

  /*-----------------render-------------------*/
  return (
    <>
      <mesh ref={otherPlayer} name="otherPlayerBox" dispose={null}>
        <boxGeometry args={[1, 1.3, 1]} />
        <meshStandardMaterial
          transparent={true}
          opacity={0}
          color="greenyellow"
          side={THREE.DoubleSide}
        />
        {/* 
        <Center position-y={1.0}>
          <Text3D
            font={fontPath}
            size={0.1}
            height={0.03}
            curveSegments={12}
            bevelEnabled
            bevelThickness={0.002}
            bevelSize={0.002}
            bevelOffset={0}
            bevelSegments={5}
          >
            {playerData.socketName}
            <meshStandardMaterial color={"white"} />
          </Text3D>
            </Center>
            */}
        <Html
          name="otherPlayerHeadLabel"
          zIndexRange={[200, 0]}
          wrapperClass="otherPlayerLabel"
          occlude="blending"
          geometry={<planeGeometry args={[0, 0]} />}
          position-y={1.0}
        >
          <div> {playerData.socketName}</div>
        </Html>
        <primitive position-y={-1.0} object={clone} dispose={false}></primitive>
      </mesh>
    </>
  );
};
export { AddMultiPlayer };
