import React, { useEffect, useState } from "react";
import styles from "./RoomPassword.module.css";

const RoomPassword = ({ passwordHandler, room_name }) => {
  const [password, setPassword] = useState("");

  const passSubmit = (e) => {
    setPassword(e.target.value);
  };

  const roomnamehandler = room_name?.replaceAll("%20", " ");

  return (
    <>
      <div className={styles["wrapper_password"]}>
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
            passwordHandler(password);
          }}
        >
          <h4 className={styles["password-heading"]}>
            Welcome To {roomnamehandler}
          </h4>
          <div className={styles["form-control_password"]}>
            <input
              type="text"
              name="password"
              placeholder="Enter Room Password"
              value={password}
              onChange={passSubmit}
              className={styles["inputpassword"]}
            />
            <button title="Enter Room" type="submit" className={styles["password_btn"]}>
              Enter Room
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default RoomPassword;
