import { useEffect } from "react";
import "./Admin.css";
import { adminDataAtom, otherPlayerListAtom } from "../../Files/Jotai";
import { useAtom } from "jotai";
import { SocketManager, socketAdmin } from "../socketManager/SocketManager";
const Admin = () => {
  //jotai
  const [adminDataA, setAdminDataA] = useAtom(adminDataAtom);
  const [otherPlayerListA, setOtherPlayerListA] = useAtom(otherPlayerListAtom);

  useEffect(() => {
    console.log('ok adminDataA:',adminDataA )
    socketAdmin.emit("adminData", {
      sender: "ashsih",
    }); 
  }, [otherPlayerListA]);

  const removePlayer = (roomName, socketName) => {
    socketAdmin.emit("deleteMyPlayer", {
      roomName: roomName,
      socketName: socketName,
    });
  };
  return (
    <>
      <SocketManager />
      <table style={{ background: "white" }}>
        <thead>
          <tr>
            <th>Id</th>
            <th>RoomName</th>
            <th>Playername</th>
            <th>Socketname</th>
            <th>SocketID</th>
            <th>VoiceID</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {adminDataA &&
            Object.keys(adminDataA) &&
            Object.values(adminDataA).map((room) =>
              Object.values(room).map((player, index) => {
                return (
                  <tr key={index}>
                    <td>{index}</td>
                    <td>{player.roomName}</td>
                    <td>{player.socketName}</td>
                    <td>{player.socketName}</td>
                    <td>{player.socketId}</td>
                    <td>{player.voiceId}</td>
                    <td>
                      <button
                        onClick={() =>
                          removePlayer(player.roomName, player.socketName)
                        }
                      >
                        Remove Player
                      </button>
                    </td>
                  </tr>
                );
              })
            )}
        </tbody>
      </table>
    </>
  );
};
export { Admin };
