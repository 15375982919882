import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentCategory,
  setPageNo,
} from "../../features/PaginationData/PaginationDataSlice";
import Pagination from "../paginationRupam/pagination";
import PostCard from "./PostCard";
import styles from "./PostCard.module.css";
import Header from "../../layouts/header/Header";
import Footer from "../../layouts/footer/Footer";
import {WorldTypeData} from "../../common/mediaConstants";

const PostType = () => {
  const dispatch = useDispatch();
  const pagination = useSelector((store) => store.pagination);
  const checkref = useRef();

  window.history.pushState(null, "", "/explore-metaverse");

  const btnActive = `${styles["filterBtn--tab"]} ${styles["filterBtn--tab_active"]}`;
  const clickHandler = (e) => {
    dispatch(setPageNo(1));
    dispatch(setCurrentCategory(e.target.value));
  };

  return (
    <>
      <Header />

      <div className={styles["explore"]}>
        <div className={`${styles["card-section"]} `}>
          <h3>Explore Metaverse</h3>
        </div>

        <div className={`${styles["filter-tabs"]} mt-5`}>
          <div>
            <button
              value="all"
              onClick={clickHandler}
              className={
                pagination.currentCategory === "all"
                  ? btnActive
                  : styles["filterBtn--tab"]
              }
            >
              All
            </button>
          </div>
          {WorldTypeData.map((item, index) => {
            return (
              <div key={index}>
                <button
                  value={item.world_type}
                  onClick={clickHandler}
                  className={
                    pagination.currentCategory === item.world_type
                      ? btnActive
                      : styles["filterBtn--tab"]
                  }
                >
                  {item.title}
                </button>
              </div>
            );
          })}
        </div>

        <div className={`postcard-tab`}>
          <PostCard screen={pagination.currentCategory} />
        </div>

        <div ref={checkref} className={styles["pagination_wrapper"]}>
          <Pagination />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PostType;
