import { useNavigate } from "react-router";
import Header from "../../layouts/header/Header";
import "../pricing/Pricing.css";
import { useDispatch, useSelector } from "react-redux";
import { generateRandomUserInfo } from "../../common/commonFunctions";
import { callVisitorRegistration } from "../../common/axiosCalls";
import { setRoomInfo } from "../../features/RoomInfo/RoomInfoDataSlice";
import { useState } from "react";
import Pricingregistration from "./pricingregistration/Pricingregistration";
import { useRef } from "react";

function Pricing() {
  const navigate = useNavigate();
  const authentication = useSelector((store) => store.authentication);
  const dispatch = useDispatch();
  const [isopen, setOpen] = useState(null);

  const tryForFreeHandler = async () => {
    if (authentication.isAuthenticated) {
      navigate("/create-room");
      return;
    }

    const userInfo = generateRandomUserInfo();

    callVisitorRegistration({
      room_name: `wbm`,
      avtar_name: userInfo.avtar_name,
      avtar_id: userInfo.avtar_id,
      ip_address: authentication.userIP,
    })
      .then((res) => {
        document.cookie = `registration_id=${
          res.data.data[0].id
        }; expires=${new Date(
          Date.now() + 1 * 1 * 60 * 60 * 1000
        ).toUTCString()}`;
      })
      .catch((err) => {
        console.log(err);
      });

    document.cookie = `avtar_name=${userInfo.avtar_name}; expires=${new Date(
      Date.now() + 1 * 1 * 60 * 60 * 1000
    ).toUTCString()}`;
    document.cookie = `avtar_id=${userInfo.avtar_id}; expires=${new Date(
      Date.now() + 1 * 1 * 60 * 60 * 1000
    ).toUTCString()}`;

    dispatch(
      setRoomInfo({
        avtar_id: userInfo.avtar_id,
        avtar_name: userInfo.avtar_name,
        room_type: "r3fmr",
        room_name: `defaultmr`,
        room_id: 0,
        is_admin: false,
      })
    );

    navigate(`/room/r3fmr/defaultmr/0`);
  };

  return (
    <>
      <Header />
      <div className="pricing-area">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-lg-3">
              <div className="single-price">
                <div className="price-header">
                  <h3 className="title">Free</h3>
                </div>
                <div className="price-value">
                  {/* <div className="value">
                    <span className="currency">$</span>{" "}
                    <span className="amount">
                      00.<span>00</span>
                    </span>{" "}
                    <span className="month">/month</span>
                  </div> */}
                </div>
                <ul className="deals">
                  <li>Access to 20 Users</li>
                  <li>Custom Voice Zones</li>
                  <li>Text Chat</li>
                  <li>Voice Communication</li>
                  <li>Upload images </li>
                  <li>Upload Documents</li>
                  <li>Upload your Logo & Branding image for Metaverse</li>
                  <li>Screen Sharing </li>
                  <li>Social Media Sharing</li>
                  <li>One to One email invite</li>
                  <li>Capture Screenshot</li>
                </ul>
                <div className="pricing_btn">
                <button
                  className="p-btn1"
                  title="Get Started"
                  onClick={tryForFreeHandler}
                >
                  Get Started
                </button>
              </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-3">
              <div className="single-price">
                <div className="price-header">
                  <h3 className="title">Basic</h3>
                </div>
                <div className="price-value">
                  <div className="value">
                    <span className="currency">$</span>{" "}
                    <span className="amount">
                      24.<span>99</span>
                    </span>{" "}
                    <span className="month">/month</span>
                  </div>
                </div>
                <ul className="deals">
                  <li> 50 users</li>
                  <li>Voice Chat </li>
                  <li>Custom Voice Zones</li>
                  <li>Text Chat</li>
                  <li>Upload images, pdf's</li>
                  <li>Logo, Custom image for your Metaverse</li>
                  <li>Screen Sharing</li>
                  <li>Google Login</li>
                  <li>Texture editing</li>
                  <li>Analytics Dashboard</li>
                  <li>Social Media Links</li>
                  <li>One to one email invite</li>
                  <li>Whiteboard</li>
                  <li>3D product display</li>
                  <li>Password Protection</li>
                </ul>

               <div className="pricing_btn">
               <button
                  title="Get Started"
                  className="p-btn2"
                  onClick={() => setOpen("Basic")}
                >
                  Get Started
                </button>
               </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-3">
              <div className="single-price">
                <div className="price-header">
                  <h3 className="title">Pro</h3>
                </div>
                <div className="price-value">
                  <div className="value">
                    <span className="currency">$</span>{" "}
                    <span className="amount">
                      99.<span>99</span>
                    </span>{" "}
                    <span className="month">/month</span>
                  </div>
                </div>
                <ul className="deals">
                  <li>150 users</li>
                  <li>Voice Chat </li>
                  <li>Custom Voice Zones</li>
                  <li>Text Chat</li>
                  <li>Upload images, pdf's</li>
                  <li>Logo, Custom image for your Metaverse</li>
                  <li>Screen Sharing</li>
                  <li>Google Login</li>
                  <li>Texture editing</li>
                  <li>Analytics Dashboard</li>
                  <li>Social Media Links</li>
                  <li>One to one email invite</li>
                  <li>Whiteboard</li>
                  <li>3D product display</li>
                  <li>Password Protection</li>
                  <li>Integrated video recording function</li>
                </ul>
                <div className="pricing_btn">
                <button
                  title="Get Started"
                  className="p-btn3"
                  onClick={() => setOpen("pro")}
                >
                  Get Started
                </button>
              </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-3">
              <div className="single-price">
                <div className="price-header">
                  <h3 className="title">Enterprise</h3>
                </div>
                <div className="price-value">
                  <div className="price-value">
                    <div className="value talk">Talk to us</div>
                  </div>
                </div>
                <ul className="deals">
                  <li>Customised 3D Environment</li>
                  <li>End to end White Labelling</li>
                  <li>Custom Voice Zones</li>
                  <li>Text Chat</li>
                  <li>Voice Communication</li>
                  <li>Upload Images & Videos</li>
                  <li>Upload Document</li>
                  <li>Upload your Logo & Branding image for Metaverse</li>
                  <li>Screen Sharing</li>
                  <li>Access to Whiteboard</li>
                  {/* <li>Texture Editing</li> */}
                  <li>Dashboard for Analytics</li>
                  <li>Social Media Links</li>
                  <li>Email invite with Password Protection</li>
                  <li>Integrated video recording function</li>
                  <li>3D Product Display</li>
                  {/* <li>Dedicated Servers & Support </li> */}
                </ul>
                <div className="pricing_btn">
                <button
                  title="Get Started"
                  className="p-btn4"
                  onClick={() => setOpen("Enterprise")}
                >
                  Get Started
                </button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isopen && <Pricingregistration setOpen={setOpen} isopen={isopen} />}
    </>
  );
}
export default Pricing;
