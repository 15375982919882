import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import RoomFooterMenu from "../room/RoomFooterMenu";
import { useDispatch, useSelector } from "react-redux";
import useGetNameCookie from "../../common/getNameCookie";
import {
  openModal,
  setRoomName,
  setRoomType,
  setRoomId,
} from "../../features/EnterWorldPopUp/EnterWorldSlice";
import { callValidatePassword } from "../../common/axiosCalls";
import { setRoomInfo } from "../../features/RoomInfo/RoomInfoDataSlice";
import RoomPassword from "../room/password/RoomPassword";
import { setToast } from "../../features/ErrorHandling/ErrorHandlingSlice";

import Threejs from "../../../R3F/Threejs";

const MetaverseRoom = () => {
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const authentication = useSelector((store) => store.authentication);
  const dispatch = useDispatch();
  const cookies = useGetNameCookie();
  const roomInfo = useSelector((store) => store.roomInfo);
  const [focus, setFocus] = useState();
  const myIframe = useRef(null);

  const roomData = [
    "r3fmr",
    "r3f_showroom",
    "r3f_showroom2",
    "r3f_office1",
    "r3f_office2",
    "r3f_school",
    "r3f_hr",
    "r3f_turkey",
    "r3f_mr2",
    "r3f_mr3",
    "r3f_hall",
    "r3f_hall2",
    "r3f_tourism",
  ];

  const navigate = useNavigate();
  let { roomName, room_type, room_id } = useParams();

  const urlDetails = window.location.pathname.split("/");

  useEffect(() => {
    myIframe?.current?.focus();
  }, [focus]);

  // const checkPasswordInCookie = () => {
  //   const cookie = document.cookie;
  //   const cookieSplit = cookie.split(";");
  //   const cookieSplitMap = cookieSplit.map((item, i) => {
  //     return item.split("=");
  //   });
  //   const filteredCookie = cookieSplitMap.filter((item, i) => {
  //     return item[0].trim() === "room_password";
  //   });

  //   if (filteredCookie.length !== 0) {
  //     const password = filteredCookie.filter(
  //       (item) => item[0].trim() === "room_password"
  //     );
  //     return password[0][1];
  //   } else {
  //     return false;
  //   }
  // };

  const giveWorldEntry = () => {
    if (cookies) {
      dispatch(
        setRoomInfo({
          avtar_id: cookies.avtar_id,
          avtar_name: cookies.avtar_name,
          room_type: urlDetails[2],
          room_name: urlDetails[3].replaceAll("%20", " "),
          room_id: urlDetails[4],
          scene_name: urlDetails[2],
          socket_name: cookies.avtar_name,
          is_admin: false,
        })
      );

      // navigate(`/room/${urlDetails[2]}/${urlDetails[3]}/${urlDetails[4]}`);
    } else {
      dispatch(setRoomName(urlDetails[3]));
      dispatch(setRoomType(urlDetails[2]));
      dispatch(setRoomId(urlDetails[4]));
      dispatch(openModal());
      navigate("/");
    }
  };

  const passwordHandler = async (password) => {
    try {
      const validatedData = await callValidatePassword({
        room_name: roomName.replaceAll("%20", " "),
        room_type: room_type,
        password: password,
      });

      if (validatedData.data.message === "Passwords match!") {
        giveWorldEntry();
        document.cookie = `room_password=${password}; expires=${new Date(
          Date.now() + 1 * 1 * 20 * 60 * 1000
        ).toUTCString()}`;

        setShowPasswordPopup(false);
      } else {
        dispatch(
          setToast({
            type: "error",
            message: "Wrong Password",
          })
        );
      }
    } catch (error) {
      dispatch(
        setToast({
          type: "error",
          message: error.message,
        })
      );
    }
  };

  const linkHandler = async () => {
    giveWorldEntry();
    // try {
    //   const publicPrivateData = await callCheckworldstatus({
    //     room_name: roomName.replaceAll("%20", " "),
    //     room_type: room_type,
    //   });

    //   if (publicPrivateData.data.message === "World is Public") {
    //     giveWorldEntry();
    //   } else {
    //     const isPassStored = checkPasswordInCookie();
    //     if (isPassStored) {
    //       passwordHandler(isPassStored);
    //     } else {
    //       setShowPasswordPopup(true);
    //     }
    //   }
    //   return;
    // } catch (error) {}
  };

  useEffect(() => {
    if (authentication.isAuthenticated) {
      dispatch(
        setRoomInfo({
          avtar_id: authentication.userDetails.avtar_id,
          avtar_name: authentication.userDetails.avtar_name,
          room_type: urlDetails[2],
          room_name: urlDetails[3].replaceAll("%20", " "),
          room_id: urlDetails[4],
          scene_name: urlDetails[2].replaceAll("%20", " "),
          socket_name: authentication.userDetails.avtar_name,
          is_admin:
            authentication?.userDetails?.rooms_created_by_me.filter(
              (room) => urlDetails[4] === room?.room_id
            ).length !== 0
              ? true
              : false,
        })
      );
    }
  }, [authentication.isAuthenticated]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      return;
    }

    if (roomData.includes(room_type)) {
      if (cookies) {
        dispatch(
          setRoomInfo({
            avtar_id: cookies.avtar_id,
            avtar_name: cookies.avtar_name,
            room_type: urlDetails[2],
            room_name: urlDetails[3].replaceAll("%20", " "),
            room_id: urlDetails[4],
            is_admin: false,
            socket_name: cookies.avtar_name,
            scene_name: urlDetails[2].replaceAll("%20", " "),
          })
        );
      } else {
        dispatch(setRoomName(urlDetails[3]));
        dispatch(setRoomType(urlDetails[2]));
        dispatch(setRoomId(0));
        dispatch(openModal());
        navigate("/");
      }
      return;
    }

    linkHandler();
  }, []);

  return (
    <>
      {showPasswordPopup && <RoomPassword passwordHandler={passwordHandler} />}

      {roomData.includes(roomInfo.room_type) ? (
        <Threejs />
      ) : (
        <>
          <iframe
            id="my-iframe"
            ref={myIframe}
            title="World Beyond Metaverse"
            allow="camera; microphone; display-capture"
            allowFullScreen={true}
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            src={`https://${roomInfo?.room_type}.worldbeyond.live/game/game.html?id=${roomInfo?.avtar_id}&name=${roomInfo?.avtar_name}&isadmin=${roomInfo?.is_admin}&room=${roomInfo?.room_name}`}
            style={{
              width: "100%",
              height: "100vh",
              position: "absolute",
              top: "0px",
            }}
          />
          <RoomFooterMenu setFocus={setFocus} />
        </>
      )}
    </>
  );
};

export default MetaverseRoom;
