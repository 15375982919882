import React from "react";
import { Route, Routes } from "react-router";
import Login from "../components/login/Login";
import Home from "../pages/home/Home";
import PrivacyPolicy from "../pages/privacyPolicy/PrivacyPolicy";
import ContentPolicy from "../pages/contentPolicy/ContentPolicy";
import TermsOfUse from "../pages/termsOfUse/TermsOfUse";
import TermsAndConditions from "../pages/termsAndConditions/TermsAndConditions";
import MetaverseRoom from "../components/metaverseRoom/MetaverseRoom";
import RegistrationForm from "../components/register/MultiStepForm/RegistrationForm";
import MyMetaverse from "../components/myMetaverse/MyMetaverse";
import Pricing from "../components/pricing/Pricing";
import RoomCreationNew from "../components/register/MultiStepForm/RoomCreationNew";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import CreateRoom from "../components/register/MultiStepForm/RegistrationUpdate/CreateRoom";
import PostType from "../components/postCard/PostType";
import TurkeyRoom from "../components/metaverseRoom/TurkeyRoom";
import NightClub from "../components/metaverseRoom/TurkeyRoom";
import HrMetaverseRoom from "../components/metaverseRoom/HrMetaverseRoom";
import MeetingRoom from "../components/metaverseRoom/MeetingRoom";
import About from "../pages/home/About";
import { Admin } from "../../R3F/Component/admin/Admin";
import ErrorPage from "../components/ErrorPage/ErrorPage";

export const RoutesComp = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home forRoom={"home-page"} />} />
      <Route exact path="/meeting-room" element={<Home forRoom={"meeting-room"} />} />
      <Route exact path="/meeting-room-2" element={<Home forRoom={"meeting-room-2"} />} />
      <Route path="/turkey-tourism" element={<Home forRoom={"turkey"} />} />
      <Route path="/hr-metaverse" element={<Home forRoom={"hr-metaverse"} />} />
      <Route exact path="/explore-metaverse" element={<PostType />} />
      <Route exact path="/about" element={<About />} />

      <Route exact path="/register" element={<RegistrationForm />} />
      <Route exact path="/room-creation-new" element={<RoomCreationNew />} />
      <Route exact path="/create-room" element={<CreateRoom />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/my-metaverse" element={<MyMetaverse />} />
      <Route path="/login-with-sm" element={<LoadingScreen />} />
      <Route
        path="/login-with-fb"
        element={<LoadingScreen loginFrom="facebook" />}
      />
      <Route path="/welcome-to-turkey" element={<TurkeyRoom />} />
      <Route path="/welcome-to-meetingroom" element={<MeetingRoom />} />
      <Route path="/welcome-to-hr-metaverse" element={<HrMetaverseRoom />} />
      <Route path="/night-club" element={<NightClub />} />
     
      <Route
        path="/metaverse-meeting"
        element={<Home forRoom={"MeetingRoom"} />}
      />

      <Route
        exact
        path="/room/:room_type/:roomName/:room_id"
        element={<MetaverseRoom />}
      />

      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route exact path="/content-policy" element={<ContentPolicy />} />
      <Route exact path="/terms-of-use" element={<TermsOfUse />} />
      <Route exact path="/terms-conditions" element={<TermsAndConditions />} />
      <Route exact path="/Pricing" element={<Pricing />} />
      <Route path="/admin" element={<Admin />}></Route>
      <Route path="/*" element={<ErrorPage />}></Route>

    </Routes>
  );
};
