import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { callVisitorRegistration } from "../../common/axiosCalls";
import useGetNameCookie from "../../common/getNameCookie";
import {
  openModal,
  setModalFor,
  setRoomId,
  setRoomName,
  setRoomType,
} from "../../features/EnterWorldPopUp/EnterWorldSlice";
import { setRoomInfo } from "../../features/RoomInfo/RoomInfoDataSlice";
import RoomFooterMenu from "../room/RoomFooterMenu";

const MeetingRoom = () => {
  const authentication = useSelector((store) => store.authentication);
  const dispatch = useDispatch();
  const cookies = useGetNameCookie();
  const roomInfo = useSelector((store) => store.roomInfo);
  const [focus, setFocus] = useState();
  const myIframe = useRef(null);

  const navigate = useNavigate();

  const newRoomInfo = {
    room_name: "Employee%20on%20boarding",
    room_type: "hr",
  };

  useEffect(() => {
    myIframe?.current?.focus();
  }, [focus]);

  useEffect(() => {
    if (authentication.isAuthenticated) {
      callVisitorRegistration({
        room_name: newRoomInfo.room_name,
        avtar_name: authentication.userDetails.avtar_name,
        avtar_id: authentication.userDetails.avtar_id,
        ip_address: authentication.userIP,
      }).catch((err) => {
        console.log(err);
      });

      dispatch(
        setRoomInfo({
          room_name: newRoomInfo.room_name,
          avtar_name: authentication.userDetails.avtar_name,
          avtar_id: authentication.userDetails.avtar_id,
          room_type: newRoomInfo.room_type,
          room_id: 1000,
          is_admin: "false",
        })
      );
    }
  }, [authentication.isAuthenticated]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      return;
    }
    if (cookies) {
      callVisitorRegistration({
        room_name: "Employee%20on%20boarding",
        avtar_name: cookies.avtar_name,
        avtar_id: cookies.avtar_id,
        ip_address: authentication.userIP,
      }).catch((err) => {
        console.log(err);
      });

      dispatch(
        setRoomInfo({
          room_name: "Employee%20on%20boarding",
          avtar_name: cookies.avtar_name,
          avtar_id: cookies.avtar_id,
          room_type: newRoomInfo.room_type,
          room_id: 385,
          is_admin: "false",
        })
      );
    } else {
      dispatch(
        setModalFor({
          purpose: "Hr-visit",
          data: {},
        })
      );
      dispatch(openModal());
      navigate("/");
    }
  }, []);

  return (
    <>
      {roomInfo?.avtar_id && (
        <>
          <iframe
            id="my-iframe"
            ref={myIframe}
            title="World Beyond Metaverse"
            allow="camera; microphone; display-capture"
            allowFullScreen={true}
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            src={`https://${roomInfo?.room_type}.worldbeyond.live/game/game.html?id=${roomInfo?.avtar_id}&name=${roomInfo?.avtar_name}&isadmin=${roomInfo?.is_admin}&room=${roomInfo?.room_name}`}
            style={{
              width: "100%",
              height: "100vh",
              position: "absolute",
              top: "0px",
            }}
          ></iframe>
          <RoomFooterMenu
            setFocus={setFocus}
            room_name={roomInfo.room_name}
            isadmin={roomInfo.is_admin}
            isDefaultRoom={true}
            room_info={{
              room_type: newRoomInfo.room_type,
              room_name: newRoomInfo.room_name,
              room_id: roomInfo.room_id,
            }}
          />
        </>
      )}
    </>
  );
};

export default MeetingRoom;
