import "./Player.css";
import * as THREE from "three";
import { useRef, useEffect, useMemo, useState, useCallback } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import {
  PerspectiveCamera,
  useAnimations,
  useKeyboardControls,
  Html,
} from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { SkeletonUtils } from "three-stdlib";
import {
  myPlayerLandPosition,
  mySmallmapPlayerPosition,
} from "../different/PlayerPositions";
import {
  allAnimationsAtom,
  isPlayerRunnningAtom,
  isWindowFocusedAtom,
  myAnimationAtom,
  playerTranslateAtom,
  showSittingIndicatorAtom,
  sittingObjectAtom,
  takeScreenshotAtom,
  teleportPositionAtom,
  // voiceAtom,
  // voiceIdArrayAtom,
  walkWithJoystickAtom,
} from "../../Files/Jotai";
import { useAtom } from "jotai";
// import { remoteUser } from "../../voice/voice";
import { useSelector } from "react-redux";
import { dotPath, fontPath } from "../../Files/CommonVariables";
import { socket } from "../socketManager/SocketManager";
import { maleIdArray } from "../../Files/CommonVariables";
import { doNotWalkMaterialArray, treeMaterialNameArray } from "../different/updateMaterial/UpdateMaterialArray";
import { SimplifyModifier } from 'three/addons/modifiers/SimplifyModifier.js';

const Player = ({ bvhRef }) => {
  const {
    avtar_id: avtarId,
    socket_name: socketName,
    room_name: roomName,
    scene_name: sceneName,
  } = useSelector((store) => store.roomInfo);


  /*------------------------------------*/
  //import
  let myPlayerLandPosition2 = myPlayerLandPosition[sceneName];
  //jotai
  const [allAnimationsA, setAllAnimationA] = useAtom(allAnimationsAtom);
  const [myAnimationA, setMyAnimationA] = useAtom(myAnimationAtom);

  const [, setShowSittingIndicatorA] = useAtom(showSittingIndicatorAtom);
  const [isPlayerRunnningA, setIsPlayerRunnningA] =
    useAtom(isPlayerRunnningAtom);
  const [teleportPositionA, setTeleportPositionA] =
    useAtom(teleportPositionAtom);
  const [sittingObjectA, setSittingObjectA] = useAtom(sittingObjectAtom);
  const [walkWithJoystickA] = useAtom(walkWithJoystickAtom);
  const [playerTranslateA] = useAtom(playerTranslateAtom);
  const [takeScreenshotA, setTakeScreenshotA] = useAtom(takeScreenshotAtom);
  // const [allPlayerM] = useAtom(allPlayer);
  // const [voiceRadiusA] = useAtom(voiceAtom);
  // const [voiceIdArrayA] = useAtom(voiceIdArrayAtom);

  const [isWindowFocusedA, setIsWindowFocusedA] = useAtom(isWindowFocusedAtom);

  /*-----------------city-------------------*/
  //dom
  let mySmallAvtar = document.querySelector(".smallAvtar");
  /*------------------var------------------*/
  //player
  let deltaTime = 40.0;
  let walkSpeed = 0.1;
  let walkSpeedFast = 0.2;
  let rotateSpeed = 0.02;
  let collisionDistance = 2.0;
  let cameraZposition = 8.0;
  let isAnimationOn = true;

  let playerGoingForward = true;
  let playerGoingBackward = true;
  let playerYPosition = 1;
  let myWalkingType = "Walking";
  if (!maleIdArray.includes(avtarId)) {
    myWalkingType = "Walking_female";
  }

  //ref
  const [subscribeKeys, getKeys] = useKeyboardControls();
  const cameraRef = useRef();
  const playerRef = useRef();
  const playerRaycasterDown = useRef();
  const playerRaycasterF = useRef();
  const playerRaycasterB = useRef();

  //avtar;
  const { scene, nodes, materials } = useLoader(
    GLTFLoader,
    `${dotPath}/R3F/avtar/${avtarId}.glb`
  );
  const clone = useMemo(() => SkeletonUtils.clone(scene), [scene]);

  //raycaster
  let rayDownTarget = new THREE.Vector3(0, -Math.PI, 0);
  rayDownTarget.normalize();
  let rayTargetForwardVector = new THREE.Vector3(0, 0, 1);
  let rayTargetBackwordVector = new THREE.Vector3(0, 0, 0);

  //state

  /*------------------arrow------------------*/
  // var arrowHelper;
  // const { scene: scene2 } = useThree()
  // const arrowHelperM = (origin, dir) => {
  //   let hex = 0xffff00;
  //   scene2.remove(arrowHelper);
  //   arrowHelper = new THREE.ArrowHelper(dir, origin, 5, hex);
  //   scene2.add(arrowHelper);
  // };
  /*-----------------useEffect-------------------*/

  let myAnimation = useAnimations(allAnimationsA, clone);

  //animation
  useEffect(() => {
    if (!isAnimationOn) return;
    let action = myAnimation.actions[myAnimationA];
    if (!action) return;
    action.reset();
    action.fadeIn(0.5).play();

    if (myAnimationA === "HandShake" || myAnimationA === "HandRaise") {
      action.loop = THREE.LoopOnce;
      action.clampWhenFinished = true;
      setTimeout(() => {
        isAnimationOn = false;
        setMyAnimationF("Idle");
      }, 2000);
    } else {
      action.loop = THREE.LoopRepeat;
    }
    return () => {
      action.fadeOut(0.5);
      isAnimationOn = true;
    };
  }, [myAnimationA, allAnimationsA]);

  //screenshot 
  const { camera: worldCamera, gl, scene: worldScene } = useThree();

  useEffect(() => {
    if (!takeScreenshotA) return;
    const link = document.createElement("a");
    link.setAttribute("download", "canvas.png");
    link.setAttribute(
      "href",
      gl.domElement
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream")
    );
    link.click();
    setTakeScreenshotA(false);
  }, [takeScreenshotA]);

  //stop walk
  useEffect(() => {
    if (walkWithJoystickA) {
      setMyAnimationF("Walking");
    } else {
      setMyAnimationF("Idle");
      updatePlayerGlobally("down", "Idle");
    }
    const handleKeyUp = (e) => {
      if (
        e.code == "KeyW" ||
        e.code == "KeyS" ||
        e.code == "ArrowUp" ||
        e.code == "ArrowDown"
      ) {
        setMyAnimationF("Idle");
        updatePlayerGlobally("down", "Idle");
      }
    };

    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.addEventListener("keyup", handleKeyUp);
    };
  }, [walkWithJoystickA]);

  //focus
  useEffect(() => {
    const handleFocus = () => {
      setIsWindowFocusedA(true);
    };
    const handleBlur = () => {
      setMyAnimationF("Idle");
      setIsWindowFocusedA(false);
    };
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    //exclude object from bvh 
    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };

  }, []);

  //sittingObjectA
  useEffect(() => {
    /*-----------------sitting-------------------*/
    if (Object.keys(sittingObjectA).length) {
      if (
        playerRef.current.position.distanceTo(sittingObjectA.position) > 0.5
      ) {
        let sitPosition = {
          x: sittingObjectA.position.x,
          y: sittingObjectA.position.y,
          z: sittingObjectA.position.z,
        };
        /*
        changed to sitting animation setMyAnimationF
        */
        setMyAnimationF(sittingObjectA.animationName);
        playerRef.current.position.copy(sitPosition);
        playerRef.current.rotation.copy(sittingObjectA.rotation);
      }
    }
    /*-----------------teleport-------------------*/
    if (Object.keys(teleportPositionA).length) {
      let moveType = teleportPositionA.moveType;
      let idlePosition = {
        x: teleportPositionA.position.x,
        y: teleportPositionA.position.y,
        z: teleportPositionA.position.z,
      };
      if (moveType === "direct") {
        playerRef.current.position.copy(idlePosition);
        setTeleportPositionA({ ...teleportPositionA, moveType: "normal" });
        setMyAnimationF("Idle");
        return;
      }
    }
  }, [sittingObjectA.animationName, teleportPositionA.position])

  /*------------------useCallback------------------*/
  //updatePlayerGlobally
  const updatePlayerGlobally = useCallback((direction, myAnimationA) => {
    /*-----------------updatePlayer-------------------*/
    playerRef.current &&
      socket.emit("updatePlayer", {
        avtarId: avtarId,
        direction: direction,
        socketName: socketName,
        roomName: roomName,
        animationName: myAnimationA,
        position: playerRef.current.position,
        rotation: playerRef.current.rotation,
        socketId: socket.id,
        voiceId: "",
        moveType: "normal",
        isActive: true,
      });
    /*-----------------voice radius-------------------*/
    // Object.values(voiceIdArrayA).map((voiceObject) => {
    //   if (
    //     voiceObject.socketName !== socketName &&
    //     remoteUser[voiceObject.voiceId] &&
    //     allPlayerM[voiceObject.socketName]
    //   ) {
    //     let distance = playerRef.current.position.distanceTo(
    //       allPlayerM[voiceObject.socketName].position
    //     );
    //     if (distance < voiceRadiusA) {
    //       if (!remoteUser[voiceObject.voiceId]._played) {
    //         remoteUser[voiceObject.voiceId].play();
    //       }
    //     } else {
    //       if (remoteUser[voiceObject.voiceId]._played) {
    //         remoteUser[voiceObject.voiceId].stop();
    //       }
    //     }
    //   }
    // });
  });

  //WalkCollision 
  const WalkCollision = useCallback((state) => {
    setShowSittingIndicatorA(true);
    // setTeleportPositionA({});
    // setSittingObjectA({});
    //down
    playerRaycasterDown.current.ray.origin = playerRef.current.position;
    playerRaycasterDown.current.ray.direction = rayDownTarget;
    let rays = playerRaycasterDown.current.intersectObject(bvhRef.current);
    if (!rays.length) {
      playerRef.current.position.copy(myPlayerLandPosition2);
      return;
    } 
    //exclude 
    const excludedObject = rays.find((intersection) => {
      return !doNotWalkMaterialArray[sceneName].includes(intersection.object.material.name)
    });
    playerRaycasterDown.current.firstHitOnly = true;
    playerRef.current.position.y = excludedObject.point.y + playerYPosition;
    
    // forward
    let rayTargetForward = playerRef.current.getWorldDirection(
      rayTargetForwardVector
      );
      rayTargetForward.normalize();
      playerRaycasterF.current.ray.origin = playerRef.current.position;
      playerRaycasterF.current.ray.direction = rayTargetForward;
      
      let forardRay = playerRaycasterF.current.intersectObject(bvhRef.current);
      
      if (forardRay.length) { 
        if (forardRay[0].distance < collisionDistance) {
          if (
            treeMaterialNameArray[sceneName].includes(
              forardRay[0].object.material.name
              )
              ) {
              return;
            }
        /*------------------SimplifyModifier lod------------------*/
        // const modifier = new SimplifyModifier();
        // let mesh = forardRay[0].object 
        // if (mesh.isMesh) {
          //   const simplified = mesh;
          //   simplified.material = simplified.material;
          //   simplified.material.flatShading = true;
        //   const count = Math.floor(simplified.geometry.attributes.position.count * 0.1); // number of vertices to remove
        //   simplified.geometry = modifier.modify(simplified.geometry, count);
        // }
        /*------------------------------------*/
        playerGoingForward = false;
      } else {
        playerGoingForward = true;
      }
    }

    //backward

    let rayTargetBackward = playerRef.current.getWorldDirection(
      rayTargetBackwordVector
    );
    rayTargetBackward.normalize();
    playerRaycasterB.current.ray.origin = playerRef.current.position;
    playerRaycasterB.current.ray.direction = rayTargetBackward.negate();
    let backwardRay = playerRaycasterB.current.intersectObject(bvhRef.current);
    if (backwardRay.length) {
      if (backwardRay[0].distance < collisionDistance) {

        if (
          treeMaterialNameArray[sceneName].includes(
            backwardRay[0].object.material.name
          )
        ) {
          return;
        }
        playerGoingBackward = false;
      } else {
        playerGoingBackward = true;
      }

      //exclude
      const excludedObjectBack = backwardRay.find((intersection) => {
        return !doNotWalkMaterialArray[sceneName].includes(intersection.object.material.name)
      }); 

      if (excludedObjectBack.distance < cameraZposition) {
        let collisionPosition = new THREE.Vector3(
          state.camera.position.x,
          state.camera.position.y,
          -cameraZposition + (cameraZposition - backwardRay[0].distance)
        );
        state.camera.position.lerp(collisionPosition, 0.2);
      } else {
        let collisionPosition = new THREE.Vector3(0, 1.5, -cameraZposition);
        state.camera.position.lerp(collisionPosition, 0.2);
      }
    }
  });


  //change animation
  const setMyAnimationF = useCallback((animationNAme) => {
    setMyAnimationA(animationNAme)
  });

  //player walk
  const playerWalk = useCallback((direction, state, deltaTime2) => {
    let tempWalkingType = isPlayerRunnningA ? "Running" : myWalkingType;
    let tempWalkingSpeed = isPlayerRunnningA ? walkSpeedFast : walkSpeed;

    WalkCollision(state);
    if (direction === "forward") {
      setMyAnimationF(tempWalkingType);
      playerGoingForward &&
        playerRef.current.translateZ(tempWalkingSpeed * deltaTime2);
      playerGoingForward && updatePlayerGlobally("up", tempWalkingType);
    }
    else if (direction === "backward") {
      setMyAnimationF(tempWalkingType);
      playerGoingBackward &&
        playerRef.current.translateZ(-tempWalkingSpeed * deltaTime2);
      playerGoingBackward && updatePlayerGlobally("down", tempWalkingType);
    }
    else if (direction === "rightward") {
      playerRef.current.rotateY(-rotateSpeed * deltaTime2);
    }
    else if (direction === "leftward") {
      playerRef.current.rotateY(+rotateSpeed * deltaTime2);
    }
  });

  /*-----------------useFrame-------------------*/
  useFrame((state, delta) => {
    let deltaTime2 = delta * deltaTime;
    let { forward, backward, leftward, rightward, key1, key2, shift } =
      getKeys();

    /*------------------------------------*/
    let x =
      playerRef.current.position.x * mySmallmapPlayerPosition[sceneName].x;
    let y =
      playerRef.current.position.z * mySmallmapPlayerPosition[sceneName].y;
    mySmallAvtar &&
      (mySmallAvtar.style.left =
        mySmallmapPlayerPosition[sceneName].left + x + "px");
    mySmallAvtar &&
      (mySmallAvtar.style.top =
        mySmallmapPlayerPosition[sceneName].top + y + "px");
    if (key1) {
      setMyAnimationF("HandRaise");
      updatePlayerGlobally("up", "HandRaise");
    }
    if (key2) {
      setMyAnimationF("HandShake");
      updatePlayerGlobally("up", "HandShake");
    }
    if (shift) {
      setIsPlayerRunnningA(true);
    } else {
      setIsPlayerRunnningA(false);
    }
    if (forward) {
      playerWalk("forward", state, deltaTime2)
    }

    if (backward) {
      playerWalk("backward", state, deltaTime2)
    }
    if (rightward) {
      playerWalk("rightward", state, deltaTime2)
    }
    if (leftward) {
      playerWalk("leftward", state, deltaTime2)

    }

    /*-----------------joystick-------------------*/

    if (walkWithJoystickA) {
      WalkCollision(state);
      playerGoingForward &&
        playerRef.current.translateZ(
          playerTranslateA.y * walkSpeed * deltaTime2
        );
      playerRef.current.rotateY(-playerTranslateA.x * rotateSpeed * deltaTime2);
      updatePlayerGlobally("down", "Walking");
    }
    /*-----------------camera-------------------*/
    const bodyPosition = playerRef.current.position;
    const cameraTarget = new THREE.Vector3();
    cameraTarget.copy(bodyPosition);
    cameraTarget.y += 1.2;
    cameraRef.current.lookAt(cameraTarget);
  });

  // useHelper(cameraRef, THREE.CameraHelper)
  return (
    <>
      <group
        name="myPlayerGroup"
        renderOrder={2}>
        <group name="myPlayerRaycasterGroup">
          <raycaster far={4} ref={playerRaycasterDown} />
          <raycaster far={4} ref={playerRaycasterF} />
          <raycaster ref={playerRaycasterB} />
        </group>
        <Html name="myPlayerHeadLabel" zIndexRange={[200, 0]} wrapperClass="myPlayerLabel" center>
          {socketName}
        </Html>
        <mesh
          ref={playerRef}
          position={[
            myPlayerLandPosition2.x,
            myPlayerLandPosition2.y,
            myPlayerLandPosition2.z,
          ]}
          name="myPlayerBox"
          dispose={null}
          receiveShadow
        >
          <meshStandardMaterial
            transparent={true}
            opacity={0}
            color="skyblue"
            side={THREE.DoubleSide}
          />
          <boxGeometry />

          <PerspectiveCamera
            name="myPlayerCamera"
            fov={35}
            ref={cameraRef}
            near={1}
            far={700}
            makeDefault
            position={[0, 1.5, -cameraZposition]}
          />
          <primitive
            name="myPlayer"
            object={clone}
            position-y={-playerYPosition}
          ></primitive>
        </mesh>
      </group>
    </>
  );
};
export { Player };
