import { Suspense } from "react";
import Experience from "./Component/experience/Experience";
import { SocketManager } from "./Component/socketManager/SocketManager";
 
const Threejs = () => {
  return (
    <> 
        <SocketManager />
        <Experience /> 
    </>
  );
};
export default Threejs;
