import { atom } from "jotai";

//common
const allAnimationsAtom = atom([]);
//player
const allPlayer = atom({});

const myAnimationAtom = atom("Idle");
const isMyPlayerWalkingAtom = atom(true);
const isPlayerRunnningAtom = atom(false);
const playerForwardWalkingAtom = atom(true);
const playerBackwardWalkingAtom = atom(true);
const teleportPositionAtom = atom({});
const sittingObjectAtom = atom({});
const otherAnimationAtom = atom("Idle");
const otherPlayerListAtom = atom({});

const walkWithJoystickAtom = atom(false);
const playerTranslateAtom = atom({
  x: 0,
  y: 0,
});

const adminDataAtom = atom({});
const voiceAtom = atom(10.0);
const voiceIdArrayAtom = atom({});

const takeScreenshotAtom = atom(false);

//world
const showSittingIndicatorAtom = atom(false);
const isWindowFocusedAtom = atom(true);
const infoPopupAtom = atom(true);
const isModelLoadedAtom=atom(false)

export {
  isMyPlayerWalkingAtom,
  playerForwardWalkingAtom,
  playerBackwardWalkingAtom,
  myAnimationAtom,
  teleportPositionAtom,
  walkWithJoystickAtom,
  playerTranslateAtom,
  sittingObjectAtom,
  allPlayer,
  adminDataAtom,
  takeScreenshotAtom,
  voiceAtom,
  otherAnimationAtom,
  voiceIdArrayAtom,
  showSittingIndicatorAtom,
  isPlayerRunnningAtom,
  isWindowFocusedAtom,
  otherPlayerListAtom,
  allAnimationsAtom,
  infoPopupAtom,
  isModelLoadedAtom
};
