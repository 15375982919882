import { useAtom } from "jotai";
import { allPlayer, otherPlayerListAtom } from "../../Files/Jotai";
import { AddMultiPlayer } from "./AddMultiPlayer";
import { focusAtom } from "jotai-optics"; 


// const AddMultiPlayerM = memo(({ playerAtom }) => {
//   return <AddMultiPlayer
//     playerAtom={playerAtom}
//   />
// }, (prevProps, nextProps) => {
//   return prevProps.socketName === nextProps.socketName;
// })

const OtherPlayer = () => {
  //Jotai  
  const [otherPlayerListA, setOtherPlayerListA] = useAtom(otherPlayerListAtom);  
  return (
    <>
      {otherPlayerListA &&
        Object.values(otherPlayerListA).map((player, index) => {
          if (!player.isActive) return
          const playerAtom = focusAtom(allPlayer, (me) =>
            me.prop(player.socketName)
          );
          return <group key={index} renderOrder={2}>
            <AddMultiPlayer
              playerAtom={playerAtom}
            />  
          </group>
        }
        )
      }
    </>
  );
};
export { OtherPlayer };
