import React from 'react'
import Styles from "./Features.module.css";

const ImageFeature = ({feature}) => {
  return (
   <div className={Styles['features-img']}>
    <img src={feature.img} alt="" />
   </div>
  )
}

export default ImageFeature