import React, { useState } from "react";
import "./SharingIcons.css";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import { callsharePassword } from "../../common/axiosCalls";
import { useDispatch, useSelector } from "react-redux";
import { setToast } from "../../features/ErrorHandling/ErrorHandlingSlice";

const SharingIcons = ({ setIsOpen, isOpen }) => {
  const roomInfo = useSelector((store) => store.roomInfo);
  const [emailInputs, setEmailInputs] = useState([{ id: 1, value: "" }]);
  const [formstate, setFormstate] = useState({
    room_link: "",
    // password: "",
    emailAddresses: [],
  });
  const dispatch = useDispatch();

  const handleButtonClick1 = () => {
    if (emailInputs.length < 10) {
      const newInputId = emailInputs[emailInputs.length - 1].id + 1;
      setEmailInputs([...emailInputs, { id: newInputId, value: "" }]);
    }
  };

  const handleRemoveButtonClick = (id) => {
    const updatedInputs = emailInputs.filter((input) => input.id !== id);
    setEmailInputs(updatedInputs);
  };

  const handleChange = (e, id) => {
    const updatedInputs = emailInputs.map((input) =>
      input.id === id ? { ...input, value: e.target.value } : input
    );
    setEmailInputs(updatedInputs);

    const emailAddresses = updatedInputs
      .map((input) => input.value)
      .filter(Boolean);
    setFormstate({ ...formstate, emailAddresses });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    

    const emailAddresses = emailInputs
      .map((input) => input.value)
      .filter(Boolean);

    const room_link = window.location.href;
    console.log(room_link);
    try {
      await callsharePassword({
        emailAddresses: JSON.stringify({ emailAddresses }),
        room_link,
        room_name: roomInfo.room_name,
        room_type: roomInfo.room_type,
      }).then((res) => {
        setIsOpen(null);
        dispatch(
          setToast({
            type: "success",
            message: `Link Shared Successfully !`,
          })
        );
      });
    } catch (error) {
      dispatch(
        setToast({
          type: "error",
          message: error.message,
        })
      );
    }
  };

  return (
    <>
      <div className="main_shareing">
        <main className="main">
          <h2 className="invite_heading">Invite Friends</h2>
          <button
            title="Close"
            className="closeButton"
            onClick={() => setIsOpen(null)}
          >
            <span className="material-icons">arrow_back</span>
          </button>

          <div className="main_invite">
            <div className="login">
              <div>
                <input
                  type="hidden"
                  name="url"
                  value={window.location.href}
                  id=""
                  onChange={handleChange}
                />
              </div>
              {/* <div>
                <input
                  className="sharing_input"
                  type="text"
                  name="password"
                  id=""
                  placeholder="Create room password"
                  value={formstate.password}
                  onChange={(e) =>
                    setFormstate({ ...formstate, password: e.target.value })
                  }
                />
              </div> */}

              {emailInputs.map((input) => (
                <div key={input.id}>
                  <input
                    className="sharing_input"
                    type="email"
                    name="email"
                    placeholder="Add invite email"
                    value={input.value}
                    onChange={(e) => handleChange(e, input.id)}
                  />
                  {input.id > 2 && (
                    <div
                      title="Remove Email"
                      onClick={() => handleRemoveButtonClick(input.id)}
                      className="removeButton"
                    >
                      <span className="material-icons share_icon">
                        remove_circle_outline
                      </span>
                    </div>
                  )}
                </div>
              ))}

              {emailInputs.length < 10 && (
                <div
                  onClick={handleButtonClick1}
                  className="addButton"
                  title="Add Email"
                >
                  <span className="material-icons share_icon">add_circle</span>
                </div>
              )}
            </div>
          </div>
          <button title="Share" onClick={handleSubmit} className="share_submit">
            Share
          </button>
          <div className="btnSection">
            <span className="item" title="Whatsapp">
              <WhatsappShareButton url={window.location.href}>
                <WhatsappIcon borderRadius="50%" size={42} />
              </WhatsappShareButton>
            </span>
            <span className="item" title="Email">
              <EmailShareButton url={window.location.href} borderRadius="50%">
                <EmailIcon borderRadius="50%" size={42} />
              </EmailShareButton>
            </span>{" "}
            <span className="item" title="Facebook">
              <FacebookShareButton url={window.location.href}>
                <FacebookIcon borderRadius="50%" size={42} />
              </FacebookShareButton>
            </span>
            <span className="item" title="Twitter">
              <TwitterShareButton url={window.location.href}>
                <TwitterIcon borderRadius="50%" size={42} />
              </TwitterShareButton>
            </span>{" "}
          </div>
        </main>
      </div>
    </>
  );
};

export default SharingIcons;
