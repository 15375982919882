// import NProgress from "nprogress";
import "./Wallpaper.css";
import { useProgress } from "@react-three/drei";
import { dotPath } from "../../../Files/CommonVariables"; 

const Wallpaper = ({ sceneName, setIsSiteLoaded }) => {
  let wallPaperPath = `${dotPath}/R3F/image/Wallpaper/${sceneName}/Wallpaper.png`;
  const {  progress } = useProgress(); 
 

  return (
    <>
      <div
        className="loadingScreenContainer"
        style={{ backgroundImage: `url(${wallPaperPath})` }}
      >
        <div className="helpScreen">
          <div className="loadingScreen">
            <div className="loadingTitle">World Beyond</div>
            <div className="loadingSubTitle">Metaverse 1.0.0</div>
            <div className="loadingDescription">
              World Beyond users can explore personalized features for avatar
              selection based on social networks, age, interests, gender, etc in
              Metaverse.
            </div>
            <div className="loadingCount">{Math.round(progress)}%</div>
          </div>
          {window.innerWidth > 1000 && <div className="shortcutScreen"></div>}
        </div>
      </div>
    </>
  );
};

export default Wallpaper;
