import * as THREE from "three";
import { useHelper } from "@react-three/drei";
import { useControls } from "leva";
import { useRef, useMemo, useEffect, useCallback } from "react";

function Lights({ sceneName }) {
  /*------------------hooks------------------*/
  const light = useRef();
  const spotLightRef = useRef()
  const dirLightRef = useRef()

  /*------------------gui------------------*/
  //ambientlight
  const ambOptions = useMemo(() => {
    return {
      ambientLightVisible: true,
      ambientLightIntensity: { value: 0.5, min: 0, max: 2 },
      ambientLightColor: '#ffffff',
    }
  }, []);
  // const { ambientLightVisible, ambientLightIntensity, ambientLightColor } = useControls('ambient light', ambOptions)

  //directional

  const dirOptions = useMemo(() => {
    return {
      dirVisible: true,
      dirColor: '#ffebc9',
      dirItensity: { value: 2.0, min: 0, max: 50 },
      dirPosition: {
        value: { x: 0, y: 10.0, z: 0 },
        step: 0.1,
        joystick: "invertY",
      },
      dirNormalBias: { value: 0.24, min: 0, max: 2, step: 0.01, },
    }
  }, [])
  // const { dirVisible, dirColor, dirItensity, dirPosition, dirNormalBias } = useControls(
  //   "directional light",
  //   dirOptions
  // );

  //spotlight
  // useHelper(spotLightRef, THREE.SpotLightHelper, 1)
  const spotOptions = useMemo(() => {
    return {
      spotLightVisible: true,
      spotLightIntensity: { value: 1.0, min: 0, max: 10 },
      spotLightPower: { value: 1.0, min: 0, max: 100 },
      spotLightAngle: { value: 1.0, min: 0, max: 10 },
      spotLightPosition: {
        value: { x: 8.9, y: 3.0, z: 26.1 },
        step: 0.1,
        joystick: "invertY",
      },
      spotLightTargetPosition: {
        value: { x: 8.9, y: 1.0, z: 26.1 },
        step: 0.1,
        joystick: "invertY",
      },
    }
  }, [])
  // const { spotLightVisible, spotLightIntensity, spotLightPower, spotLightAngle, spotLightPosition, spotLightTargetPosition } = useControls(
  //   "spot light",
  //   spotOptions
  // );

  /*------------------useEffect------------------*/
  // useCallback(() => {
  //   console.log('ok dirLightRef:',dirLightRef.current.shadow.camera )
  //   useHelper(dirLightRef.current.shadow.camera, THREE.DirectionalLightHelper, 1)
  // //   spotLightRef.current.target = spotLightTargetRef.current
  // }, [dirPosition])

  /*------------------------------------*/

  const TempLight = () => {
    switch (sceneName) {
      case "r3f_school":
        return (
          <>
            <ambientLight
              visible={true}
              // visible={ambientLightVisible}
              intensity={1.5}
            // intensity={ambientLightIntensity}
            />
            <directionalLight
              visible={true}
              // visible={visible}
              intensity={3.0}
              // intensity={intensity}
              castShadow={true}
              shadow-mapSize-height={512}
              shadow-mapSize-width={512}
              position={[23.9, 100.0, 0]}
              // position={[position.x, position.y, position.z]}
              shadow-camera-far={150}
              shadow-camera-left={-100}
              shadow-camera-right={100}
              shadow-camera-top={100}
              shadow-camera-bottom={-100}
              // shadow-normalBias={normalBias}
              shadow-normalBias={0.6}
            />
          </>
        );
        break;

      case "r3fmr":
        return (
          <>
            <ambientLight
              visible={true}
              // visible={ambientLightVisible}
              intensity={2.5}
            // intensity={ambientLightIntensity}
            />
          </>
        );
        break;

      case "r3f_hr":
        return (
          <>
            <ambientLight
              visible={true}
              // visible={ambientLightVisible}
              intensity={2.5}
            // intensity={ambientLightIntensity}
            />
          </>
        );
        break;

      case "r3f_tourism":
        return (
          <>
            <ambientLight
              visible={true}
              // visible={ambientLightVisible}
              intensity={1.0}
            // intensity={ambientLightIntensity}
            />
          </>
        );
        break;

      case "r3f_mr2":
        return (
          <>
            <ambientLight
              name="light"
              visible={true}
              // visible={ambientLightVisible}
              intensity={1}
              // intensity={ambientLightIntensity}
              color="#ffffff"
            // color={ambientLightColor}
            />
            <directionalLight
              name="light"
              ref={dirLightRef}
              visible={true}
              // visible={dirVisible}
              // color={dirColor}
              color="#ffebc9"
              intensity={2.5}
              // intensity={dirItensity} 
              castShadow={true}
              shadow-mapSize-height={512 * 4}
              shadow-mapSize-width={512 * 4}
              position={[17.6, 29.3, -25.8]}
              // position={[dirPosition.x, dirPosition.y, dirPosition.z]}
              shadow-camera-far={150}
              shadow-camera-left={-100}
              shadow-camera-right={100}
              shadow-camera-top={100}
              shadow-camera-bottom={-100}
              shadow-normalBias={0.20}
            // shadow-normalBias={dirNormalBias}
            />
          </>
        );
        break;

      case "r3f_mr3":
        return (
          <>
            <ambientLight
              name="light"
              visible={true}
              // visible={ambientLightVisible}
              intensity={1}
              // intensity={ambientLightIntensity}
              color="#ffffff"
            // color={ambientLightColor}
            />
            <directionalLight
              name="light"
              ref={dirLightRef}
              visible={true}
              // visible={dirVisible}
              // color={dirColor}
              color="#ffebc9"
              intensity={2.5}
              // intensity={dirItensity} 
              castShadow={true}
              shadow-mapSize-height={512 * 4}
              shadow-mapSize-width={512 * 4}
              position={[17.6, 29.3, -25.8]}
              // position={[dirPosition.x, dirPosition.y, dirPosition.z]}
              shadow-camera-far={150}
              shadow-camera-left={-100}
              shadow-camera-right={100}
              shadow-camera-top={100}
              shadow-camera-bottom={-100}
              shadow-normalBias={0.20}
            // shadow-normalBias={dirNormalBias}
            />
          </>
        );
        break;

      case "r3f_office1":
        return (
          <>
            <ambientLight
              visible={true}
              // visible={ambientLightVisible}
              intensity={1.5}
            // intensity={ambientLightIntensity}
            />
            <directionalLight
              visible={true}
              // visible={visible}
              intensity={1.7}
              // intensity={intensity}
              castShadow={true}
              shadow-mapSize-height={512}
              shadow-mapSize-width={512}
              position={[23.9, 100.0, 0]}
              // position={[position.x, position.y, position.z]}
              shadow-camera-far={150}
              shadow-camera-left={-100}
              shadow-camera-right={100}
              shadow-camera-top={100}
              shadow-camera-bottom={-100}
              // shadow-normalBias={normalBias}
              shadow-normalBias={0.6}
            />
          </>
        );
        break;

      case "r3f_showroom":
        return (
          <>
            <ambientLight
              visible={true}
              // visible={ambientLightVisible}
              intensity={1}
            // intensity={ambientLightIntensity}
            />
            <directionalLight
              visible={true}
              // visible={visible}
              intensity={1}
              // intensity={intensity}
              castShadow={true}
              shadow-mapSize-height={512}
              shadow-mapSize-width={512}
              position={[23.9, 100.0, 0]}
              // position={[position.x, position.y, position.z]}
              shadow-camera-far={150}
              shadow-camera-left={-100}
              shadow-camera-right={100}
              shadow-camera-top={100}
              shadow-camera-bottom={-100}
              // shadow-normalBias={normalBias}
              shadow-normalBias={0.6}
            />
          </>
        );
        break;

      case "r3f_showroom2":
        return (
          <>
            <ambientLight
              visible={true}
              // visible={ambientLightVisible}
              intensity={1}
            // intensity={ambientLightIntensity}
            />
            <directionalLight
              visible={true}
              // visible={visible}
              intensity={1}
              // intensity={intensity}
              castShadow={true}
              shadow-mapSize-height={512}
              shadow-mapSize-width={512}
              position={[23.9, 100.0, 0]}
              // position={[position.x, position.y, position.z]}
              shadow-camera-far={150}
              shadow-camera-left={-100}
              shadow-camera-right={100}
              shadow-camera-top={100}
              shadow-camera-bottom={-100}
              // shadow-normalBias={normalBias}
              shadow-normalBias={0.6}
            />
          </>
        );
        break;

      case "r3f_office2":
        return (
          <>
            <ambientLight
              visible={true}
              // visible={ambientLightVisible}
              intensity={1}
            // intensity={ambientLightIntensity}
            />
            <directionalLight
              visible={true}
              // visible={visible}
              intensity={1.5}
              // intensity={intensity}
              castShadow={true}
              shadow-mapSize-height={512}
              shadow-mapSize-width={512}
              position={[23.9, 100.0, 0]}
              // position={[position.x, position.y, position.z]}
              shadow-camera-far={150}
              shadow-camera-left={-100}
              shadow-camera-right={100}
              shadow-camera-top={100}
              shadow-camera-bottom={-100}
              // shadow-normalBias={normalBias}
              shadow-normalBias={0.6}
            />
          </>
        );
        break;

      case "r3f_turkey":
        return (
          <>
            <ambientLight
              visible={true}
              // visible={ambientLightVisible}
              intensity={1}
              // intensity={ambientLightIntensity}
              color="#e1f4ff"
            // color={ambientLightColor}
            />
            <directionalLight
              ref={dirLightRef}
              visible={true}
              // visible={dirVisible}
              // color={dirColor}
              color="#ffe2e2"
              intensity={3.0}
              // intensity={dirItensity} 
              castShadow={true}
              shadow-mapSize-height={512}
              shadow-mapSize-width={512}
              position={[27.6, 100.0, 24.8]}
              // position={[dirPosition.x, dirPosition.y, dirPosition.z]}
              shadow-camera-far={150}
              shadow-camera-left={-100}
              shadow-camera-right={100}
              shadow-camera-top={100}
              shadow-camera-bottom={-100}
              // shadow-normalBias={dirNormalBias}
              shadow-normalBias={0.6}
            />
            {/* <spotLight ref={spotLightRef}
              visible={spotLightVisible}
              intensity={spotLightIntensity}
              power={spotLightPower}
              angle={spotLightAngle}
              position={[spotLightPosition.x, spotLightPosition.y, spotLightPosition.z]}
            /> */}
            {/* <mesh
              ref={spotLightTargetRef}
              position={[spotLightTargetPosition.x, spotLightTargetPosition.y, spotLightTargetPosition.z]}
              scale={0.5}>
              <boxGeometry />
              <meshBasicMaterial color="mediumpurple" />
            </mesh> */}
          </>
        );
        break;

      case "r3f_hall":
        return (
          <>
            <ambientLight
              visible={true}
              // visible={ambientLightVisible}
              intensity={1}
              // intensity={ambientLightIntensity}
              color="#e1f4ff"
            // color={ambientLightColor}
            />
            <directionalLight
              ref={dirLightRef}
              visible={true}
              // visible={dirVisible}
              // color={dirColor}
              color="#ffffff"
              intensity={2.0}
              // intensity={dirItensity} 
              castShadow={true}
              shadow-mapSize-height={512}
              shadow-mapSize-width={512}
              position={[0.0, 10.0, 0]}
              // position={[dirPosition.x, dirPosition.y, dirPosition.z]}
              shadow-camera-far={150}
              shadow-camera-left={-100}
              shadow-camera-right={100}
              shadow-camera-top={100}
              shadow-camera-bottom={-100}
              // shadow-normalBias={dirNormalBias}
              shadow-normalBias={0.6}
            />
            {/* <spotLight ref={spotLightRef}
                visible={spotLightVisible}
                intensity={spotLightIntensity}
                power={spotLightPower}
                angle={spotLightAngle}
                position={[spotLightPosition.x, spotLightPosition.y, spotLightPosition.z]}
              /> */}
            {/* <mesh
                ref={spotLightTargetRef}
                position={[spotLightTargetPosition.x, spotLightTargetPosition.y, spotLightTargetPosition.z]}
                scale={0.5}>
                <boxGeometry />
                <meshBasicMaterial color="mediumpurple" />
              </mesh> */}
          </>
        );
        break;

      case "r3f_hall2":
        return (
          <>
            <ambientLight
              visible={true}
              // visible={ambientLightVisible}
              intensity={1}
              // intensity={ambientLightIntensity}
              color="#e1f4ff"
            // color={ambientLightColor}
            />
            <directionalLight
              ref={dirLightRef}
              visible={true}
              // visible={dirVisible}
              // color={dirColor}
              color="#ffffff"
              intensity={2.0}
              // intensity={dirItensity} 
              castShadow={true}
              shadow-mapSize-height={512}
              shadow-mapSize-width={512}
              position={[-7.1, 9.9, 2.7]}
              // position={[dirPosition.x, dirPosition.y, dirPosition.z]}
              shadow-camera-far={150}
              shadow-camera-left={-100}
              shadow-camera-right={100}
              shadow-camera-top={100}
              shadow-camera-bottom={-100}
              // shadow-normalBias={dirNormalBias}
              shadow-normalBias={0.6}
            />
            {/* <spotLight ref={spotLightRef}
                  visible={spotLightVisible}
                  intensity={spotLightIntensity}
                  power={spotLightPower}
                  angle={spotLightAngle}
                  position={[spotLightPosition.x, spotLightPosition.y, spotLightPosition.z]}
                /> */}
            {/* <mesh
                  ref={spotLightTargetRef}
                  position={[spotLightTargetPosition.x, spotLightTargetPosition.y, spotLightTargetPosition.z]}
                  scale={0.5}>
                  <boxGeometry />
                  <meshBasicMaterial color="mediumpurple" />
                </mesh> */}
          </>
        );
        break;

      default:
        break;
    }
  };

  return (
    <>
      <group name="lightGroup">
        <TempLight />
      </group>
    </>
  );
}

export { Lights };
