import React from "react";
import "./Videoslider.css";
import useGetNameCookie from "../../common/getNameCookie";
import HomePage from "../../assets/Videos/video.mp4";
import HrmetaversePage from "../../assets/Videos/hr-metaverse.mp4";
import Header from "../../layouts/header/Header";
import InfoPopup from "../InfoPopup/InfoPopup";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ArrowLongRight } from "../../common/svgIcon";
import {
  openModal,
  setModalFor,
} from "../../features/EnterWorldPopUp/EnterWorldSlice";
// import TurkeyPage from "../../assets/Videos/Turkey.mp4";
import { generateRandomUserInfo } from "../../common/commonFunctions";
import { setRoomInfo } from "../../features/RoomInfo/RoomInfoDataSlice";
import MeetingRoom from "../world/meetingRoom/MeetingRoom";
import { callEnterMetaBtnClick } from "../../common/axiosCalls";

const VideoAddslider = ({ forRoom }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = useGetNameCookie();
  const authentication = useSelector((store) => store.authentication);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [isOpen, setIsOpen] = useState(null);

  const tidioIframe = document.querySelector("#tidio-chat-iframe");
  if (tidioIframe) {
    tidioIframe.style.display = "block";
  }

  const diveInHandler = async () => {
    const userInfo = generateRandomUserInfo();

    document.cookie = `avtar_name=${userInfo.avtar_name}; expires=${new Date(
      Date.now() + 1 * 1 * 60 * 60 * 1000
    ).toUTCString()}`;
    document.cookie = `avtar_id=${userInfo.avtar_id}; expires=${new Date(
      Date.now() + 1 * 1 * 60 * 60 * 1000
    ).toUTCString()}`;

    dispatch(
      setRoomInfo({
        avtar_id: userInfo.avtar_id,
        avtar_name: userInfo.avtar_name,
        room_type: "r3f_mr2",
        room_name: "",
        socket_name: userInfo.avtar_name,
        scene_name: "r3f_mr2",
        is_admin: false,
        socket_name: userInfo.avtar_name,
      })
    );
    setIsOpen(true);
  };

  const enterMetaverseHandler = async () => {
    await callEnterMetaBtnClick({
      webpage_link: window.location.href
    })

    if (forRoom === "home-page") {
      navigate("/meeting-room")
      return;
    }

    if (forRoom === "meeting-room") {
      diveInHandler();
      return;
    }
    if (!cookies) {
      dispatch(
        setModalFor({
          purpose: "R3F entry",
          data: {},
        })
      );
      dispatch(openModal());
    } else {
      navigate("/room/r3fmr/defaultmr/0");
    }
  };

  const visitMyMetaverseHandler = () => {
    navigate("/my-metaverse");
  };

  return (
    <>
      <div
        className={
          forRoom === "meeting-room" ? "background_img" : "container-home"
        }
      >
        {forRoom === "meeting-room" ? (
          <Header isMeetingRoom={true} setIsOpen={setIsOpen} forRoom={forRoom} />
        ) : (
          <Header />
        )}

        <script
          src="//code.tidio.co/bebjuc7aysuignfq1xydnvv8tpssrzux.js"
          async
        ></script>
        {forRoom === "home-page" && (
          <video
            className={
              forRoom === "meeting-room"
                ? "background-video"
                : "container-video"
            }
            playsInline
            autoPlay
            muted
            loop
          >
            <source src={HomePage} type="video/mp4" />
          </video>
        )}
        {forRoom === "hr-metaverse" && (
          <video className="background-video" playsInline autoPlay muted loop>
            <source src={HrmetaversePage} type="video/mp4" />
          </video>
        )}

        {forRoom === "turkey" && (
          <video className="background-video" playsInline autoPlay muted loop>
            {/* <source src={TurkeyPage} type="video/mp4" /> */}
          </video>
        )}

        {forRoom === "meeting-room" && (
          <div className="main-video-overlay">
            <video className="background-video" playsInline autoPlay muted loop>
              <source src={HrmetaversePage} type="video/mp4" />
            </video>
          </div>
        )}

        <div id={forRoom === "meeting-room" ? "overlay" : null}>
          <div className="video-title">
            {forRoom === "home-page" ? (
              <h1 className="home_name">
                Unlock Infinite Possibilities <br /> Create & Enter the
                Metaverse
              </h1>
            ) : (
              <h1 className="home_name">
                Elevate Your Meetings Experiences in Metaverse <br />
                Through Seamless Avatars, Voice and Text Chats,
                <br />
                Screensharing, and much more!
              </h1>
            )}
          </div>
          <div className="content">
            {authentication.isAuthenticated ? (
              <button
                title="My Metaverse"
                className="main--down-btn1"
                onClick={visitMyMetaverseHandler}
              >
                My Worlds
              </button>
            ) : (
              <button
                title="Create Your Metaverse it's Free"
                className="mainbutton_main"
                onClick={() => enterMetaverseHandler()}
              >
                {forRoom === "home-page"
                  ? "Enter Meeting Room"
                  : "Create Meeting Room"}
                <ArrowLongRight />
              </button>
            )}
          </div>
        </div>
      </div>

      {isOpen && <MeetingRoom setIsOpen={setIsOpen} isOpen={isOpen} />}

      {showInfoPopup && (
        <InfoPopup
          setShowInfoPopup={setShowInfoPopup}
          showInfoPopup={showInfoPopup}
        />
      )}
    </>
  );
};

export default VideoAddslider;
