import React, { useRef } from "react";
import styles from "./LeaveRoom.module.css";
import { useState } from "react";
import {
  callDeactivateRoomVisitor,
  callLeaveWorldReason,
} from "../../../common/axiosCalls";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useGetNameCookie from "../../../common/getNameCookie";
import { setToast } from "../../../features/ErrorHandling/ErrorHandlingSlice";

const LeaveRoom = ({ setIsOpen, room_name }) => {
  const authentication = useSelector((store) => store.authentication);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const input1 = useRef();
  const input2 = useRef();
  const input3 = useRef();
  const input4 = useRef();
  const input5 = useRef();
  const cookies = useGetNameCookie();

  const [showEmailAndPhone, setShowEmailAndPhone] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      !input1.current.checked &&
      !input2.current.checked &&
      !input3.current.checked
    ) {
      dispatch(
        setToast({
          type: "error",
          message: `Please select at least one reason for leaving !`,
        })
      );
      return;
    }

    if (input3.current.checked) {
      if (!input4.current.value.trim()) {
        dispatch(
          setToast({
            type: "error",
            message: "Please Enter Email Id .",
          })
        );
        return;
      }
    }

    if (input3.current.checked) {
      if (!input5.current.value.trim()) {
        dispatch(
          setToast({
            type: "error",
            message: "Please Enter Phone Number.",
          })
        );
        return;
      }
    }

    callLeaveWorldReason({
      avtar_id: cookies.avtar_id || authentication.userDetails.avtar_id,
      avtar_name: cookies.avtar_name || authentication.userDetails.avtar_name,
      leave_reason_first: input1.current.checked
        ? "Unable to operate the platform"
        : "null",
      leave_reason_second: input2.current.checked
        ? "Not interested in environment"
        : "null",
      leave_reason_third: input3.current.checked
        ? "I need support and trainig"
        : "null",
      email: input3.current.checked ? input4.current.value : "",
      phone: input3.current.checked ? input5.current.value : "",
    }).then((res) => {
      if (authentication.isAuthenticated) {
        callDeactivateRoomVisitor({ room_name }).then(() => {
          navigate("/");
        });
      } else {
        navigate("/");
      }
      dispatch(
        setToast({
          type: "success",
          message:
            "Thank you for your valuable feedback. World Beyond team will contact you soon.",
        })
      );
    });
  };

  const SkipAndLeaveHandler = () => {
    if (authentication.isAuthenticated) {
      callDeactivateRoomVisitor({ room_name }).then(() => {
        navigate("/");
      });
    } else {
      navigate("/");
    } 
  };

  return (
    <>
      <div className={styles["main_1"]}>
        <div className={styles["leaveRoom_Wrapper"]}>
          <button
            title="Close"
            className={styles["closeBtn"]}
            onClick={() => {
              setIsOpen(null);
            }}
          >
            <svg
              className={styles["close-button-svg"]}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
          </button>
          <div className={styles["leave_section"]}>
            <h4>kindly provide us the reason for leaving.</h4>
            <div className={styles["checkbox-form"]}>
              <div className={styles["LeaveInput"]}>
                <input
                  ref={input1}
                  className={styles["checkmark"]}
                  type="checkbox"
                />
                <label htmlFor="myInput">Unable to operate the platform.</label>
              </div>
              <div className={styles["LeaveInput"]}>
                <input
                  ref={input2}
                  className={styles["checkmark"]}
                  type="checkbox"
                />
                <label htmlFor="myInput">Not interested in environment.</label>
              </div>
              <div className={styles["LeaveInput"]}>
                <input
                  ref={input3}
                  className={styles["checkmark"]}
                  type="checkbox"
                  onChange={() => {
                    setShowEmailAndPhone(!showEmailAndPhone);
                  }}
                />
                <label htmlFor="myInput">I need support and training.</label>
              </div>
              {showEmailAndPhone && (
                <div className={styles["thirdInput"]}>
                  <input
                    ref={input4}
                    className={styles["extra_input"]}
                    type="email"
                    name="email"
                    placeholder="Enter Email Id"
                  />
                  <input
                    ref={input5}
                    className={styles["extra_input"]}
                    type="number"
                    name="phone"
                    placeholder="Enter Phone Number"
                  />
                </div>
              )}
            </div>

            <div className={styles["confirm__box_buttons"]}>
              <button
                className={styles["confirm_cancel"]}
                onClick={submitHandler}
              >
                Submit
              </button>
            </div>
          </div>
          <button
            className={styles["skip_and_Leave"]}
            onClick={SkipAndLeaveHandler}
          >
            Skip & Leave
          </button>
        </div>
      </div>
    </>
  );
};

export default LeaveRoom;
