
import React from 'react';
import Header from '../../layouts/header/Header';
import Footer from '../../layouts/footer/Footer';
import "../policyCSS/policy.css";

const TermsOfUse = () => {
  return (
    <>
    <Header/>
        <section className="d-section position-relative" id="">
			
            <div className="container">
                <div className="row row-30">
                    <div className="col-md-12 section-content" >
                        <h2 className="text-center title"> Terms of Use </h2>
                    </div>
                </div>
                <div className="row row-30 ">
                    <div className="section-content1" >
                        <p><strong>Updated on : 14 February 2022.</strong></p>
                    </div>
                    <div className="section-content1">
                        
                        <h5><strong>1.Introduction </strong></h5>
                        
                        <p>
                           These Worldbeyond Terms of Use (the “Terms”) set forth the terms and conditions under which CWW Limited, a company incorporated in India,
                           including its subsidiaries and affiliates (collectively, “CWW LTD”, “us”, “our”, or “we”), and you (“you” or “your”) agree to be governed as 
                           it relates to any of your activity in The Worldbeyond, a decentralized, community-driven platform created and owned by CWW LTD. These Terms 
                           govern your access to, use of, and interaction with other user, including any content, functionality, and services offered on or through The 
                           Worldbeyond and your creation of any Assets (collectively, the “Services”). 
                        </p>
                        <p>
                            Please read these Terms carefully before you start to use The Worldbeyond. By using The Worldbeyond, you accept and agree to be bound and 
                            abide by these Terms, the extra Terms of Use which can be found at URL:  our Privacy Policy.
                        </p>
                        <p>
                            The Worldbeyond is offered and available to users who are 18 years of age or older. By accessing or using CWW LTD, you represent and warrant 
                            that you are of legal age to form a binding contract with the Worldbeyond and meet the foregoing eligibility requirements. If you do not meet
                            all these requirements, you must not access or use CWW LTD.
                        </p>
                        <p>
                            As per the endowment received, the CWW LTD makes available the Tools and the Site free of charge in order to allow different interactions with 
                            the Worldbeyond platform a decentralized virtual world. The CWW LTD does not own or control Virtual land, as ownership and governance is 
                            decentralized in the community through this Platform
                        </p>
                    
                     </div>
                     
                    
                    <div className="section-content1">
                        <h5><strong>2.Acceptance of Terms </strong></h5>
                        <p>
                           The CWW LTD, acting for the benefit of the  Virtual land community as a whole, holds the intellectual property rights over, and makes available, 
                           (a) the Client, the Marketplace, Events, Forum, the Land Manager, the Command Line Interface, the Rewards tool which, with any other features, tools
                           and/or materials made available from time to time by the CWW LTD, are referred to herein as the "Tools" and (b) the website
                           <a href="https://cwwlimited.com/" target="_blank">https://cwwlimited.com/</a>. (the "Site").
                           Please read these Terms of Use (the "Terms" or "Terms of Use") carefully before using the Tools and the Site. By using or otherwise accessing the 
                           Tools and the Site, you: (1) accept and agree to be bound by these Terms; (2) accept that the Tools are still in testing phase and that you use 
                           at your own risk, as further explained below; (3) represent that you are old enough to use the Tools and the Site pursuant to Sections 3 and 9
                           below; (4) consent to the collection, use, disclosure and other handling of information as described in the Privacy Policy, 
                           available here <a href="https://www.Worldbeyond.live/privacy-policy" target="_blank">https://www.Worldbeyond.live/privacy-policy</a> ;
                           (5) accept and agree (5.1.) the Content Policy approved by the ………….......
                           (name/position of the authority), available here <a href="https://www.Worldbeyond.live/content-policy"
                           target="_blank">https://www.Worldbeyond.live/content-policy </a> , and (5.2.) any additional terms and conditions of participation
                           issued by the CWW LTD from time to time. If you do not agree to the Terms, then you must not access or use the Tools or the Site.
                        </p>
                        <p>
                            CWW LTD has no continuing obligation to operate the Tools and the Site and may cease to operate one or more of the Tools in the future, 
                            at its exclusive discretion, with no liability whatsoever in connection thereto.
                        </p>
                        <p>
                            With respect to the source code of the software of the Tools that has been released under an open-source license, such software code must be used
                            in accordance with the applicable open source license terms and conditions as described in Section 13 below. Other similar tools might be developed
                            in the future by the community or third parties.
                        </p>
                        <p>
                            “NFT” in these Terms means a non-fungible token or similar digital item implemented on a blockchain (such as the Ethereum blockchain), which uses
                            smart contracts to link to or otherwise be associated with certain content or data.
                        </p>
                        <p>
                            For purposes of these Terms, “user”, “you”, and “your” means you as the user of the Service. If you use the Service on behalf of a company or other 
                            entity then “you” includes you and that entity, and you represent and warrant that (a) you are an authorized representative of the entity with the authority
                            to bind the entity to these Terms, and (b) you agree to these Terms on the entity’s behalf.
                        </p>
                        <p>
                            CWW LTD is not a wallet provider, exchange, broker, financial institution, or creditor. CWWPL provides service that helps users discover 
                            and directly interact with each other and NFTs available on public blockchains. We do not have custody or control over the NFTs or blockchains
                            you are interacting with, and we do not execute or effectuate purchases, transfers, or sales of NFTs. To use our Service, you must use a
                            third-party wallet which allows you to engage in transactions on blockchains.
                        </p>
                        <p>
                            we have a growing number of services, we sometimes need to provide additional terms for specific services (and such services are deemed part of
                            the “Service” hereunder and shall also be subject to these Terms). Those additional terms and conditions, which are available with the relevant
                            service, then become part of your agreement with us if you use those services. In the event of a conflict between these Terms and any additional
                            applicable terms we may provide for a specific service, such additional terms shall control for that specific service.
                        </p>
                        <p>
                            CWW LTD reserves the right to change or modify these Terms at any time and in our sole discretion. If we make material changes to these Terms, 
                            we will use reasonable efforts to provide notice of such changes, such as by providing notice through the Service or updating the “Last Updated” 
                            date at the beginning of these Terms. By continuing to access or use the Service, you confirm your acceptance of the revised Terms and all of 
                            the terms incorporated therein by reference effective as of the date these Terms are updated. It is your sole responsibility to review the
                            Terms from time to time to view such changes and to ensure that you understand the terms and conditions that apply when you access or use 
                            the Service.
                        </p>
                    </div>
                    
                    
                    <div className="section-content1">
                        <h5><strong>3. Definitions </strong></h5>
                        <p>
                          The following definitions shall apply only to Section 19 of the Terms: 
                        </p>
                        <p>
                            <strong>"Contribution"</strong> shall mean any work of authorship, including the original version of the Work and any modifications or additions to that Work or
                            Derivative Works thereof, that is intentionally submitted to Licensor for inclusion in the Work by the copyright owner or by an individual or 
                            Legal Entity authorized to submit on behalf of the copyright owner. For the purposes of this definition, "submitted" means any form of electronic,
                            verbal, or written communication sent to the Licensor or its representatives, including but not limited to communication on electronic mailing
                            lists, source code control systems, and issue tracking systems that are managed by, or on behalf of, the Licensor for the purpose of discussing 
                            and improving the Work, but excluding communication that is conspicuously marked or otherwise designated in writing by the copyright owner as 
                            "Not a Contribution."
                        </p>
                        <p>
                            <strong>"Derivative Works"</strong> shall mean any work, whether in Source or Object form, that is based on (or derived from) the Work and for which the editorial
                            revisions, annotations, elaborations, or other modifications represent, as a whole, an original work of authorship. For the purposes of this
                            License, Derivative Works shall not include works that remain separable from, or merely link (or bind by name) to the interfaces of, the Work
                            and Derivative Works thereof.
                        </p>
                        <p>
                            <strong>"Legal Entity"</strong> shall mean the union of the acting entity and all other entities that control, are controlled by, or are under common control 
                            with that entity. For the purposes of this definition, "control" means (i) the power, direct or indirect, to cause the direction or management
                            of such entity, whether by contract or otherwise, or (ii) ownership of fifty percent (50%) or more of the outstanding shares, or (iii) beneficial
                            ownership of such entity.
                        </p>
                        <p>
                           <strong> "License"</strong> shall mean the terms and conditions for the use, reproduction, and distribution of the open source, Copyright license, Patent License.
                        </p>
                        <p>
                           <strong> "Licensor"</strong> shall mean the copyright owner or entity authorized by the copyright owner that is granting the License.
                        </p>
                        <p>
                            <strong>"Object"</strong> form shall mean any form resulting from mechanical transformation or translation of a Source form, including but not limited to compiled 
                            object code, generated documentation, and conversions to other media types.
                        </p>
                        <p>
                            <strong>"Source"</strong> form shall mean the preferred form for making modifications, including but not limited to software source code, documentation source, and 
                            configuration files.
                        </p>
                        <p>
                            <strong>"Work"</strong> shall mean the work of authorship of the Tools, made available under the License, as indicated by a copyright notice that is included in 
                            or attached to the Work.
                        </p>
                    </div>
                
                    <div className="section-content1">
                        <h5><strong>4.Eligibility </strong></h5>
                        <p>
                           You hereby represent and warrant that you are fully able and competent to enter into the terms, conditions, obligations, affirmations, 
                           representations and warranties set forth in these Terms and to abide by and comply with these Terms. The World Beyond Metaverse is a global platform and by
                           accessing the Content, the Site or Tools, you are representing and warranting that you are of the legal age of majority in your jurisdiction as is 
                           required to access such Tools and Content and enter into arrangements as provided by the Tools. You further represent that you are otherwise legally 
                           permitted to use the Tools in your jurisdiction including owning cryptographic tokens, if necessary and interacting with the Site, Tools or Content in 
                           any way. You further represent that you are responsible for ensuring compliance with the laws of your jurisdiction and acknowledge that the Digi 
                           Metaverse is not liable for your compliance or failure to comply with such laws. You further represent and warrant that all funds or assets used by you
                           have been lawfully obtained by you in compliance with all applicable laws.
                        </p>
                    </div>
                
                
                    <div className="section-content1">
                        <h5><strong>5.Disclaimer </strong></h5>
                        <p>
                           You acknowledge and agree that your use of the Tools and the Site is at your sole risk. The Tools and the Site are provided on an "AS IS" and "as
                           available" basis, without warranties of any kind, either express or implied, including, without limitation, implied warranties of merchantability,
                           fitness for a particular purpose or non-infringement. You acknowledge and agree that the Digi Meteverse has no CWW LTD obligation to take any
                           action regarding: which users gain access to or use the Tools; what effects the Tools may have on you; the LAND you own; how you may interpret or
                           use the Tools; or what actions you may take or fail to take as a result of having been exposed to the Tools. You release the CWW LTD from all
                           liability for your inability to access to the Site, Tools or any Content therein. CWW LTD is not and cannot be responsible for and makes no 
                           representations, warranties or covenants concerning any Content contained in or accessed through the Site, Tools, and CWW LTD will not be 
                           responsible or liable for the accuracy, copyright compliance, legality or decency of material contained in or accessed through the Tools.
                           The Content Policy over Virtual land is established and enforced exclusively by the Virtual land community through CWW LTD.
                        </p>
                    </div>
                            
                
                    
                    
                    <div className="section-content1">
                        <h5><strong>6.Account Access and Security </strong></h5>
                        <p>
                           Access to the Tools is provided via a third-party private key manager selected by you (e.g., a Web3 Provider, Metamask, a USB interface for 
                           Ledger Wallet, the Mist browser, or other). Security and secure access to each account in the Tools is provided solely by the third-party 
                           private key manager you select to administer your private key. You and the third-party private key manager you select are entirely responsible
                           for security related to access of the Tools and all information provided by you to such third-party provider (including without limitation,
                           email or phone number. The World Beyond Metaverse bears no responsibility for any breach of security or unauthorized access to your account (the "Account").
                           You are advised to: (a) avoid any use of the same password with your selected third-party private key manager that you have ever used outside 
                           of the third-party private key manager; and (b) keep your password and any related secret information secure and confidential and do not share
                           them with anyone else. 
                        </p>
                        <p>
                            You are solely responsible for all activities conducted through your Account whether or not you authorize the activity. In the event that fraud, 
                            illegality or other conduct that violates this Agreement is discovered or reported (whether by you or someone else) that is connected with your
                            Account, the CWW LTD may suspend or block your Account (or Accounts). You are solely responsible for maintaining the confidentiality of your
                            password and for restricting access to your devices. You are solely responsible for any harm resulting from your disclosure, or authorization
                            of the disclosure, of your password or from any person's use of your password to gain access to your Account. You acknowledge that in the event 
                            of any unauthorized use of or access to your Account, password or other breach of security, you accept that due to the nature of the Tools and
                            the platform itself, The World Beyond Metaverse will be unable to remedy any issues that arise.
                        </p>
                        <p>
                            The World Beyond Metaverse will not be liable for any loss or damage (of any kind and under any legal theory) to you or any third party arising from 
                            your inability or failure for any reason to comply with any of the foregoing obligations, or for any reason whatsoever, except fraud on our part
                            the World Beyond Metaverse cannot and will not be liable for any loss or damage arising from your sharing or other loss of your private key or related 
                            information, or any other damage or loss arising from unauthorized access to your Account.
                        </p>
                        <p>
                            Transactions that take place using the Tools are confirmed and managed via the Ethereum blockchain, if necessary. You understand that your 
                            Ethereum public address will be made publicly visible whenever you engage in a transaction using the Tools. 
                        </p>
                        <p>
                            If you reside in a jurisdiction where online gambling is banned (such as the United States of America, China and South Korea) you must
                            refrain from accessing Content which includes online gambling.
                        </p>
                    </div>
                    
                    
                    
                    <div className="section-content1">
                        <h5><strong>7.Ownership </strong></h5>
                        <p>
                           The Service, including its “look and feel” (e.g., text, graphics, images, logos, page headers, button icons, and scripts), proprietary
                           content, information and other materials, and all content and other materials contained therein, including, without limitation, the World Beyond Metaverse
                           logo and all designs, text, graphics, pictures, data, software, sound files, other files, and the selection and arrangement thereof are 
                           the proprietary property of CWW LTD or our affiliates, licensors, or users, as applicable, and you agree not to take any action(s) inconsistent
                           with such ownership interests. We and our affiliates, licensors, and users, as applicable, reserve all rights in connection with the Service and
                           its content, including, without limitation, the exclusive right to create derivative works.
                        </p>
                        <p>
                            The World Beyond Metaverse name, logo, trademarks, and any CWW LTD product or service names, designs, logos, and slogans are the intellectual property 
                            of CWW LTD or our affiliates or licensors and may not be copied, imitated or used, in whole or in part, without our prior written permission in
                            each instance. You may not use any metatags or other “hidden text” utilizing “World Beyond Metaverse or any other name, trademark or product or service
                            name of the World Beyond Metaverse or our affiliates or licensors without our prior written permission. In addition, the “look and feel” of the Service
                            constitutes the service mark, trademark, or trade dress of World Beyond Metaverse and may not be copied, imitated or used, in whole or in part, without
                            our prior written permission.
                        </p>
                        <p>
                            All other third-party trademarks, registered trademarks, and product names mentioned on the Service or contained in the content linked to or associated
                            with any NFTs displayed on the Service are the property of their respective owners and may not be copied, imitated, or used, in whole or in part,
                            without the permission of the applicable intellectual property rights holder. Reference to any products, services, processes or other information
                            by name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship, or recommendation by the World Beyond Metaverse.
                        </p>
                        <p>
                            We welcome feedback, comments, and suggestions for improvements to the Service (“Feedback”). You acknowledge and expressly agree that any contribution of
                            Feedback does not and will not give or grant you any right, title, or interest in the Service or in any such Feedback. You agree that the World Beyond Metaverse 
                            may use and disclose Feedback in any manner and for any purpose whatsoever without further notice or compensation to you and without retention by you of
                            any proprietary or other right or claim. You hereby assign to CWW LTD any and all right, title, and interest (including, but not limited to, any patent, 
                            copyright, trade secret, trademark, show-how, know-how, moral rights and any and all other intellectual property right) that you may have in and to any
                            and all Feedback.
                        </p>
                    </div>
                    
                    <div className="section-content1">
                        <h5><strong>8.Taxes</strong></h5>
                        <p>
                          You are solely responsible for determining what, if any, Taxes apply to your LAND parcel related transactions, and any other transaction conducted by
                          you. CWW LTD does not, and will not, have any insight into or control over any transactions conducted by you in  virtual land, and thus is not 
                          responsible for determining the Taxes that apply to your transactions entered through the Tools or otherwise involving any LAND parcel, or any
                          other related transaction, and is not to act as a withholding Tax agent in any circumstances whatsoever.
                        </p>
                    </div>
                    
                    
                    <div className="section-content1">
                        <h5><strong>9. Uses of the Tools </strong></h5>
                        <p>
                           You acknowledge and agree that the Tools do not store, send, or receive LAND parcels. This is because LAND parcels exist only by virtue of the 
                           ownership record maintained on the Tools' supporting blockchain in the Ethereum network. Any transfer of a LAND parcel occurs within the 
                           supporting blockchain in the Ethereum network, and not within the Tools.
                        </p>
                    </div>
                    <div className="section-content1">
                        <h5><strong>10.Application Security</strong></h5>
                        <p>
                           You acknowledge and agree that the Site, the Tools and related applications are software code and are subject to flaws and acknowledge that you
                           are solely responsible for evaluating any smart contract, code provided by the Site, Tools or Content and the trustworthiness of any third-party 
                           websites, products, smart-contracts, or Content you access or use through the Tools. You further expressly acknowledge and agree that Ethereum
                           applications can be written maliciously or negligently, that the World Beyond Metaverse cannot be held liable for your interaction with such applications
                           and that such applications may cause the loss of property or even identity. This warning and others later provided by the World Beyond Metaverse in no
                           way evidence or represent an on-going duty to alert you to all of the potential risks of utilizing the Tools or the Site.
                        </p>
                    </div>
                    
                    
                    <div className="section-content1">
                        <h5><strong>11.Third Party Providers</strong></h5>
                        <p>
                           The World Beyond Metaverse neither owns nor controls MetaMask, Ledger Wallet, the Mist browser, Google Chrome, the Ethereum network, any Web3 Provider 
                           any other third-party site, product, or service that you might access, visit, or use for the purpose of enabling you to use the various features
                           of the Tools. The World Beyond Metaverse shall not be liable for the acts or omissions of any such third parties, nor shall the World Beyond Metaverse be liable
                           for any damage that you may suffer as a result of your transactions or any other interaction with any such third parties.
                        </p>
                    </div>
                    <div className="section-content1">
                        <h5><strong>12.User Conduct</strong></h5>
                        <p>
                          Openness is one of our most prized values, and we’re committed to providing people from all walks of life and varying experience levels with this
                          platform with a colourful lens into different blockchains. However, to protect our community and comply with our legal obligations, we reserve the
                          right to take action, with or without advance notice, if we believe you have violated these Terms. This may include: removing the ability to view 
                          certain NFTs on the Service or use our Service to interact with the NFTs; disabling the ability to use the Service in conjunction with
                          buying/selling/leasing NFTs available on blockchains, if necessary; disabling your ability to access our Service; and/or other actions. 
                        </p>
                        <div>
                            You agree that you will not violate any law, contract, intellectual property or other third-party right, and that you are solely responsible for 
                            your conduct and content, while accessing or using the Service. You also agree that you will not:
                            <ol type="a">
                                <li>	Use or attempt to use another user’s Account without authorization from such user;</li>
                                <li>	Pose as another person or entity;</li>
                                <li>	Claim on the Digi  Metaverse username for the purpose of reselling it or otherwise engage in name squatting;</li>
                                <li>	Distribute spam, including through sending unwanted NFTs to other users;</li>
                                <li> 	Use the Service – including through disseminating any software or interacting with any API – that could damage, disable, overburden,
                                        or impair the functioning of the Service in any manner;</li>
                                <li> 	Bypass or ignore instructions that control access to the Service, including attempting to circumvent any rate limiting systems by
                                    using multiple API keys, directing traffic through multiple IP addresses, or otherwise obfuscating the source of traffic you send to 
                                        the World Beyond Metaverse;</li>
                               <li>	Use any data mining, robot, spider, crawler, scraper, script, browser extension, offline reader, or other automated means or interface not
                                    authorized by us to access the Service, extract data, or otherwise interfere with or modify the rendering of Service pages or functionality;
                                    </li>
                               <li>	Reverse engineer, duplicate, decompile, disassemble, or decode any aspect of the Service, or do anything that might discover source code 
                                    or bypass or circumvent measures employed to prevent or limit access to any service, area, or code of the Service;</li> 
                               <li>	Sell or resell the Service or attempt to circumvent any the World Beyond Metaverse fee systems;</li>
                               <li>	Engage in behaviours that have the intention or the effect of artificially causing a listing to appear at the top of search results, 
                                    or artificially increasing view counts, favourites, volume, or other metrics that the World Beyond Metaverse might use to sort search results;</li>
                               <li>
                                       Use the Service or data collected from our Service for any advertising or direct marketing activity (including without limitation, email
                                       marketing, SMS marketing, and telemarketing); </li>
                               <li>	Use the Service for money laundering, terrorist financing, or other illicit finance;</li>
                               <li>	Use the Service from a country sanctioned by the government of the United States or to facilitate transactions involving individuals
                                    sanctioned by the government of the United States or located in sanctioned countries;</li>
                               <li> Use the Service to carry out any financial activities subject to registration or licensing, including but not limited to
                                        creating, selling, or buying securities, commodities, options, or debt instruments; </li>
                               <li>	Use the Service to engage in price manipulation, fraud, or other deceptive, misleading, or manipulative activity;</li>
                               <li> Use the Service to buy, sell, or transfer stolen items, fraudulently obtained items, items taken without authorization, and/or 
                                    any other illegally obtained items;</li>
                                <li>	Infringe or violate the intellectual property rights or any other rights of others;</li>   
                                <li>	Create or display illegal content, such as content that may involve child sexual exploitation;</li>
                                <li>	Use the Service for any illegal or unauthorized purpose, including creating or displaying illegal content, such as content that
                                        may involve child sexual exploitation, or encouraging or promoting any activity that violates the Terms of Service;</li> 
                               <li>	Use the Service in any manner that could interfere with, disrupt, negatively affect or inhibit other users from fully enjoying the 
                                    Service.</li>       
                               <li>	Identification of the copyrighted work(s), trademark, publicity rights, or other intellectual property rights that you claim is being 
                                    infringed;</li>   
                               <li>	Identification of the allegedly infringing material that is requested to be removed, including a description of the specific location 
                                    (i.e., urls) on the Service of the material claimed to be infringing, so that we may locate the material;</li>     
                               <li>	Your contact information – at a minimum, your full legal name (not pseudonym) and email address;</li>     
                              <li>	A declaration that contains all of the following:
                                    <ul className="ml-3">
                                        <li>A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the 
                                        intellectual property rights owner, its agent, or the law;</li>
                                        <li>A statement that the information in the notice is accurate; and</li>
                                        <li>A statement under penalty of perjury that you are authorized to act on behalf of the intellectual property owner of the
                                        intellectual property that is allegedly being infringed.</li>
                                    </ul>
                              </li>
                              <li>Your physical or electronic signature (of your full legal name).</li>
                               
                            </ol>
                        </div>                        
                    </div>
                    
                    
                    <div className="section-content1">
                        <h5><strong>13.Intellectual Property Rights </strong></h5>
                        <p>
                           You are solely responsible for your use of the Service and for any information you provide, including compliance with applicable laws, 
                           rules, and regulations, as well as these Terms, including the User Conduct requirements outlined above. 
                        </p>
                        <p>
                            By using the Service in conjunction with creating, submitting, posting, promoting, or displaying content, or by complying with the Digi
                            Metaverse metadata standards in your metadata API responses, you grant us a worldwide, non-exclusive, sublicensable, royalty-free 
                            license to use, copy, modify, and display any content, including but not limited to text, materials, images, files, communications, 
                            comments, feedback, suggestions, ideas, concepts, questions, data, or otherwise, that you submit or post on or through the Service for 
                            our current and future business purposes, including to provide, promote, and improve the Service. This includes any digital file, art,
                            or other material linked to or associated with any NFTs that are displayed on the Service.
                        </p>
                        <p>
                            The World Beyond Metaverse does not claim that submitting, posting, or displaying this content on or through the Service gives the Digi 
                            Metaverse any ownership of the content. We're not saying we own it. We're just saying we might use it and show it off a bit.
                        </p>
                        <p>
                            You represent and warrant that you have, or have obtained, all rights, licenses, consents, permissions, power and/or authority 
                            necessary to grant the rights granted herein for any content that you create, submit, post, promote, or display on or through the Service.
                            You represent and warrant that such content does not contain material subject to copyright, trademark, publicity rights, or other 
                            intellectual property rights, unless you have necessary permission or are otherwise legally entitled to post the material and to grant 
                            the World Beyond Metaverse the license described above, and that the content does not violate any laws.
                        </p>
                        <p>For us to process your infringement claim regarding content on the Service, you must be the rightsholder or someone authorized to act on 
                        behalf of the rightsholder. If you choose to write to us by e-mail or physical mail instead, your notice must include:
                        </p>
                        <p>
                            Please note that we will forward your notice of intellectual property infringement, including your contact information, to the party who 
                            will have their content removed so they understand why it is no longer available on the World Beyond Metaverse and can also contact you to resolve 
                            any dispute.
                        </p>
                    </div>
                    
                    <div className="section-content1">
                        <h5><strong>14.Transactions and Feeds </strong></h5>
                        <p>
                          14.1 If you elect to purchase, trade, or sell a LAND parcel, Avatar, wearable or other non-fungible token ("NFT") with or from other users via
                          the Tools, any financial transaction that you engage in will be conducted solely through the Ethereum network, Layer 2 solutions or other 
                          sidechains via Web3 providers. CWW LTD will have no insight into or control over these payments or transactions, nor does it have the ability 
                          to reverse any transactions. With that in mind, CWW LTD will have no liability to you or to any third party for any claims or damages that may 
                          arise as a result of any transactions that you engage in via the Tools, or any other transactions that you conduct via the Ethereum network, 
                          and other networks, sidechains, Layer 2 solutions or Web3 providers.
                        </p>
                        <p>
                            14.2 Ethereum requires the payment of a transaction fee (a "Gas Fee") for every transaction that occurs on the Ethereum network. The Gas Fee
                            funds the network of computers that run the decentralized Ethereum network. This means that you will need to pay a Gas Fee for each transaction
                            that occurs via the Tools. You accept that the payment of the Gas Fee is inherent to the nature of the Ethereum network and alien to CWW LTD
                            or due to the use of the Tools.
                        </p>
                        {/* <p style="color:#8fdcff"> */}
                        <p>
                            14.3 As per a CWW LTD's decision, transactions taking place in the Marketplace will be subject to a ………..%........ (currency) cost 
                            (the "Marketplace Transaction Cost") calculated over the amount of ………(currency) involved in such transactions. Such …….(currency) cost will 
                            be borne by the participants to the transaction. All …………(currency) spent under the Marketplace Transaction Cost will be transferred to the 
                            treasury of the ………..(either to CWWPL or…..?) Please be aware that transactions in third-party NFT marketplaces (inside or outside ……….land) may
                            be subject to the fees charged by said third-party marketplaces as per the respective terms and conditions. The Foundation shall have no liability
                            whatsoever with regards to transactions conducted in third-party marketplaces.
                        </p>
                        <div>
                            <p>
                                14.4 You will be solely responsible to pay any and all sales, use, value-added and other taxes, duties, and assessments 
                                (except taxes that may apply on our net income) now or hereafter claimed or imposed by any governmental authority (collectively, "Taxes") associated
                                with your use of the Tools (including, without limitation, any Taxes that may become payable as the result of your ownership, or transfer of any NFT 
                                and any activity conducted by you in Virtual land). Except for income taxes levied on CWW LTD, you: 
                            </p>

                            <ol type="I">
                                <li>
                                    will pay or reimburse to CWW LTD for all national, federal, state, local or other taxes and assessments of any jurisdiction, including value
                                    added taxes and taxes as required by international tax treaties, customs or other import or export taxes, and amounts levied in lieu thereof 
                                    based on charges set, services performed or payments made hereunder, as are now or hereafter may be imposed under the authority of any national,
                                    state, local or any other taxing jurisdiction;
                                </li>
                                <li>
                                    shall not be entitled to deduct the amount of any such taxes, duties or assessments from payments made to the CWWPL pursuant to these Terms.
                                </li>                                
                            </ol>
                        </div>
                    </div>
                    
                    
                    <div className="section-content1">
                        <h5><strong>15. Changes</strong></h5>
                        <p>
                           The CWW LTD may approve changes to the Terms from time to time. When the CWW LTD approves changes, will make available the updated Terms through the Site
                           and update the "Last Updated" date at the beginning of these Terms accordingly. Please check these Terms periodically for changes. Any changes to the Terms
                           will apply on the date that they are made, and your continued access to or use of the Tools or the Site after the Terms have been updated will constitute 
                           your binding acceptance of the updates. If you do not agree to any revised Terms, you must not access or use the Tools or the Site. 
                        </p>
                    </div>
                    
                    <div className="section-content1">
                        <h5><strong>16. Children </strong></h5>
                        <p>
                          You affirm that you are over the age of 13, as the Tools is not intended for children under 13. IF YOU ARE 13 OR OLDER BUT UNDER THE AGE OF 18, OR THE LEGAL
                          AGE OF MAJORITY WHERE YOU RESIDE IF THAT JURISDICTION HAS AN OLDER AGE OF MAJORITY, THEN YOU AGREE TO REVIEW THESE TERMS WITH YOUR PARENT OR GUARDIAN TO MAKE 
                          SURE THAT BOTH YOU AND YOUR PARENT OR GUARDIAN UNDERSTAND AND AGREE TO THESE TERMS. YOU AGREE TO HAVE YOUR PARENT OR GUARDIAN REVIEW AND ACCEPT THESE TERMS ON
                          YOUR BEHALF. IF YOU ARE A PARENT OR GUARDIAN AGREEING TO THE TERMS FOR THE BENEFIT OF A CHILD OVER 13, THEN YOU AGREE TO AND ACCEPT FULL RESPONSIBILITY FOR THAT
                          CHILD'S USE OF THE TOOLS OR THE SITE, INCLUDING ALL FINANCIAL CHARGES AND LEGAL LIABILITY THAT HE OR SHE MAY INCUR.
                        </p>
                        <p>
                            TO ACCESS CERTAIN CONTENTS, YOU WILL NEED TO HAVE A MINIMUM AGE AS DETAILED IN THE CONTENT POLICY.
                        </p>
                        <p>
                            IN ALL CASES INVOLVING ONLINE GAMBLING YOU MUST BE OLDER THAN 18 YEARS (OR THE MINIMUM LEGAL AGE IN YOUR PLACE OF RESIDENCE).
                        </p>
                    </div>
                    
                    <div className="section-content1">
                        <h5><strong>17. Indemnity </strong></h5>
                        <p>
                           You shall release and indemnify, defend and hold harmless CWW LTD,  and its officers, directors, employees and representatives from and against any and all losses,
                           liabilities, expenses, damages, costs (including attorneys' fees and court costs) claims or actions of any kind whatsoever arising or resulting from your use of the
                           Tools or the Site, your violation of these Terms of Use, and any of your acts or omissions. CWW LTD reserves the right, at its own expense, to assume exclusive 
                           defense and control of any matter otherwise subject to indemnification by you and, in such case, you agree to cooperate with CWW LTD in the defense of such matter.
                        </p>
                    </div>
                    
                    <div className="section-content1">
                        <h5><strong>18. Disclaimers </strong></h5>
                        <p>
                           18.1 YOU ACKNOWLEDGE AND AGREE THAT YOU ASSUME FULL RESPONSIBILITY FOR YOUR USE OF THE SITE AND TOOLS. YOU ACKNOWLEDGE AND AGREE THAT ANY INFORMATION YOU SEND 
                           OR RECEIVE DURING YOUR USE OF THE SITE AND TOOLS MAY NOT BE SECURE AND MAY BE INTERCEPTED OR LATER ACQUIRED BY UNAUTHORIZED PARTIES. YOU ACKNOWLEDGE AND AGREE 
                           THAT YOUR USE OF THE SITE AND TOOLS IS AT YOUR OWN RISK. YOU ACKNOWLEDGE AND AGREE THAT THE TOOLS ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF 
                           ANY KIND, WHETHER EXPRESS OR IMPLIED. RECOGNIZING SUCH, YOU ACKNOWLEDGE AND AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER THE FOUNDATION,
                           ITS DIRECTORS, OFFICERS AND EMPLOYEES, THE DAO, NOR ITS SUPPLIERS OR LICENSORS WILL BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
                           PUNITIVE, EXEMPLARY OR OTHER DAMAGES OF ANY KIND, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER TANGIBLE OR INTANGIBLE LOSSES
                           OR ANY OTHER DAMAGES BASED ON CONTRACT, TORT, STRICT LIABILITY, INFRINGEMENT OF INTELLECTUAL PROPERTY OR THEFT OR MISAPPROPRIATION OF PROPERTY OR ANY OTHER THEORY
                           (EVEN IF CWW LTD HAD BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM THE SITE OR TOOLS; THE USE OR THE INABILITY TO USE THE SITE OR TOOLS; 
                           UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; STATEMENTS, CONTENT OR CONDUCT OF ANY THIRD PARTY ON THE SITE OR TOOLS; ANY ACTIONS CWWPL TAKES OR
                           FAILS TO TAKE AS A RESULT OF COMMUNICATIONS YOU SEND; HUMAN ERRORS; TECHNICAL MALFUNCTIONS; FAILURES, INCLUDING PUBLIC UTILITY OR TELEPHONE OUTAGES; OMISSIONS, 
                           INTERRUPTIONS, LATENCY, DELETIONS OR DEFECTS OF ANY DEVICE OR NETWORK, PROVIDERS, OR SOFTWARE (INCLUDING, BUT NOT LIMITED TO, THOSE THAT DO NOT PERMIT PARTICIPATION 
                           IN THE TOOLS); ANY INJURY OR DAMAGE TO COMPUTER EQUIPMENT; INABILITY TO FULLY ACCESS THE SITE OR TOOLS OR ANY OTHER WEBSITE; THEFT, TAMPERING, DESTRUCTION, OR 
                           UNAUTHORIZED ACCESS TO, IMAGES OR OTHER CONTENT OF ANY KIND; DATA THAT IS PROCESSED LATE OR INCORRECTLY OR IS INCOMPLETE OR LOST; TYPOGRAPHICAL, PRINTING OR OTHER 
                           ERRORS, OR ANY COMBINATION THEREOF; OR ANY OTHER MATTER RELATING TO THE SITE OR TOOLS. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE 
                           LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                        </p>
                        <p>
                            18.2 THE FOUNDATION HEREBY EXPRESSLY DISCLAIMS, WAIVES, RELEASES AND RENOUNCES ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF
                            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT.
                        </p>
                        <p>
                           18.3 WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, THE FOUNDATION, ITS DIRECTORS, OFFICERS AND EMPLOYEES , AND LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT:
                           (I) YOUR ACCESS TO OR USE OF THE TOOLS WILL MEET YOUR REQUIREMENTS, (II) YOUR ACCESS TO OR USE OF THE TOOLS WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR,
                           (III) USAGE DATA PROVIDED THROUGH THE TOOLS WILL BE ACCURATE, (III) THE TOOLS OR ANY CONTENT, SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THE TOOLS ARE FREE 
                           OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR (IV) THAT ANY DATA THAT YOU DISCLOSE WHEN YOU USE THE TOOLS WILL BE SECURE.
                        </p>
                        <p>
                            18.4 YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET, AND AGREE THAT THE FOUNDATION, ITS DIRECTORS, OFFICERS
                            AND EMPLOYEES AND THE DAO HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO ITS GROSS NEGLIGENCE.
                        </p>
                        <p>
                            18.5 THE CWW LTD, ITS DIRECTORS, OFFICERS AND EMPLOYEES WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR AS THE RESULT OF YOUR USE OF THE ETHEREUM
                            NETWORK OR THE METAMASK OR ANY OTHER ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (A) USER ERROR, SUCH AS FORGOTTEN 
                            PASSWORDS OR INCORRECTLY CONSTRUED SMART CONTRACTS OR OTHER TRANSACTIONS; (B) SERVER FAILURE OR DATA LOSS; (C) CORRUPTED WALLET FILES; (D) INTELLECTUAL PROPERTY 
                            INFRINGEMENT BY THE USERS; (E) UNAUTHORIZED ACCESS OR ACTIVITIES BY THIRD PARTIES, INCLUDING BUT NOT LIMITED TO THE USE OF VIRUSES, PHISHING, BRUTEFORCING OR OTHER 
                            MEANS OF ATTACK AGAINST THE TOOLS, ETHEREUM NETWORK, OR THE METAMASK OR OTHER ELECTRONIC WALLET.
                        </p>
                        <p>
                            18.6 LAND PARCELS, WEARABLES AND ANY AND ALL TRADE TOKENS NFT ARE INTANGIBLE DIGITAL ASSETS THAT EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE ETHEREUM NETWORK.
                            ALL SMART CONTRACTS ARE CONDUCTED AND OCCUR ON THE CRESCENDO LEDGER WITHIN THE ETHEREUM PLATFORM. THE CWW LTD HAS NO CONTROL OVER AND MAKES NO GUARANTEES OR PROMISES 
                            WITH RESPECT TO THE OWNERSHIP RECORD OR SMART CONTRACTS.
                        </p>
                        <p>
                            18.7 NFT TRADE TOKEN ARE INTANGIBLE DIGITAL ASSETS THAT EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE ETHEREUM NETWORK. ALL SMART CONTRACTS ARE CONDUCTED AND 
                            OCCUR ON THE CWW LTD LEDGER WITHIN THE ETHEREUM PLATFORM. THE CWW LTD HAS NO CONTROL OVER AND MAKE NO GUARANTEES OR PROMISES WITH RESPECT TO THE OWNERSHIP RECORD OR SMART
                            CONTRACTS. CWW LTD MAKES NO OFFER OR INVITATION TO ACQUIRE, PURCHASE, TRANSFER, SELL OR OTHERWISE DEAL IN THE World Beyond Metaverse.
                        </p>
                        <p>
                            18.8. THE CWW LTD IS NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAIN OR ANY OTHER FEATURES OF THE ETHEREUM NETWORK OR THE METAMASK OR OTHER ELECTRONIC WALLET, INCLUDING BUT NOT
                            LIMITED TO LATE REPORT BY DEVELOPERS OR REPRESENTATIVES (OR NO REPORT AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING THE ETHEREUM NETWORK, INCLUDING FORKS,
                            TECHNICAL NODE ISSUES, OR ANY OTHER ISSUES HAVING FUND LOSSES AS A RESULT.
                        </p>
                    </div>
                    
                    <div className="section-content1">
                        <h5><strong>19. Limitation on Liability </strong></h5>
                        <p>
                           19.1 YOU UNDERSTAND AND AGREE THAT CWW LTD, ITS OFFICERS, EMPLOYEES, DIRECTORS, AND LICENSORS WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL,
                           SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS 
                           (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER 
                           INTANGIBLE LOSS, EVEN IF CWW LTD HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                        </p>
                        <p>
                            19.2 YOU AGREE THAT CWW LTD’S TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR ACCESS TO OR USE OF 
                            (OR YOUR INABILITY TO ACCESS OR USE) ANY PORTION OF THE TOOLS, WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED TO THE GREATER OF 
                            (A) THE AMOUNTS ACTUALLY PAID BY YOU TO CWW LTD UNDER THESE TERMS IN THE 12 MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE, OR (B) $100.
                        </p>
                        <p>
                            19.3 YOU ACKNOWLEDGE AND AGREE THAT CWW LTD HAS MADE THE TOOLS AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND LIMITATIONS 
                            OF LIABILITY SET FORTH HEREIN, WHICH REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN THE PARTIES AND FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN US. 
                            THE FOUNDATION WOULD NOT BE ABLE TO PROVIDE THE TOOLS TO YOU WITHOUT THESE LIMITATIONS.
                        </p>
                        <p>
                            19.4 SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, AND SOME JURISDICTIONS ALSO LIMIT DISCLAIMERS OR LIMITATIONS OF
                            LIABILITY FOR PERSONAL INJURY FROM CONSUMER PRODUCTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO PERSONAL INJURY CLAIMS.
                        </p>
                    </div>
                    
                    <div className="section-content1">
                        <h5><strong>20. Proprietary Rights</strong></h5>
                        <p>
                          20.1 All title, ownership and Intellectual Property Rights in and to the Site and the Tools are owned exclusively by CWW LTD or its licensors. CWW LTD holds these Intellectual
                          Property Rights for the benefit of the virtual land community as a whole. You acknowledge and agree that the Site and Tools contains proprietary and confidential information
                          that is protected by applicable intellectual property and other laws. Except as expressly authorized, you agree not to copy, modify, rent, lease, loan, sell, distribute, 
                          perform, display or create Derivative Works based on the Site and the Tools, in whole or in part. The CWW LTD's exclusive ownership shall include all elements of the Site 
                          and Tools, and all Intellectual Property Rights therein. The visual interfaces, graphics (including, without limitation, all art and drawings associated with Tools), design,
                          systems, methods, information, computer code, software, "look and feel", organization, compilation of the content, code, data, and all other elements of the Site and the Tools 
                          (but excluding the Content submitted by Users) (collectively, the " CWW LTD Materials") are owned by the CWW LTD, and are protected by copyright, trade dress, patent, and
                          trademark laws, international conventions, other relevant intellectual property and proprietary rights, and applicable laws. All CWW LTD Materials are the copyrighted property 
                          of CWW LTD or its licensors, and all trademarks, service marks, and trade names contained in the CWW LTD Materials are proprietary to the CWW LTD or its licensors. Except as 
                          expressly set forth herein, your use of the Site and the Tools does not grant you ownership of or any other rights with respect to any content, code, data, or other materials 
                          that you may access on or through the Site and the Tools. The CWW LTD reserves all rights in and to CWW LTD Materials not expressly granted to you in the Terms. For the sake 
                          of clarity, you understand and agree: (i) that any "purchase" “Lease” of LAND, whether via the Tools or otherwise, does not give you any rights or licenses in or to the CWW 
                          LTD Materials (including, without limitation, the CWWLTD’s copyright in and to the art and drawings associated with the Tools and content therein) other than those expressly 
                          contained in these Terms; and (ii) that you do not have the right to reproduce, distribute, or otherwise commercialize any elements of the CWW LTD Materials 
                          (including, without limitation, the CWW LTD’s copyright in and to the art and drawings associated with the Tools and content therein) in any way without the CWW LTD’s prior 
                          written consent in each case, which consent the CWW LTD may withhold in its sole and absolute discretion. 
                        </p>
                        <p>
                            20.2 You acknowledge and agree that you are responsible for your own conduct while accessing or using the Site and the Tools, and for any consequences thereof. 
                            You agree to use the Site and the Tools only for purposes that are legal, proper and in accordance with these Terms and any applicable laws or regulations.
                            By way of example, and not as a limitation, you may not, and may not allow any third party to: (i) send, post, upload, transmit, distribute, disseminate or
                            otherwise make available any Content in violation of the Content Policy approved by the CWW LTD, including without limitation, Content that infringes the
                            Intellectual Property Rights of any party and any Content that contains any hate-related or violent content or contains any other material or products that 
                            violate or encourage conduct that would violate any criminal laws, any other applicable laws, or any third party rights; (ii) distribute viruses, worms, 
                            defects, Trojan horses, spyware, time bombs, cancelbots, corrupted files, hoaxes, or any other items of a destructive or deceptive nature, or that may harvest 
                            or collect any data or information about other users without their consent; (iii) impersonate another person (via the use of an email address or otherwise); 
                            (iv) use the Site or the Tools to violate the legal rights (such as rights of privacy and publicity) of others; (v) engage in, promote, or encourage illegal
                            activity (including, without limitation, money laundering); (vi) interfere with other users' enjoyment of the Site or the Tools; (vii) exploit the Site or the
                            Tools for any unauthorized commercial purpose; (viii) post or transmit unsolicited or unauthorized advertising, or promotional materials, that are in the 
                            nature of "junk mail," "spam," "chain letters," "pyramid schemes," or any other similar form of solicitation; (ix) modify, adapt, translate, or reverse engineer
                            any portion of the Tools; (x) remove any copyright, trademark or other proprietary rights notices contained in or on the Site or the Tools or any part of it; 
                            (xi) reformat or frame any portion of the Site or the Tools; (xii) stalk, harass, or engage in any sexual, suggestive, lewd, lascivious, or otherwise 
                            inappropriate conduct with minors on the Site or the Tools; (xiii) use any robot, spider, site search/retrieval application, or other device to retrieve or
                            index any portion of the Site, the Tools or the Content posted on the Tools, or to collect information about its users for any unauthorized purpose; 
                            (xiv) use any cheats, hacks, or any other unauthorized techniques or unauthorized third-party software to cheat in any competition or game that may be offered
                            on the Tools by other Users, or to otherwise disrupt or modify the Tools or the experience of any users on the Tools; (xv) create user accounts by automated 
                            means or under false or fraudulent pretences; (xvi) attempt to gain unauthorized access to any other user's Account, password or Content; or (xvii) access or
                            use the Tools for the purpose of creating a product or service that is competitive with the Tools.
                        </p>
                        <p>
                            20.3 Ownership and management of LAND, Non-fungible tokens (NTFs) and Content created by users:
                       </p>
                        <p><b>LAND:</b> All title and ownership rights over each piece of LAND lies with its owner. Each LAND owner decides the Content to be included in the LAND 
                        and may impose its own terms and conditions and policies. In the case of Districts, the relationship between the District and District participants - in any
                        capacity - is exclusively governed by the applicable plan approved by each community. You are advised to review any such terms, conditions and policies before
                        entering into transactions in any LAND. The CWW LTD does not control the Content of each LAND parcel and does not assume any liability or obligation in connection
                        thereto. All Content uploaded to LAND must comply with the Content Policy. The CWW LTD holds the Intellectual Property Rights over the LAND smart contract but does
                        not have any Intellectual Property Right over the Content introduced by each user.
                        </p>
                        <p>
                            <b>NFTs:</b> All title, ownership and Intellectual Property Rights over NFTs, including without limitation, Wearables, belong to the creator of the NFT. 
                            Transactions for the sale of NFT through the Marketplace will convey said title, ownership and Intellectual Property Rights to the purchaser. To the fullest
                            extent possible, the creator will waive any moral rights over the NFTs upon transfer to third parties. Neither the CWW LTD nor the any authority have any 
                            Intellectual Property Rights over NFTs created by users. All NFTs must comply with the Content Policy.
                        </p>
                        <p>
                           <b>Content:</b> All title, ownership and Intellectual Property Rights over the Content created by users belongs to the users who created said Content.
                           Neither the CWW LTD nor ay authority have any Intellectual Property Rights over the user’s Content. The creator of the Content may impose its own terms, 
                           conditions and licences for access and use of said Content. You are advised to review any such terms, conditions and policies before accessing any such Content.
                           All Content must comply with the Content Policy.
                        </p>
                        
                    </div>
                    
                    <div className="section-content1">
                        <h5><strong>21. Redistribution.</strong></h5>
                        <p>
                           You may reproduce and distribute copies of the Work or Derivative Works thereof in any medium, with or without modifications, and in Source or Object form,
                           provided that you meet the following conditions: (i) modifications to the Work and the Derivative Works thereof shall not infringe the Privacy and Content
                           Policies, nor allow the infringement of said policies or of Section 19.2 above, and require any further user to abide by these limitations; (ii) any modifications
                           to the Work or the Derivative Works can only take place until six (6) months have elapsed since the release of the relevant Work or Derivative Works by the user
                           to the general public; (iii) you must give any other recipients of the Work or Derivative Works a copy of this License; (iv) you must cause any modified files to
                           carry prominent notices stating that you changed the files; (v) you must retain, in the Source form of any Derivative Works that you distribute, all copyright,
                           patent, trademark, and attribution notices from the Source form of the Work, excluding those notices that do not pertain to any part of the Derivative Works; 
                           (vi) if the Work includes a "NOTICE" text file as part of its distribution, then any Derivative Works that You distribute must include a readable copy of the 
                           attribution notices contained within such NOTICE file, excluding those notices that do not pertain to any part of the Derivative Works, in at least one of the 
                           following places: within a NOTICE text file distributed as part of the Derivative Works; within the Source form or documentation, if provided along with the
                           Derivative Works; or, within a display generated by the Derivative Works, if and wherever such third-party notices normally appear. The contents of the NOTICE 
                           file are for informational purposes only and do not modify the License. You may add your own attribution notices within Derivative Works that you distribute,
                           alongside or as an addendum to the NOTICE text from the Work, provided that such additional attribution notices cannot be construed as modifying the License. 
                        </p>
                        <p>
                            You may add your own copyright statement to your modifications and may provide additional or different license terms and conditions for use, reproduction,
                            or distribution of your modifications, or for any such Derivative Works as a whole, provided your use, reproduction, and distribution of the Work otherwise 
                            complies with the conditions stated in this License.
                        </p>
                    </div>
                    
                    <div className="section-content1">
                        <h5><strong>22. Submission of Contributions.</strong></h5>
                        <p>
                           Unless explicitly stated otherwise, any Contribution intentionally submitted for inclusion in the Work by you to the CWW LTD  and/or its licensors shall be 
                           under the terms and conditions of this License, without any additional terms or conditions. Notwithstanding the above, nothing herein shall supersede or
                           modify the terms of any separate license agreement you may have executed with the Foundation and/or its licensors regarding such Contributions.
                        </p>
                    </div>
                    
                    <div className="section-content1">
                        <h5><strong>23. Trademarks.</strong></h5>
                        <p>
                           This License does not grant permission to use the trade names, trademarks, service marks, or product names of CWW LTD and/or its licensors. 
                        </p>
                    </div>
                    
                    <div className="section-content1">
                        <h5><strong>24.Disclaimer of Warranty.</strong></h5>
                        <p>
                           Unless required by applicable law or agreed to in writing, the CWW LTD and/or its licensors provide the Work (and each Contributor provides 
                           its Contributions) on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied, including, without limitation,
                           any warranties or conditions of TITLE, NON-INFRINGEMENT, MERCHANTABILITY, or FITNESS FOR A PARTICULAR PURPOSE. You are solely responsible for determining
                           the appropriateness of using or redistributing the Work and assume any risks associated with your exercise of permissions under this License. 
                        </p>
                    </div>
                    
                    <div className="section-content1">
                        <h5><strong>25.Limitation of Liability.</strong></h5>
                        <p>
                           In no event and under no legal theory, whether in tort (including negligence), contract, or otherwise, unless required by applicable law
                           (such as deliberate and grossly negligent acts) or agreed to in writing, shall any user be liable to you for damages, including any direct, indirect,
                           special, incidental, or consequential damages of any character arising as a result of this License or out of the use or inability to use the Work
                           (including but not limited to damages for loss of goodwill, work stoppage, computer failure or malfunction, or any and all other commercial damages or losses),
                           even if such user has been advised of the possibility of such damages.
                        </p>
                        <p>
                            25.1 Accepting Warranty or Additional Liability.
                            While redistributing the Work or Derivative Works thereof, you may choose to offer, and charge a fee for, acceptance of support, warranty, 
                            indemnity, or other liability obligations and/or rights consistent with this License. However, in accepting such obligations, you may act only on your own 
                            behalf and on your sole responsibility, not on behalf of any other user, and only if you agree to indemnify, defend, and hold each user harmless for any liability
                            incurred by, or claims asserted against, such user by reason of your accepting any such warranty or additional liability.

                        </p>
                    </div>
                    
                    <div className="section-content1">
                        <h5><strong>26. Links</strong></h5>
                        <p>
                           The Site and the Tools provide, or third parties may provide, links to other World Wide Web or accessible sites, applications or resources. Because CWW LTD has no
                           control over such sites, applications and resources, you acknowledge and agree that the CWW LTD, its officers, employees and any other authority are not responsible 
                           for the availability of such external sites, applications or resources, and do not endorse and are not responsible or liable for any content, advertising, products
                           or other materials on or available from such sites or resources. You further acknowledge and agree that the Foundation, its officers, employees and CWW LTD, shall 
                           not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such 
                           content, goods or services available on or through any such site or resource. 
                        </p>
                    </div>
                    
                    <div className="section-content1">
                        <h5><strong>27. Termination and Suspension</strong></h5>
                        <p>
                           You shall have a right to terminate your Account at any time by cancelling and discontinuing your access to and use of the Tools. The CWW LTD may decide to terminate 
                           or suspend all or part of the Site and the Tools and your access to the Site and the Tools immediately, without prior notice or liability. You will not receive any 
                           refunds if you cancel your Account, or if these Terms are otherwise terminated. You agree that the CWW LTD, in its sole discretion and for any or no reason, may 
                           terminate these Terms and suspend your Account(s) for the Tools. You agree that any suspension of your access to the Site or the Tools may be without prior notice, 
                           and that CWW LTD (and its officers and employees) will not be liable to you or to any third party for any such suspension.
                        </p>
                        <p>
                            In the event of your breach of these Terms or any suspected fraudulent, abusive, or illegal activity, CWW LTD may, without limitation, suspend your Account, block 
                            any infringing Content and adopt any other action deemed necessary to prevent future breaches, in addition to any other remedies the CWW LTD and/or any User may
                            have at law or in equity.
                        </p>
                        <p>
                            Upon any termination or suspension of your Account, you may no longer have access to information that you have posted on the Tools or that is related to your Account,
                            and you acknowledge that CWW LTD will have no obligation to maintain any such information in the relevant databases or to forward any such information to you or 
                            to any third party. Upon termination of your Account, your right to use the Tools will immediately cease. The following provisions of these Terms survive any 
                            termination of these Terms: REPRESENTATIONS AND RISKS; TRANSACTION AND FEES; INDEMNITY; DISCLAIMERS; LIMITATION ON LIABILITY; PROPRIETARY RIGHTS; LINKS; TERMINATION 
                            AND SUSPENSION; NO THIRD-PARTY BENEFICIARIES; BINDING ARBITRATION AND CLASS ACTION WAIVER; GENERAL INFORMATION.
                        </p>
                    </div>
                    
                    <div className="section-content1">
                        <h5><strong>28. No Third-Party Beneficiaries</strong></h5>
                        <p>
                           You agree that, except as otherwise expressly provided in these Terms, there shall be no third-party beneficiaries to the Terms. 
                        </p>
                    </div>
                    
                    <div className="section-content1">
                        <h5><strong>29. Notice and Procedure for Making Claims of Copyright Infringement </strong></h5>
                        <div>
                           If you believe that your Intellectual Property Rights (as this term is defined in the Content Policy) or the Intellectual Property Rights of a person on whose behalf 
                           you are authorized to act has been infringed, you are encouraged to contact the infringing party directly. Additionally, you may also contact CWW LTD providing the 
                           following information:
                           <ul className="ml-3">
                               <li>
                                   an electronic or physical signature of the person authorized to act on behalf of the owner of the Intellectual Property Right;
                               </li>
                               <li>
                                   a description of the Intellectual Property Right that you claim has been infringed;
                               </li>
                               <li>
                                   description of where the material that you claim is infringing is located on the Tools;
                               </li>
                               <li>
                                   your address, telephone number, and email address;
                               </li>
                               <li>
                                   a statement by you that you have a good faith belief that the disputed use is not authorized by the owner of the Intellectual Property Right, its agent,
                                   or the law;
                               </li>
                               <li>
                                   a statement by you, made under penalty of perjury, that the above information in your Notice is accurate and that you are Intellectual Property owner or 
                                   authorized to act on the owner's behalf.
                               </li>
                           </ul>
                        </div>
                        <p>
                            The CWW LTD can be reached at email: <a href="mailto:hello@Worldbeyond.live">
                                 hello@Worldbeyond.live</a>
                        </p>
                        <p>
                            To the extent possible, the CWW LTD may try to reach the would-be infringing party to forward your concerns. The CWW LTD is not in a position to assess the legal
                            merits of the claims.
                        </p>
                        <p>
                            As the CWW LTD does not control the Content being uploaded by the users, if the user does not agree with your claim and/or does not accept to withdraw the infringing 
                            content, CWW LTD, as per the votes of the community, may take any of the following measures, (i) block the infringing Content so as to render it inaccessible through 
                            the Tools (although it could be still accessible through other clients); (ii) block the infringing user's Account. Any further action, claim or remedy against the 
                            infringing user must be undertaken by the aggrieved user.
                        </p>
                        <p>
                            Although not at the core of its role or responsibilities, to the extent technically possible, the CWW LTD may also, at its sole discretion, (i) block any kind of
                            Content uploaded by users; (ii) suspend user Accounts; and/or (iii) request documents evidencing the right of the contributor to use Intellectual Property Rights 
                            embedded in the Content.
                        </p>
                    </div>
                    
                    <div className="section-content1">
                        <h5><strong>30. Binding Arbitration and Class Action Waiver </strong></h5>
                        <p>
                           PLEASE READ THIS SECTION CAREFULLY - IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT 
                        </p>
                        <p><strong>30.1 Initial Dispute Resolution</strong></p>
                        <p>
                            The parties shall use their best efforts to engage directly to settle any dispute, claim, question, or disagreement and engage in good faith negotiations which
                            shall be a condition to either party initiating a lawsuit or arbitration.
                        </p>
                        <p><strong>30.2 Binding Arbitration</strong> </p>
                        <p>If the parties do not reach an agreed upon solution within a period of 30 days from the time informal dispute resolution under the Initial Dispute Resolution provision
                        begins, then either party may initiate binding arbitration as the sole means to resolve claims, subject to the terms set forth below. Specifically, any dispute that is
                        not resolved under the Initial Dispute Resolution provision shall be finally settled under the Rules of Arbitration of the International Chamber of Commerce. The 
                        following shall apply in respect of such arbitration: (i) the number of arbitrators shall be ONE arbitrator; (ii) the decision of the arbitrators shall be binding and
                        enforceable against the parties and a judgment upon any award rendered by the arbitrators may be entered in any court having jurisdiction thereto (provided that in no 
                        event will the arbitrator have the authority to make any award that provides for punitive or exemplary damages or to award damages excluded by these Terms or in excess
                        of the limitations contained in these Terms); (iii) the seat, or legal place, of arbitration shall be the City of Pune, Maharashtra, India ; and (iv) the language to be
                        used in the arbitral proceedings shall be English, any documents submitted as evidence that are in another language must be accompanied by an English translation and the
                        award shall be in the English language. Claimants and respondents shall bear its or their own costs of the arbitration, including attorney's fees, and share equally the 
                        arbitrators' fees and ICC's administrative costs. For purposes of cost sharing, all claimants shall be considered one party and all respondents shall be considered one party.
                        </p>
                        <p>
                            The parties shall maintain strict confidentiality with respect to all aspects of any arbitration commenced pursuant to these Terms and shall not disclose the fact, 
                            conduct or outcome of the arbitration to any non-parties or non-participants, except to the extent required by applicable Law or to the extent necessary to recognize,
                            confirm or enforce the final award or decision in the arbitration, without the prior written consent of all parties to the arbitration.
                        </p>
                        <p><strong>30.3 Notice period</strong></p>
                        <p>
                            You have the right to opt-out and not be bound by the arbitration provisions set forth above by sending written notice of your decision to opt-out to the following email
                            address: <a href="mailto:hello@Worldbeyond.live">
                                 hello@Worldbeyond.live</a> Your notice must be sent within 30 days of your first use of the Tools, otherwise you shall be bound to arbitrate disputes in accordance with the terms
                            of those paragraphs. 
                        </p>
                        <p><strong>30.4 Changes to this Section</strong></p>
                        <p>
                            The CWW LTD will provide 60-days' notice of any changes to this section. Changes will become effective on the 60th day and will apply prospectively only to any claims 
                            arising after the 60th day. These Terms and the relationship between you and the CWW LTD shall be governed by the laws of India, without regard to conflict of law provisions. 
                        </p>
                        <p>
                            For any dispute not subject to arbitration you and The CWW LTD agree to submit to the exclusive jurisdiction of the courts with seat in the city of Pune, Maharashtra, India. 
                            You further agree to accept service of process by mail, and hereby waive any and all jurisdictional and venue defenses otherwise available.
                        </p>
                    </div>
                    <div className="section-content1">
                        <h5><strong>31. General Information </strong></h5>
                        <p>
                           <strong>31.1 Entire Agreement</strong> 
                        </p>
                        <p>
                            These Terms (and any additional terms, rules and conditions of participation that the CWW LTD may post on the Site or on the Tools) constitute the entire agreement between
                            you and the CWW LTD with respect to the Tools and supersedes any prior agreements, oral or written, between you and the CWW LTD. In the event of a conflict between these 
                            Terms and the additional terms, rules and conditions of participation, the latter will prevail over the Terms to the extent of the conflict.
                        </p>
                        <p><strong>31.2 Waiver and Severability of Terms</strong></p>
                        <p>
                            The failure of the CWW LTD to exercise or enforce any right or provision of the Terms shall not constitute a waiver of such right or provision. If any provision of the 
                            Terms is found by an arbitrator or court of competent jurisdiction to be invalid, the parties nevertheless agree that the arbitrator or court should endeavour to give 
                            effect to the parties' intentions as reflected in the provision, and the other provisions of the Terms remain in full force and effect.
                        </p>
                        <p><strong>31.3 Statute of Limitations</strong></p>
                        <p>You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to the use of the Site, the Tools or the Terms 
                           must be filed within one (1) year after such claim or cause of action arose or be forever barred.
                        </p>
                        <p><strong>31.4 Section Titles</strong></p>
                        <p>
                            The section titles in the Terms are for convenience only and have no legal or contractual effect.
                        </p>
                        <p>
                            <strong>31.5 Communications</strong>
                        </p>
                        <p>
                            Users with questions, complaints or claims with respect to the Site or the Tools may contact us using the relevant contact information set forth above and at E-mail id - <a href="mailto:hello@Worldbeyond.live">
                                 hello@Worldbeyond.live</a>
                        </p>
                    </div>
                    
                
                    
                </div>
            </div>
</section>
<Footer />
    </>
  )
}

export default TermsOfUse
