import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  callCreateRoom,
  callVisitRoom,
} from "../../../../../common/axiosCalls";
import { updateMyRooms } from "../../../../../features/Authentication/AuthenticationSlice";
import { setToast } from "../../../../../features/ErrorHandling/ErrorHandlingSlice";
import { setRoomInfo } from "../../../../../features/RoomInfo/RoomInfoDataSlice";
import styles from "./MetaverseNamePopup.module.css";

const MetaverseNamePopup = ({ room_type }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [roomName, setRoomName] = useState("");
  const authentication = useSelector((store) => store.authentication);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changeHandler = (e) => {
    if (e.target.value[e.target.value.length - 1] === " ") return;

    setRoomName(e.target.value);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const responce = await callCreateRoom({
        room_name: roomName,
        room_type: room_type,
      });

      const roomId = responce.data.room_id;
      dispatch(
        setRoomInfo({
          avtar_id: authentication.userDetails.avtar_id,
          avtar_name: authentication.userDetails.avtar_name,
          room_type: room_type,
          room_id: roomId,
          room_name: roomName.replaceAll("%20", " "),
          is_admin: true,
        })
      );

      await callVisitRoom({
        room_name: roomName.replaceAll("%20", " "),
      });

      dispatch(
        updateMyRooms({
          room_id: roomId.toString(),
          room_name: roomName.replaceAll("%20", " "),
        })
      );

      dispatch(
        setToast({
          type: "success",
          message: `${roomName} has been created successfully`,
        })
      );

      navigate(`/room/${room_type}/${roomName}/${roomId}`);
    } catch (error) {
      dispatch(
        setToast({
          type: "error",
          message: error.message,
        })
      );
    }
  };

  // if(isLoading) {
  //   return (
  //     <>
  //      <div className={styles['loading_wrapper']} >
  //         <div className={styles["loading-div"]} />;
  //         <p style={{
  //           color: 'white'
  //         }}>Loading...</p>
  //      </div>
  //     </>
  //   )
  // }

  return (
    <main className={styles["wrapper"]}>
      <form onSubmit={submitHandler}>
        <div className={styles["meta_button"]}>
          <div>
            <input
              onChange={changeHandler}
              type="text"
              placeholder="Enter Metaverse Name"
              className={styles["place_meta"]}
              value={roomName}
              name="roomName"
            />
          </div>
          <div>
            <button className={styles["submit_btn"]} type="submit">
              Create World
            </button>
          </div>
        </div>
      </form>
    </main>
  );
};

export default MetaverseNamePopup;
