import React, { useEffect, useState } from "react";
import "./ImgageVideoFile.css";
import {
  CloseBars,
  SeetingIconSvg,
  UploadBarSvgIcon,
} from "../../common/svgIcon";
import { callGetRoomMedia, callRoomMedia } from "../../common/axiosCalls";

const UploadVideo = ({ roomInfo, screen, dispatch, setToast }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serverVideo, setServerVideo] = useState(false);
  const [isVideoToUpload, setIsVideoToUpload] = useState(null);
  const [callServer, setCallServer] = useState(true);

  useEffect(() => {
    const getMedia = async () => {
      try {
        const media = await callGetRoomMedia({
          room_name: roomInfo.room_name,
        });

        if (!media.data.status) {
          return;
        }

        setServerVideo(media.data.data[roomInfo.room_name].Video_links[screen]);
      } catch (error) {
        console.log(error);
      }
    };
    getMedia();
  }, [callServer]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const VideoInputChangeHandler = (e) => {
    setIsVideoToUpload(e.target.value);
  };

  const HandleUploadVideo = async (e) => {
    e.preventDefault();
    if (!isVideoToUpload) {
      dispatch(
        setToast({
          type: "error",
          message: "Please insert url",
        })
      );
      return;
    }
    const result = await callRoomMedia({
      room_name: roomInfo.room_name,
      screen,
      screen_img: "null",
      video_link: isVideoToUpload,
    });

    setIsVideoToUpload(null);
    setIsModalOpen(false);
    setCallServer(!callServer);
  };

  if (!serverVideo && !roomInfo.is_admin) {
    return (
      <>
        <div className="main-file">
          <h1 className="no-exist">No Video Exist</h1>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="main-file">
        {serverVideo && roomInfo.is_admin && (
          <div title="Change Video" className="svg-wrap" onClick={openModal}>
            <SeetingIconSvg />
          </div>
        )}
        {serverVideo && (
          <iframe
            className="videoFile"
            src={serverVideo}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        )}

        {roomInfo.is_admin && !serverVideo && (
          <div className="container-upload">
            <div className="header-upload" onClick={openModal}>
              <UploadBarSvgIcon />
              <p>Enter Web Url</p>
            </div>
          </div>
        )}

        {isModalOpen && (
          <div className="main-overlay">
            <div className="utMain">
              <form onSubmit={HandleUploadVideo} className="UTform">
                <input
                  className="UTinput"
                  type="text"
                  accept="image/*"
                  name=""
                  id=""
                  placeholder="Paste your video link here"
                  onChange={VideoInputChangeHandler}
                ></input>
                <button type="submit" className="UTbtn">
                  Upload
                </button>
              </form>
              <div className="Close-btn-img" onClick={closeModal}>
                <CloseBars ImgUpload={true} />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UploadVideo;
