import * as THREE from "three";
const UpdateMesh = (threejsScene, allMeshArray, sceneName) => {
  if (sceneName === "r3f_showroom") {
    let outerWallAlpha = new THREE.TextureLoader().load(
      "./public/R3F/texture/r3f_hr/outerWallAlpha.png"
    );
    outerWallAlpha.flipY = false;
    
    const outerWall = threejsScene.getObjectByName("environment");
    outerWall.material.alphaMap = outerWallAlpha;
    outerWall.material.transparent = true;
    outerWall.material.opacity = 0;
    /*------------------envmap------------------*/
    let carBodyMeshNameArray = [
      "Mesh261",
      "Mesh172_3",
      "Mesh171",
      "Mesh003_2",
      "Mesh263",
      "Body004",
      "Mesh287",
      "Mesh272",
    ];
    carBodyMeshNameArray.map((item) => {
      const carBody = threejsScene.getObjectByName(item);
      carBody.material.metalness = 0.7
      carBody.material.roughness = 0.2
      carBody.material.envMapIntensity = 2.0
    })
    let glassMeshNameArray = [
      //car glass
      "Mesh261_1",
      "Mesh172_2",
      "Mesh171_2",
      "Mesh003_3",
      "Glass002",
      "Windshield001",
      "Mesh287_1",
      "Mesh272_4",
    ];
    glassMeshNameArray.map((item) => {
      const glass = threejsScene.getObjectByName(item);
      glass.material.metalness = 1
      glass.material.roughness = 0
    })
  }
  if (sceneName === "r3f_turkey") {
   
    /*------------------envmap------------------*/
    let metalBodyMeshNameArray = [
      "Spear_Sculper_Rings002",
      "Metal_Rod004",
      "Flag_Pole011",
      "pole_Circle_Sculpture_019",
      "Mesh391_1",
      "Pipe_Circle_Sculptre026",
      "Cylinder538",
      "Cylinder540",
      "Mesh140_1",
      "Sculpter_023",
      "Hellogen_Polle014",
      "cage",
      "Mesh139_1",
      "Mesh391",
      "FanPole006",
    ]
    metalBodyMeshNameArray.map(item => {
      let metalBody = threejsScene.getObjectByName(item);
      metalBody.material.metalness = 0.9
      metalBody.material.roughness = 0.1
      metalBody.material.envMapIntensity = 1.0
    })

    //2
    let metalBodyMeshNameArray2 = [
      "Light_Pole019",
      "streetlight055",
      "Mesh332",
      "Mesh328",
      "Cylinder",
      "Mesh330",
      "Sculpture_03_Red003",
      "Sculpture_03_Yellow002",
      "Sculpture_03_Orange003",
      "Circle055",
      "Big_Circle_Sculptre026",
      "Angle_Circle_Sculpture_019",
      "Fan020",
    ]
    metalBodyMeshNameArray2.map(item => {
      let metalBody = threejsScene.getObjectByName(item);
      metalBody.material.metalness = 0.7
      metalBody.material.roughness = 0.1
      metalBody.material.envMapIntensity = 1.0
    })
  //3
  let metalBodyMeshNameArray3 = [
    "Center_Roof_Base001",
    "Center_Roof",
    "Center_Paving_01",
    "Center_Paving_03",
    "Center_Paving_04",
    "Center_Paving_02",
    "Mesh028",
    "Paving_Border",
    "screen_wall",
    "Cube236",
    "Booth_024",
    "booth_007",
    "Booth_019",
    "Booth_029",
    "Booth_030",
    "Object224",
    "booth033",
  ]
  metalBodyMeshNameArray3.map(item => {
    let metalBody = threejsScene.getObjectByName(item);
    metalBody.material.metalness = 0.3
    metalBody.material.roughness = 0.15
    metalBody.material.envMapIntensity = 1.0
  })
  //4
  let metalBodyMeshNameArray4 = [
    "Seat_Sofa002",
    "Seat_Sofa003",
  ]
  metalBodyMeshNameArray4.map(item => {
    let metalBody = threejsScene.getObjectByName(item);
    metalBody.material.metalness = 0.1
    metalBody.material.roughness = 0.5
    metalBody.material.envMapIntensity = 1.0
  })
  //5
  let metalBodyMeshNameArray5 = [
    "Cylinder002",
  ]
  metalBodyMeshNameArray5.map(item => {
    let metalBody = threejsScene.getObjectByName(item);
    metalBody.material.metalness = 0.1
    metalBody.material.roughness = 0.1
    metalBody.material.envMapIntensity = 1.0
  })
  //6

  let emissiveMeshNameArray = [
    "Cylinder539",
  ]
  emissiveMeshNameArray.map(item => {
    let emissiveBody = threejsScene.getObjectByName(item);
    emissiveBody.material.emissiveIntensity =  10.0 
    emissiveBody.material.transparent = true 
    emissiveBody.material.opacity = 0.5;
    emissiveBody.material.roughness = 0;
    emissiveBody.material.metalness = 1;  
  })
  }
  
};

export default UpdateMesh;
