//let
let maleIdArray = [
  "1",
  "4",
  "5",
  "8",
  "10",
  "11",
  "12",
  "13",
  "16",
  "20",
];

let dotPath = "/..";


const sceneObject = {
  mr: "r3fmr",
  mr2: "r3f_mr2",
  mr3: "r3f_mr3",
  dr: "r3f_dr",
  school: "r3f_school",
  office1: "r3f_office1",
  showroom: "r3f_showroom",
  showroom2: "r3f_showroom2",
  hr: "r3f_hr",
  office2: "r3f_office2",
  turkey: "r3f_turkey",
  hall: "r3f_hall",
  hall2: "r3f_hall2",
  tourism: "r3f_tourism",
};

const fontPath = "/../fonts/helvetiker_regular.typeface.json";
 
export {
  maleIdArray, dotPath, sceneObject, fontPath 
};

// F:\saas\r3f\websaas\public\R3F\asset
// F:\saas\r3f\websaas\public\R3F\environmentMaps
// F:\saas\r3f\websaas\public\R3F\image\map
// F:\saas\r3f\websaas\public\R3F\image\Wallpaper
// F:\saas\r3f\frontend\WebSAAS\src\R3F\Component\experience\ExperiencePosition.js 

// F:\saas\New-R3F-Nodejs\src\R3F\Component\different\PlayerPositions.js
// F:\saas\New-R3F-Nodejs\src\R3F\Component\different\htmlScreen\ScreenPositions.js
// F:\saas\New-R3F-Nodejs\src\R3F\Component\different\updateMaterial\UpdateMaterialArray.js

// F:\saas\New-R3F-Nodejs\src\Web\components\videoSlider\VideoSlider.js
// F:\saas\New-R3F-Nodejs\src\Web\components\postCard\EnterWorldPopup\EnterWorldPopup.js 
// F:\saas\r3f\frontend\WebSAAS\src\Web\components\metaverseRoom\MetaverseRoom.js
