import elogo1 from "../../assets/images/e-logo/1.png";
import elogo2 from "../../assets/images/e-logo/2.png";
import elogo3 from "../../assets/images/e-logo/3.png";
import elogo4 from "../../assets/images/e-logo/4.png";
import elogo5 from "../../assets/images/e-logo/5.png";
import elogo6 from "../../assets/images/e-logo/6.png";
import elogo7 from "../../assets/images/e-logo/7.png";
import elogo8 from "../../assets/images/e-logo/8.png";
import elogo9 from "../../assets/images/e-logo/9.png";
import elogo10 from "../../assets/images/e-logo/10.png";
import elogo11 from "../../assets/images/e-logo/11.png";
import elogo12 from "../../assets/images/e-logo/12.png";
import elogo13 from "../../assets/images/e-logo/13.png";
import elogo14 from "../../assets/images/e-logo/14.png";
import elogo15 from "../../assets/images/e-logo/15.png";
import elogo16 from "../../assets/images/e-logo/16.png";
import elogo17 from "../../assets/images/e-logo/17.png";
import elogo18 from "../../assets/images/e-logo/18.png";

import NRW from "../../assets/images/e-logo/NRW.png";
import DITP from "../../assets/images/e-logo/DITP.png";
import Sao_paulo from "../../assets/images/e-logo/Sao_paulo.png";
import Karsan from "../../assets/images/e-logo/Karsan.png";
import MIDA from "../../assets/images/e-logo/MIDA.png";
import TATAADS from "../../assets/images/e-logo/TATA-ADS.png";
import Motovario from "../../assets/images/e-logo/Motovario.png";

import elogo19 from "../../assets/images/e-logo/19.png";
import elogo20 from "../../assets/images/e-logo/20.png";
import elogo21 from "../../assets/images/e-logo/21.png";
import elogo22 from "../../assets/images/e-logo/22.png";
import elogo23 from "../../assets/images/e-logo/23.png";

import sec1 from "../../assets/images/Sector/Event.jpg";
import sec2 from "../../assets/images/Sector/Education.jpg";
import sec3 from "../../assets/images/Sector/Tourism.jpg";
import sec4 from "../../assets/images/Sector/Manufacturing.jpg";
import sec5 from "../../assets/images/Sector/Bank.jpg";
import sec6 from "../../assets/images/Sector/Ecommerce.jpg";
import sec7 from "../../assets/images/Sector/Real_estate.jpg";
import sec8 from "../../assets/images/Sector/Office.jpg";

export const companyLogos = [
  elogo1,
  elogo2,
  elogo3,
  elogo4,
  elogo5,
  elogo6,
  elogo7,
  elogo8,
  elogo9,
  elogo10,
  elogo11,
  elogo12,
  elogo13,
  elogo14,
  elogo15,
  elogo16,
  elogo17,
  elogo18,
  NRW,
  DITP,
  Sao_paulo,
  Karsan,
  MIDA,
  TATAADS,
  Motovario,
  elogo19,
  elogo20,
  elogo21,
  elogo22,
  elogo23,
];

export const sectorImages = [
  { name: "Events", img: sec1 },
  { name: "Education", img: sec2 },
  { name: "Tourism", img: sec3 },
  { name: "Manufacturing", img: sec4 },
  { name: "Bank", img: sec5 },
  { name: "Ecommerce", img: sec6 },
  { name: "Real Estate", img: sec7 },
  { name: "Office", img: sec8 },
];
