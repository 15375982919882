import React from 'react';
import Classes from "./Common.module.css";
import {useDispatch, useSelector} from 'react-redux';
import { closeModal, setModalFor } from '../features/EnterWorldPopUp/EnterWorldSlice';

const ModalBackDrop = (props) => {
  const dispatch = useDispatch();
  const enterWorld = useSelector(store => store.enterWorld);
  
  const backdropHandler = () => {
    if (enterWorld.modalFor.purpose === 'edit_info') {
      dispatch(setModalFor(null));
    }
    dispatch(closeModal());
  }

  
  return (
    <div onClick={backdropHandler} className={Classes["backdrop"]}>
    </div>
  )
}

export default ModalBackDrop;