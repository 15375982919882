import { useState } from "react";
import styles from "./InfoPopup.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setToast } from "../../features/ErrorHandling/ErrorHandlingSlice";
import { setRoomInfo } from "../../features/RoomInfo/RoomInfoDataSlice";
import { Navigate, useNavigate } from "react-router";
import { generateRandomUserInfo } from "../../common/commonFunctions";
import {
  callClickRegData,
  callVisitorRegistration,
} from "../../common/axiosCalls";

const InfoPopup = ({ setShowInfoPopup, showInfoPopup }) => {
  const [formData, setFormData] = useState({
    email_address: "",
    phone_number: "",
    name: "",
  });

  const [validations, setValidations] = useState({
    name: false,
    email_address: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const diveInHandler = async () => {
    dispatch(
      setToast({
        type: "success",
        message: `${formData.name}, Welcome to WB Metaverse !`,
      })
    );

    try {
      callClickRegData({
        name: formData.name,
        email: formData.email_address,
        phone: formData.phone_number,
        utm_url: window.location.href,
      });
    } catch (error) {
      console.log(error);
    }

    const userInfo = generateRandomUserInfo();
    document.cookie = `avtar_name=${formData.name}; expires=${new Date(
      Date.now() + 1 * 1 * 60 * 60 * 1000
    ).toUTCString()}`;
    document.cookie = `avtar_id=${userInfo.avtar_id}; expires=${new Date(
      Date.now() + 1 * 1 * 60 * 60 * 1000
    ).toUTCString()}`;
    document.cookie = `interest_intent_email_id=${
      formData.email_address
    }; expires=${new Date(Date.now() + 1 * 1 * 60 * 60 * 1000).toUTCString()}`;

    dispatch(
      setRoomInfo({
        avtar_id: userInfo.avtar_id,
        avtar_name: formData.name,
        room_type: "r3fmr",
        room_name: "defaultmr",
        room_id: 0,
        is_admin: false,
      })
    );
    if (showInfoPopup === "home-page") {
      navigate("/room/default/wbm/0");
    }

    if (showInfoPopup === "turkey") {
      navigate("/welcome-to-turkey");
    }

    if (showInfoPopup === "hr-metaverse") {
      navigate("/welcome-to-hr-metaverse");
    }

    if (showInfoPopup === "MeetingRoom") {
      navigate("/welcome-to-meetingroom");
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!validations.name || !validations.email_address) {
      if (!validations.name) {
        dispatch(
          setToast({
            type: "error",
            message: "Name cannot be empty !",
          })
        );
      } else if (!validations.email_address) {
        dispatch(
          setToast({
            type: "error",
            message: "Enter valid email address",
          })
        );
      }
      return;
    }

    diveInHandler();
  };

  return (
    <>
      <form onSubmit={submitHandler}>
        <div className={styles["wrapper"]}>
          <div className={styles["back-icon"]}>
            <svg
              className={styles["back-icon_svg"]}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              onClick={() => setShowInfoPopup(false)}
            >
              <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
            </svg>
            <b className={styles["look_heading"]}>Enter Details</b>
          </div>

          <input
            type="text"
            onChange={(e) => {
              setFormData({ ...formData, name: e.target.value });
              setValidations({
                ...validations,
                name: e.target.value ? true : false,
              });
            }}
            value={formData.name}
            placeholder="Name *"
          />

          <input
            onChange={(e) => {
              setFormData({ ...formData, email_address: e.target.value });
              const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
              setValidations({
                ...validations,
                email_address: emailRegex.test(e.target.value),
              });
            }}
            value={formData.email_address}
            placeholder="Email *"
          />

          <input
            type="number"
            onChange={(e) => {
              setFormData({ ...formData, phone_number: e.target.value });
              setValidations({
                ...validations,
                phone_number: e.target.value ? true : false,
              });
            }}
            value={formData.phone_number}
            placeholder="Contact Number"
          />
          <label htmlFor="checkbox" className="cBox">
            <input id="checkbox" type="checkbox" required /> &nbsp; I accept the
            &nbsp;
            <a href="https://www.worldbeyond.live/terms-of-use" target="blank">
              Terms of Use.
            </a>
          </label>
          <button className={styles["step_btn"]}>Submit</button>
        </div>
      </form>
    </>
  );
};

export default InfoPopup;
