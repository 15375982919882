import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { callLikeDislikeWorld } from "../../common/axiosCalls";
import { dislikeRoom, likeRoom } from "../../features/UserLikes/UserLikesSlice";
import "./LikeButton.css";
import LoginPopup from "../room/loginpopup/LoginPopup";

const LikeButton = ({
  room_id,
  room_name,
  like_count,
  RoomLikeButton,
  isExplore, 
}) => {
  const likedRoomIDs = useSelector((store) => store.userLikes.roomIDs);
  const navigate = useNavigate();
  const authentication = useSelector((store) => store.authentication);
  const pagination = useSelector((store) => store.pagination.postCards);
  const [likeCount, setLikeCount] = useState(+like_count);
  const [isOpen, setIsOpen] = useState(null);
  const dispatch = useDispatch();

  const [icon, setIcon] = useState(
    likedRoomIDs?.includes(+room_id) ? "favorite" : "favorite_border"
  );
  const [liked, setLiked] = useState(
    likedRoomIDs?.includes(+room_id) ? true : false
  );

  const toggleHover = (e) => {
    if (liked) {
      e.type === "mouseenter"
        ? setIcon("favorite_border")
        : setIcon("favorite");
    } else {
      e.type === "mouseenter"
        ? setIcon("favorite")
        : setIcon("favorite_border");
    }
  };

  useEffect(() => {
    setLiked(likedRoomIDs?.includes(+room_id) ? true : false);
    setIcon(likedRoomIDs?.includes(+room_id) ? "favorite" : "favorite_border");
  }, [likedRoomIDs, pagination, room_id]);

  useEffect(() => {
    setLikeCount(+like_count);
  }, [like_count]);

  const postLikeDislikeHandler = (data) => {
    if (!authentication.isAuthenticated) {
      setIsOpen('login-popup')
      return;
    }

    if (data.like_status === true) {
      setLikeCount((prevState) => {
        return +prevState + 1;
      });

      dispatch(likeRoom(room_id));
      setLiked(true);
      callLikeDislikeWorld({
        room_name,
        room_id,
        like_status: likedRoomIDs?.includes(+room_id) ? false : true,
      });
    } else if (data.like_status === false) {
      setLikeCount((prevState) => {
        return +prevState - 1;
      });
      dispatch(dislikeRoom(room_id));
      setLiked(false);
      callLikeDislikeWorld({
        room_name,
        room_id,
        like_status: likedRoomIDs?.includes(+room_id) ? false : true,
      });
    }
  };

  return (
    <>
      <span
        className={
          RoomLikeButton ? "material-icons like_button" : "material-icons"
        }
        onClick={() => {
          postLikeDislikeHandler({
            room_name: room_name,
            room_id: room_id,
            like_status: likedRoomIDs?.includes(+room_id) ? false : true,
          });
        }}
        style={{ cursor: "pointer" }}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
      >
        {icon}
      </span>
      {RoomLikeButton ? null : likeCount}
      {isOpen === "login-popup" && <LoginPopup setIsOpen={setIsOpen} isOpen={isOpen}  isExplore={isExplore}/>}
    </>
  );
};

export default LikeButton;
