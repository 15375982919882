import { socket } from "../Component/socketManager/SocketManager";
let agoraEngine;
let options = {
  // Pass your App ID here.
  appId: "aa6bd939aae94c179e8fb5f7ab619222",
  // Set the channel name.
  channel: "",
  // Pass your temp token here.
  token: "",
  // Set the user ID.
  uid: 0,
};

export let channelParameters = {
  localAudioTrack: null,
  remoteAudioTrack: null,
  remoteUid: null,
};

var remoteUser = {};
async function startBasicCall(roomName, socketName) {
  agoraEngine = await AgoraRTC.createClient({
    mode: "rtc",
    codec: "vp8",
  });

  agoraEngine.on("user-published", async (user, mediaType) => {
    await agoraEngine.subscribe(user, mediaType);
    if (mediaType == "audio") {
      const remoteUid = user.uid;
      // Get the RemoteAudioTrack object from the AgoraRTCRemoteUser object.
      const remoteAudioTrack = user.audioTrack;
      remoteUser[remoteUid] = remoteAudioTrack;
      remoteAudioTrack.play();
    }
    // Listen for the "user-unpublished" event.
    agoraEngine.on("user-unpublished", (user) => { });
  });

  //socket
  options.channel = roomName;
  socket.emit("checkAgoraToken", roomName);
  socket.on("getTokenFromDB", async (token) => {
    options.token = token;
    await agoraEngine.join(
      options.appId,
      options.channel,
      options.token,
      options.uid
    );
    /*------------------------------------*/
    socket.emit("addVoiceId", {
      voiceId: agoraEngine.uid,
      socketName: socketName,
      roomName: roomName,
      isMuted: false,
    });
    try {
      channelParameters.localAudioTrack =
        await AgoraRTC?.createMicrophoneAudioTrack();
      await agoraEngine?.publish(channelParameters.localAudioTrack);
    } catch (e) {}

    let muteButton = document.querySelector(".selfmute");
    muteButton.addEventListener("click", async function () {
      if (!channelParameters.localAudioTrack.enabled) {
        await channelParameters.localAudioTrack.setEnabled(true);
        muteButton.innerHTML = '<i class="fas fa-microphone"></i>';
        muteButton.style.backgroundColor = "green";
        muteButton.dataset.tooltip = "Clik to Mute";
      } else {
        await channelParameters.localAudioTrack.setEnabled(false);
        muteButton.innerHTML = '<i class="fas fa-microphone-slash"></i>';
        muteButton.style.backgroundColor = "red";
        muteButton.dataset.tooltip = "Click to Unmute";
      }
    });
    // document.addEventListener('keyup', async function (e) {
    //   if (e.code === 'KeyM') {
    //     if (!channelParameters.localAudioTrack.enabled) {
    //       await channelParameters.localAudioTrack.setEnabled(true)
    //       muteButton.innerHTML = '<i class="fas fa-microphone"></i>'
    //     } else {
    //       await channelParameters.localAudioTrack.setEnabled(false)
    //       muteButton.innerHTML = '<i class="fas fa-microphone-slash"></i>'
    //     }
    //   }
    // })
  });
  agoraEngine.enableAudioVolumeIndicator();
}

// agoraEngine.on("volume-indicator", (volumes) => {
//   volumes.forEach((volume) => {
//     if (volume.level > 0) {
//     }
//   });
// });

export { startBasicCall, remoteUser, agoraEngine };
