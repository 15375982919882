import { sceneObject } from "../../Files/CommonVariables";

const myPlayerLandPosition = {
  [sceneObject["mr"]]: {
    x: Math.random() * 8 + 2,
    y: 51.05,
    z: Math.random() * 2 + 2.5,
  },
  [sceneObject["mr2"]]: {
    x: -15.78 + Math.random() * 1,
    y: -0.04,
    z: 31.95 + Math.random() * 1,
  },
  [sceneObject["mr3"]]: {
    x: 0 + Math.random() * 1,
    y: 1,
    z: 0 + Math.random() * 1,
  },
  [sceneObject["dr"]]: {
    x: Math.random() * 10,
    y: 1.0,
    z: Math.random() * 10,
  },
  [sceneObject["school"]]: {
    x: Math.random() * 5 + 19.22,
    y: 1.15,
    z: Math.random() * 10 + 37.24,
  },
  [sceneObject["office1"]]: {
    x: Math.random() * 5 + -19.26,
    y: 1.5,
    z: Math.random() * 10 + 62.74,
  },
  [sceneObject["showroom"]]: {
    x: Math.random() * 8 - 11,
    y: 1,
    z: Math.random() * 8 + 46,
  },
  [sceneObject["showroom2"]]: {
    x: 15.06,
    y: 1.0,
    z: 1.77,
  },
  [sceneObject["hr"]]: {
    x: Math.random() * 10 + 5,
    y: 1.3,
    z: Math.random() * 3 + 31,
  },
  [sceneObject["office2"]]: {
    x: -15 + Math.random() * 10,
    y: 1,
    z: 26 + Math.random() * 5,
  },
  [sceneObject["turkey"]]: {
    x: 0.9 + Math.random() * 1,
    y: 2.9,
    z: 0.57 + Math.random() * 1,
  },
  [sceneObject["hall"]]: {
    x: -63.83 + Math.random() * 1,
    y: 4.0,
    z: 53.06 + Math.random() * 1,
  },
  [sceneObject["hall2"]]: {
    x: -11.64 + Math.random() * 1,
    y: 2.0,
    z:48.18 + Math.random() * 1,
  },
  [sceneObject["tourism"]]: {
    x: -11.64 + Math.random() * 1,
    y: 1.0,
    z:48.18 + Math.random() * 1,
  },
};

const mySmallmapPlayerPosition = {
  [sceneObject["mr"]]: {
    x: 2.0,
    y: 2.0,
    left: 90,
    top: 90,
  },
  [sceneObject["mr2"]]: {
    x: 1.0,
    y: 1.0,
    left: 125,
    top: 100,
  },
  [sceneObject["mr3"]]: {
    x: 1.0,
    y: 1.0,
    left: 125,
    top: 100,
  },
  [sceneObject["dr"]]: {
    x: 1.5,
    y: 1.5,
    left: 90,
    top: 90,
  },
  [sceneObject["school"]]: {
    x: 1.4,
    y: 1.4,
    left: 85,
    top: 90,
  },
  [sceneObject["office1"]]: {
    x: 1.4,
    y: 1.4,
    left: 85,
    top: 90,
  },
  [sceneObject["showroom"]]: {
    x: 1.4,
    y: 1.4,
    left: 95,
    top: 90,
  },
  [sceneObject["showroom2"]]: {
    x: 1.4,
    y: 1.4,
    left: 95,
    top: 90,
  },
  [sceneObject["hr"]]: {
    x: 1,
    y: 1,
    left: 95,
    top: 90,
  },
  [sceneObject["office2"]]: {
    x: 0.8,
    y: 0.8,
    left: 90,
    top: 90,
  },
  [sceneObject["turkey"]]: {
    x: 0.8,
    y: 0.8,
    left: 90,
    top: 90,
  },
  [sceneObject["hall"]]: {
    x: 0.8,
    y: 0.8,
    left: 90,
    top: 90,
  },
  [sceneObject["hall2"]]: {
    x: 0.8,
    y: 0.8,
    left: 90,
    top: 90,
  },
  [sceneObject["tourism"]]: {
    x: 0.8,
    y: 0.8,
    left: 90,
    top: 90,
  },
};

const myPlayerLandRotation = {
  x: 0,
  y: 0,
  z: 0,
};

export { myPlayerLandPosition, myPlayerLandRotation, mySmallmapPlayerPosition };
