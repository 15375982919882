import { configureStore } from "@reduxjs/toolkit";
import authenticationReducer from "../features/Authentication/AuthenticationSlice";
import enterWorldReducer from "../features/EnterWorldPopUp/EnterWorldSlice";
import paginationSlice from "../features/PaginationData/PaginationDataSlice";
import userLikesSlice from "../features/UserLikes/UserLikesSlice";
import roomInfoSlice from '../features/RoomInfo/RoomInfoDataSlice';
import errorHandlingReducer from "../features/ErrorHandling/ErrorHandlingSlice"

const store = configureStore({
    reducer: {
        authentication: authenticationReducer,
        enterWorld: enterWorldReducer,
        pagination: paginationSlice,
        userLikes: userLikesSlice,
        roomInfo : roomInfoSlice,
        errorHandling: errorHandlingReducer
    }
});

export default store;
