const useGetNameCookie = () => {
  const cookie = document.cookie;
  const cookieSplit = cookie.split(";");
  const cookieSplitMap = cookieSplit.map((item, i) => {
    return item.split("=");
  });
  const filteredCookie = cookieSplitMap.filter((item, i) => {
    return (
      item[0].trim() === "avtar_name" ||
      item[0].trim() === "avtar_id" ||
      item[0].trim() === "interest_intent_email_id"
    );
  });

  if (filteredCookie.length !== 0) {
    const name = filteredCookie.filter(
      (item) => item[0].trim() === "avtar_name"
    );
    const id = filteredCookie.filter((item) => item[0].trim() === "avtar_id");
    const interestIntentEmailId = filteredCookie.filter(
      (item) => item[0].trim() === "interest_intent_email_id"
    );
    return {
      avtar_name: name[0][1],
      avtar_id: id[0][1],
      interestIntentEmailId:
        interestIntentEmailId.length === 0 ? null : interestIntentEmailId[0][1],
    };
  } else {
    return false;
  }
};

export default useGetNameCookie;
