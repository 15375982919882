import React, { useEffect, useState } from "react";
import "./ImgageVideoFile.css";
import {
  CloseBars,
  SeetingIconSvg,
  UploadBarSvgIcon,
} from "../../common/svgIcon";
import { callGetRoomMedia, callRoomMedia } from "../../common/axiosCalls";

const ImageUpload = ({ roomInfo, screen, dispatch, setToast }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serverImages, setServerImages] = useState(false);
  const [imgToUpload, setImgToUpload] = useState(null);
  const [callServer, setCallServer] = useState(true);

  useEffect(() => {
    const getMedia = async () => {
      try {
        const media = await callGetRoomMedia({
          room_name: roomInfo.room_name,
        });

        if (!media.data.status) {
          return;
        }

        setServerImages(
          media.data.data[roomInfo.room_name].Image_links[screen]
        );
      } catch (error) {
        console.log(error);
      }
    };
    getMedia();
  }, [callServer]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const imageInputHandler = (e) => {
    if (e.target.files[0].type === "image/png") {
      dispatch(
        setToast({
          type: "error",
          message: "Please select a JPG Image",
        })
      );
      return;
    }

    if (e.target.files[0].size > 1 * 1024 * 1024) {
      dispatch(
        setToast({
          type: "error",
          message: "Please select file less than 500KB",
        })
      );
      return;
    }
    setImgToUpload(e.target.files[0]);
  };

  const HandleUploadImage = async (e) => {
    e.preventDefault();
    if (!imgToUpload) {
      dispatch(
        setToast({
          type: "error",
          message: "Please select an appropriate image !",
        })
      );
      return;
    }

    const result = await callRoomMedia({
      room_name: roomInfo.room_name,
      screen,
      screen_img: imgToUpload,
    });

    setImgToUpload(null);
    setIsModalOpen(false);
    setCallServer(!callServer);
  };

  if (!serverImages && !roomInfo.is_admin) {
    return (
      <>
        <div className="main-file">
          <h1 className="no-exist">No Image Exist</h1>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="main-file">
        {serverImages && roomInfo.is_admin && (
          <div title="Change Image" className="svg-wrap" onClick={openModal}>
            <SeetingIconSvg />
          </div>
        )}

        {serverImages && <img src={serverImages} alt="screen" />}
        {roomInfo.is_admin && !serverImages && (
          <div className="container-upload">
            <div className="header-upload" onClick={openModal}>
              <UploadBarSvgIcon />
              <p>Browse image to upload!</p>
            </div>
          </div>
        )}

        {isModalOpen && (
          <div className="main-overlay">
            <div className="utMain">
              <form onSubmit={HandleUploadImage} className="UTform">
                <input
                  className="UTinput"
                  type="file"
                  accept="image/*"
                  name=""
                  id=""
                  onChange={imageInputHandler}
                ></input>
                <button type="submit" className="UTbtn">
                  Upload
                </button>
              </form>
              <div className="Close-btn-img" onClick={closeModal}>
                <CloseBars ImgUpload={true} />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ImageUpload;
