import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ChatSideBar from "../dashboard/creator/chatSidebar/ChatSidebar";
import SharingIcons from "../RoomSharingLink/SharingIcons";
import styles from "./Room.module.css";
import CDashboard from "../dashboard/creator/CDashboard";
import { useSelector } from "react-redux";
import VisitorProfile from "./VisitorProfile";
import {
  callCreateMetaverseClickCheck,
  callDeactivateRoomVisitor,
} from "../../common/axiosCalls";
import MetaverseWorld from "./Metaverse World/MetaverseWorld";
import ExploreWorld from "./exploreWorld/ExploreWorld";
import LeaveRoom from "./leaveRoom/LeaveRoom";
import LikeButton from "../postCard/LikeButton";
import { avatars } from "../../common/mediaConstants";
import useGetNameCookie from "../../common/getNameCookie";
import RoomProfile from "./roomProfile/RoomProfile";

import {
  ChatSvgIcon,
  ExploreSvgIcon,
  LeaveSvgIcon,
  ShareSvgIcon,
} from "../../common/svgIcon";
// import { useDeleteMyPlayer } from "../../../R3F/Component/socketManager/DeleteMyPlayer";

function RoomFooterMenu({ setFocus }) {
  const authentication = useSelector((store) => store.authentication);
  const roomInfo = useSelector((store) => store.roomInfo);

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const tidioIframe = document.querySelector("#tidio-chat-iframe");
  const cookies = useGetNameCookie();

  if (tidioIframe) {
    tidioIframe.style.display = "none";
  }

  useEffect(() => {
    setFocus(isOpen);
  }, [isOpen]);

  const createWorldHandler = () => {
    const roomName = roomInfo.room_name;
    if (authentication.isAuthenticated) {
      callDeactivateRoomVisitor({ roomName }).then((response) => {
        navigate("/create-room");
      });
    } else {
      let registrationID = 10000000;
      const cookies = document.cookie
        .split(";")
        .map((item) => {
          return item.split("=");
        })
        .filter((item) => item[0].trim() === "registration_id");
      registrationID = cookies.length === 0 ? 10000000 : cookies[0][1];

      callCreateMetaverseClickCheck({
        id: registrationID,
      });
      navigate("/create-room");
    }
  };

  const humburgerHandler = () => {
    setIsMobile(!isMobile);
  };


  return (
    <>
      {!authentication.isAuthenticated && (
        <button
          className={styles["cb"]}
          onClick={createWorldHandler}
          title="Create World"
        >
          <span>Create World</span>
        </button>
      )}
      <button
        className={styles["cb1"]}
        onClick={() => {
          setIsOpen("exploreWorld");
        }}
        title="Explore World"
      >
        <span>Explore World</span>
      </button>

      <div
        className={styles["pro_room_btn"]}
        onClick={() => setIsOpen("profile")}
        title="profile"
      >
        <img
          className={styles["imgbtn"]}
          src={
            avatars[
              authentication.isAuthenticated
                ? authentication.userDetails?.avtar_id - 1
                : cookies.avtar_id - 1
            ]
          }
          alt="profile"
        />
      </div>

      <div className={isMobile ? styles["menubar"] : styles["nav1"]}>
        <div className={styles["sharenew"]}>
          {authentication.isAuthenticated && roomInfo.is_admin && (
            // <button
            //   className={styles["exp-btn_chat"]}
            //   title="share"
            //   onClick={() => {
            //     setIsOpen("shareUser");
            //   }}
            // >
            //   <ShareSvgIcon />
            // </button>

            <button
            title="share"
            className={`${styles["exp-btn-share"]}`}
            onClick={() => {
              setIsOpen("shareUser");
            }}
          >
            <ShareSvgIcon />
            <span className={styles["button_heading"]}> Share&nbsp;</span>
          </button>

          )}
        </div>

        <div className={styles["nav2"]}>
          <div>
            <button
              title="Leave Room"
              className={`${styles["exp-btn"]}`}
              onClick={() => {
                setIsOpen("leaveRoom");
              }}
            >
              <LeaveSvgIcon />
              <span className={styles["button_heading"]}> Leave&nbsp;</span>
            </button>
          </div>
          <div title="Explore">
            <button
              title="Explore"
              className={`${styles["exp-btn_chat"]}`}
              onClick={() => {
                setIsOpen("exploreWorld");
              }}
            >
              <ExploreSvgIcon />
              {/* <span className={styles["button_heading"]}> Explore&nbsp;</span> */}
            </button>
          </div>

          {authentication.isAuthenticated && (
            <div>
              <button
                title="Chat"
                className={`${styles["exp-btn_chat"]}`}
                onClick={() => setIsOpen("liveChat")}
              >
                <ChatSvgIcon />
              </button>
            </div>
          )}

          <div className={styles["like_button"]} title="Like">
            <LikeButton
              room_name={roomInfo.room_name}
              room_id={roomInfo.room_id}
              RoomLikeButton={true}
            />
          </div>
        </div>
      </div>

      {authentication.isAuthenticated && (
        <div className={styles["hamburger-menu"]}>
          <button
            className={styles["hamburger-menu_hight"]}
            onClick={humburgerHandler}
          >
            {isMobile ? (
              <i className={`${"hum_icon "} material-icons `}>close</i>
            ) : (
              <i className={`${"hum_icon "} material-icons `}>menu</i>
            )}
          </button>
        </div>
      )}

      {isOpen === "liveChat" && (
        <ChatSideBar
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          room_name={roomInfo.room_name}
        />
      )}

      {authentication.isAuthenticated && isOpen === "dashboard" && (
        <CDashboard isOpen={isOpen} setIsOpen={setIsOpen} />
      )}

      {isOpen === "shareUser" && (
        <SharingIcons isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
      {isOpen === "MetaverseWorld" && (
        <MetaverseWorld isOpen={isOpen} setIsOpen={setIsOpen} />
      )}

      {isOpen === "exploreWorld" && (
        <ExploreWorld 
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          room_name={roomInfo.room_name}
        />
      )}

      {authentication.isAuthenticated ? (
        isOpen === "profile" ? (
          <RoomProfile 
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        ) : null
      ) : (
        isOpen === "profile" && (
          <VisitorProfile isOpen={isOpen} setIsOpen={setIsOpen} />
        )
      )}

      {isOpen === "leaveRoom" && (
        <LeaveRoom 
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          room_name={roomInfo.room_name}
        />
      )}
    </>
  );
}
export default RoomFooterMenu;
