const styles = {
  height: "30px",
};

export const MenuBars = () => {
  const styles = {
    height: "30px",
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className=""
      style={styles}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
      />
    </svg>
  );
};

export const SeetingIconSvg = () => {
  const styles = {
    height: "40px",
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      style={styles}
    >
      <path d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
      <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    </svg>
  );
};

export const AddWorld = () => {
  const styles = {
    height: "30px",
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
      style={styles}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>
  );
};

export const CloseBars = ({ ImgUpload }) => {
  const someObject = {
    height: ImgUpload ? "18px" : "30px",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
      style={someObject}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );
};

// use Use Home Page

export const ArrowLongRight = () => {
  const styles = {
    height: "25px",
    position: "absolute",
    color: "#fff",
    marginLeft: "6px",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      style={styles}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
      />
    </svg>
  );
};

// use Like
export const LikeSolidButton = () => {
  const styles = {
    height: "25px",
    position: "absolute",
    color: "#fff",
    marginLeft: "6px",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="w-6 h-6"
      style={styles}
    >
      <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
    </svg>
  );
};

// use Dislike

export const DislikeOutlineButton = () => {
  const styles = {
    height: "25px",
    position: "absolute",
    color: "#fff",
    marginLeft: "6px",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
      style={styles}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
      />
    </svg>
  );
};

// use edit avtar

export const PencileEdit = () => {
  const styles = {
    height: "25px",
    color: "#f65f65",
    marginLeft: "6px",
    marginBottom: "7px",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
      style={styles}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
      />
    </svg>
  );
};

// use emailShare

export const ArrowLeft = () => {
  const styles = {
    height: "25px",
    color: "#f65f65",
    marginLeft: "6px",
    marginBottom: "7px",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
      />
    </svg>
  );
};

// use Login

export const GoogleLogoSvg = () => {
  return (
    <svg
      xmlSpace="preserve"
      style={{ enableBackground: "new 0 0 512 512" }}
      viewBox="0 0 512 512"
      y="0px"
      x="0px"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      width={20}
      version="1.1"
    >
      <path
        d="M113.47,309.408L95.648,375.94l-65.139,1.378C11.042,341.211,0,299.9,0,256
c0-42.451,10.324-82.483,28.624-117.732h0.014l57.992,10.632l25.404,57.644c-5.317,15.501-8.215,32.141-8.215,49.456
C103.821,274.792,107.225,292.797,113.47,309.408z"
        style={{ fill: "#FBBB00" }}
      />
      <path
        d="M507.527,208.176C510.467,223.662,512,239.655,512,256c0,18.328-1.927,36.206-5.598,53.451
c-12.462,58.683-45.025,109.925-90.134,146.187l-0.014-0.014l-73.044-3.727l-10.338-64.535
c29.932-17.554,53.324-45.025,65.646-77.911h-136.89V208.176h138.887L507.527,208.176L507.527,208.176z"
        style={{ fill: "#518EF8" }}
      />
      <path
        d="M416.253,455.624l0.014,0.014C372.396,490.901,316.666,512,256,512
c-97.491,0-182.252-54.491-225.491-134.681l82.961-67.91c21.619,57.698,77.278,98.771,142.53,98.771
c28.047,0,54.323-7.582,76.87-20.818L416.253,455.624z"
        style={{ fill: "#28B446" }}
      />
      <path
        d="M419.404,58.936l-82.933,67.896c-23.335-14.586-50.919-23.012-80.471-23.012
c-66.729,0-123.429,42.957-143.965,102.724l-83.397-68.276h-0.014C71.23,56.123,157.06,0,256,0
C318.115,0,375.068,22.126,419.404,58.936z"
        style={{ fill: "#F14336" }}
      />
    </svg>
  );
};

// Facebbok

export const FacebookLogoSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      fill="currentColor"
      className="bi bi-facebook"
      viewBox="0 0 16 16"
    >
      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />{" "}
    </svg>
  );
};

export const LoginPasswordIconSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      viewBox="-64 0 512 512"
      height={20}
    >
      <path d="m336 512h-288c-26.453125 0-48-21.523438-48-48v-224c0-26.476562 21.546875-48 48-48h288c26.453125 0 48 21.523438 48 48v224c0 26.476562-21.546875 48-48 48zm-288-288c-8.8125 0-16 7.167969-16 16v224c0 8.832031 7.1875 16 16 16h288c8.8125 0 16-7.167969 16-16v-224c0-8.832031-7.1875-16-16-16zm0 0" />
      <path d="m304 224c-8.832031 0-16-7.167969-16-16v-80c0-52.929688-43.070312-96-96-96s-96 43.070312-96 96v80c0 8.832031-7.167969 16-16 16s-16-7.167969-16-16v-80c0-70.59375 57.40625-128 128-128s128 57.40625 128 128v80c0 8.832031-7.167969 16-16 16zm0 0" />
    </svg>
  );
};

export const LoginEmailIconSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      viewBox="0 0 32 32"
      height={20}
    >
      <g data-name="Layer 3" id="Layer_3">
        <path d="m30.853 13.87a15 15 0 0 0 -29.729 4.082 15.1 15.1 0 0 0 12.876 12.918 15.6 15.6 0 0 0 2.016.13 14.85 14.85 0 0 0 7.715-2.145 1 1 0 1 0 -1.031-1.711 13.007 13.007 0 1 1 5.458-6.529 2.149 2.149 0 0 1 -4.158-.759v-10.856a1 1 0 0 0 -2 0v1.726a8 8 0 1 0 .2 10.325 4.135 4.135 0 0 0 7.83.274 15.2 15.2 0 0 0 .823-7.455zm-14.853 8.13a6 6 0 1 1 6-6 6.006 6.006 0 0 1 -6 6z" />
      </g>
    </svg>
  );
};

export const RegistrationEmailIconSvg = () => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="#2e2e2e"
      height="16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      className="inputIcon"
    >
      <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z"></path>
    </svg>
  );
};

export const RegistrationPasswordIconSvg = () => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="#2e2e2e"
      height={16}
      width={16}
      xmlns="http://www.w3.org/2000/svg"
      className="inputIcon"
    >
      <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
    </svg>
  );
};

export const LocationSvgIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
      width="20"
      height="20"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
      />
    </svg>
  );
};

export const EmailSvgIcon = () => {
  const styles = {
    fontWeight: "bold",
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
      width="20"
      height="20"
      style={styles}
    >
      <path
        strokeLinecap="round"
        d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"
      />
    </svg>
  );
};

// toast ---

export const ToastSuccessIcon = () => {
  return (
    <svg
      className="icon-32-check"
      width="18"
      height="18"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M27.3012 6.34639C28.3603 7.15845 28.5625 8.6745 27.7531 9.7357L15.7428 25.4829C15.3193 26.0382 14.6774 26.3832 13.9817 26.4295C13.2861 26.4758 12.6044 26.2189 12.1114 25.7246L4.48838 18.0815C3.54564 17.1363 3.54564 15.6064 4.48838 14.6612C5.43464 13.7124 6.97147 13.7124 7.91773 14.6612L13.5784 20.3368L23.9033 6.79944C24.7155 5.73443 26.2382 5.5314 27.3012 6.34639Z" fill="white">
      </path>

    </svg>
  );
};

export const ToastSnapIcon = () => {
  return (
    <svg
      className="icon-32-check"
      width="18"
      height="18"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.8134 9.6379C26.6418 8.8095 26.6418 7.46638 25.8134 6.63797L25.362 6.18649C24.5336 5.35808 23.1904 5.35808 22.362 6.18649L16 12.5486L9.6379 6.18649C8.80949 5.35809 7.46638 5.35808 6.63797 6.18649L6.18649 6.63797C5.35808 7.46638 5.35808 8.80949 6.18649 9.6379L12.5486 16L6.18649 22.362C5.35809 23.1904 5.35808 24.5336 6.18649 25.362L6.63797 25.8134C7.46638 26.6419 8.80949 26.6419 9.6379 25.8134L16 19.4514L22.362 25.8134C23.1904 26.6418 24.5336 26.6419 25.362 25.8134L25.8134 25.362C26.6419 24.5336 26.6418 23.1904 25.8134 22.362L19.4514 16L25.8134 9.6379Z"
        fill="white"
      />
    </svg>
  );
};

export const ToastSuccessBlueCircleIcon = () => {
  return (
    <svg
      className="union3"
      width="50"
      height="56"
      viewBox="0 0 70 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
  <path fillRule="evenodd" clipRule="evenodd" d="M34.9999 68.64C53.9543 68.64 69.3199 53.2744 69.3199 34.32C69.3199 15.3656 53.9543 0 34.9999 0C16.0455 0 0.679932 15.3656 0.679932 34.32C0.679932 44.9926 5.5515 54.5274 13.1923 60.822C13.2045 60.832 13.1936 60.8515 13.1787 60.8464C13.1695 60.8433 13.16 60.8501 13.16 60.8598V71.5042C13.16 74.4834 16.3001 76.4171 18.9605 75.076L30.9719 69.0212C31.6354 68.6867 32.3786 68.5494 33.1205 68.5894C33.7427 68.623 34.3693 68.64 34.9999 68.64Z" fill="#004E32">
</path>

    </svg>
  );
};

export const ToastSuccessRedCircleIcon = () => {
  return (
    <svg
      className="union3"
      width="50"
      height="56"
      viewBox="0 0 70 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
  <path fillRule="evenodd" clipRule="evenodd" d="M43.8055 67.6794C44.1344 67.4706 44.4933 67.3124 44.8664 67.2006C59.0117 62.9621 69.3199 49.8448 69.3199 34.32C69.3199 15.3656 53.9543 0 34.9999 0C16.0455 0 0.679932 15.3656 0.679932 34.32C0.679932 47.8227 8.47774 59.5042 19.8158 65.1068C19.8286 65.1132 19.8233 65.1326 19.8091 65.1315C19.7999 65.1308 19.793 65.1396 19.7957 65.1483L22.9037 74.9903C23.6946 77.4949 26.6437 78.5705 28.8614 77.1631L43.8055 67.6794Z" fill="#801336">
</path>

    </svg>
  );
};

export const ToastSuccessGreenBubblesIcon = () => {
  return (
    <svg
      className="green-bubbles"
      width="95"
      height="132"
      viewBox="0 0 115 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="71.6075" cy="9.29547" r="9.29547" fill="#004E32" />
      <circle cx="131.099" cy="47.7167" r="3.71819" fill="#004E32" />
      <path
        d="M130.444 11.0253C133.497 18.5483 129.874 27.1221 122.351 30.1754C121.521 30.5122 120.678 30.7678 119.832 30.9458C115.12 31.9366 109.859 33.2841 107.338 37.3855C104.395 42.1741 106.004 48.3927 110.35 51.9571C119.015 59.0642 126.027 68.4315 130.383 79.6187C143.906 114.348 126.715 153.464 91.9856 166.987C57.2564 180.51 18.1401 163.319 4.61711 128.59C-8.90593 93.8607 8.28512 54.7445 43.0144 41.2214C58.1834 35.3148 74.1894 35.2678 88.5341 39.9824C93.7299 41.69 99.6536 40.072 102.517 35.4125L103.582 33.68C105.694 30.2441 104.717 25.8191 103.201 22.0823C100.147 14.5592 103.771 5.98544 111.294 2.93215C118.817 -0.121136 127.391 3.5023 130.444 11.0253Z"
        fill="#004E32"
      />
    </svg>
  );
};

export const ToastSuccessRedBubblesIcon = () => {
  return (
    <svg
      className="red-bubbles"
      width="159"
      height="199"
      viewBox="0 0 159 199"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="99.9429" cy="36.8292" r="6.82918" fill="#801336" />
      <circle cx="145" cy="27" r="13" fill="#801336" />
  <path fillRule="evenodd" clipRule="evenodd" d="M98.5511 49.4389C98.4191 48.7782 97.9357 48.2428 97.2918 48.0442C96.5686 47.8212 95.7827 48.0647 95.3124 48.6576L92.6097 52.0648C90.7585 54.3984 87.7904 55.5017 84.8235 55.2372C77.5519 54.5888 70.046 55.1701 62.5798 57.1407C28.0482 66.255 7.4433 101.637 16.5575 136.169C25.6718 170.7 61.0537 191.305 95.5853 182.191C130.117 173.077 150.722 137.695 141.608 103.163C136.381 83.3617 122.518 68.1397 105.13 60.4748C102.234 59.1983 100.001 56.6968 99.3809 53.5935L98.5511 49.4389Z" fill="#801336">
</path>

      <circle
        cx="87.5475"
        cy="4.5476"
        r="3.49096"
        transform="rotate(-22.0902 87.5475 4.5476)"
        fill="#801336"
      />
    </svg>
  );
};

export const ToastCloseIcon = () => {
  return (
    <svg
      className="icon-24-close"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.93934 18.9393C2.35355 19.5251 2.35355 20.4749 2.93934 21.0607C3.52513 21.6464 4.47487 21.6464 5.06066 21.0607L12 14.1213L18.9393 21.0607C19.5251 21.6465 20.4749 21.6465 21.0607 21.0607C21.6464 20.4749 21.6464 19.5251 21.0607 18.9393L14.1213 12L21.0607 5.06066C21.6464 4.47487 21.6464 3.52513 21.0607 2.93934C20.4749 2.35355 19.5251 2.35355 18.9393 2.93934L12 9.87868L5.06066 2.93934C4.47487 2.35356 3.52513 2.35356 2.93934 2.93934C2.35355 3.52513 2.35355 4.47488 2.93934 5.06066L9.87868 12L2.93934 18.9393Z"
        fill="white"
      />
    </svg>
  );
};

export const FooterLocationIcon = () => {
  const styles = {
    height: "40px",
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="#ff512f"
      className="w-6 h-6"
      style={styles}
    >
      <path
        fillRule="evenodd"
        d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const FooterPhoneIcon = () => {
  const styles = {
    height: "35px",
  };

  const gradientId = "footerPhoneIconGradient"; // Create a unique ID for the gradient

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="w-6 h-6"
      style={styles}
    >
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#ff512f" />
          <stop offset="100%" stopColor="#dd2476" />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${gradientId})`}
        fillRule="evenodd"
        d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const FooterMailIcon = () => {
  const styles = {
    height: "35px",
  };

  const gradientId = "footerMailIconGradient";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="w-6 h-6"
      style={styles}
    >
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#ff512f" />
          <stop offset="100%" stopColor="#dd2476" />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${gradientId})`}
        fillRule="evenodd"
        d="M5.478 5.559A1.5 1.5 0 016.912 4.5H9A.75.75 0 009 3H6.912a3 3 0 00-2.868 2.118l-2.411 7.838a3 3 0 00-.133.882V18a3 3 0 003 3h15a3 3 0 003-3v-4.162c0-.299-.045-.596-.133-.882l-2.412-7.838A3 3 0 0017.088 3H15a.75.75 0 000 1.5h2.088a1.5 1.5 0 011.434 1.059l2.213 7.191H17.89a3 3 0 00-2.684 1.658l-.256.513a1.5 1.5 0 01-1.342.829h-3.218a1.5 1.5 0 01-1.342-.83l-.256-.512a3 3 0 00-2.684-1.658H3.265l2.213-7.191z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 2.25a.75.75 0 01.75.75v6.44l1.72-1.72a.75.75 0 111.06 1.06l-3 3a.75.75 0 01-1.06 0l-3-3a.75.75 0 011.06-1.06l1.72 1.72V3a.75.75 0 01.75-.75z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const FooterSocialIcon = () => {
  const styles = {
    height: "35px",
  };

  const gradientId = "footerIconGradient";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="w-6 h-6"
      style={styles}
    >
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#ff512f" />
          <stop offset="100%" stopColor="#dd2476" />
          {/* <stop offset="100%" stopColor="#ff512f" /> */}
        </linearGradient>
      </defs>
      <path
        fill={`url(#${gradientId})`}
        fillRule="evenodd"
        d="M4.5 3.75a3 3 0 00-3 3v10.5a3 3 0 003 3h15a3 3 0 003-3V6.75a3 3 0 00-3-3h-15zm4.125 3a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm-3.873 8.703a4.126 4.126 0 017.746 0 .75.75 0 01-.351.92 7.47 7.47 0 01-3.522.877 7.47 7.47 0 01-3.522-.877.75.75 0 01-.351-.92zM15 8.25a.75.75 0 000 1.5h3.75a.75.75 0 000-1.5H15zM14.25 12a.75.75 0 01.75-.75h3.75a.75.75 0 010 1.5H15a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3.75a.75.75 0 000-1.5H15z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const UploadBarSvgIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        <path
          d="M7 10V9C7 6.23858 9.23858 4 12 4C14.7614 4 17 6.23858 17 9V10C19.2091 10 21 11.7909 21 14C21 15.4806 20.1956 16.8084 19 17.5M7 10C4.79086 10 3 11.7909 3 14C3 15.4806 3.8044 16.8084 5 17.5M7 10C7.43285 10 7.84965 10.0688 8.24006 10.1959M12 12V21M12 12L15 15M12 12L9 15"
          stroke="#000000"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const LeftSideSvgIcon = () => {
  const styles = {
    height: "40px",
  };

  if (window.matchMedia("(max-width: 500px)").matches) {
    styles.height = "25px";
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
      style={styles}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 19.5L8.25 12l7.5-7.5"
      />
    </svg>
  );
};

export const RightSideSvgIcon = () => {
  const styles = {
    height: "40px",
  };
  if (window.matchMedia("(max-width: 500px)").matches) {
    styles.height = "25px";
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
      style={styles}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.25 4.5l7.5 7.5-7.5 7.5"
      />
    </svg>
  );
};

export const LeaveSvgIcon = () => {
  const styles = {
    height: "20px",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="#fffffff5"
      className="w-6 h-6"
      style={styles}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
      />
    </svg>
  );
};

export const ExploreSvgIcon = () => {
  const styles = {
    height: "20px",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="#fffffff5"
      className="w-6 h-6"
      style={styles}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 3.75H6.912a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859M12 3v8.25m0 0l-3-3m3 3l3-3"
      />
    </svg>
  );
};

export const ChatSvgIcon = () => {
  const styles = {
    height: "20px",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="#fffffff5"
      className="w-6 h-6"
      style={styles}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
      />
    </svg>
  );
};

export const ShareSvgIcon = () => {
  const styles = {
    height: "20px",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="#fffffff5"
      className="w-6 h-6"
      style={styles}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
      />
    </svg>
  );
};

// hide and show password

export const EyeCloseSvgIcon = () => {
  const styles = {
    height: "21px",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="w-6 h-6"
      style={styles}
    >
      <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" />
      <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" />
      <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" />
    </svg>
  );
};

export const EyeOpenSvgIcon = () => {
  const styles = {
    height: "21px",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="w-6 h-6"
      style={styles}
    >
      <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
      <path
        fillRule="evenodd"
        d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const PlaycircleSvgIcon = () => {
  const styles = {
    height: "35px",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
      style={styles}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
      />
    </svg>
  );
};
