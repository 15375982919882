import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { callUpdateRoomThumbnail } from "../../../common/axiosCalls";
import { setToast } from "../../../features/ErrorHandling/ErrorHandlingSlice";
import classes from "./UpdateThumbnail.module.css";

const UpdateThumbnail = ({ setUpdateThumbnailInfo, updateThumbnailInfo }) => {
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();

  const imageUploadHandler = (e) => {
    if (e.target.files[0].size > 500000) {
      dispatch(setToast({
        type: 'error',
        message: 'Please select file less than 500KB'
      }));
      return;
    } else {
      setImage(e.target.files[0]);
    }
  };

  const updateThumbnailHandler = (e) => {
    e.preventDefault();

    if (!image) {
      dispatch(setToast({
        type:"error",
        message: 'Please select an appropriate image.'
      }))
      return;
    }

    callUpdateRoomThumbnail({
      room_name: updateThumbnailInfo.room_to_update,
      profile_thumbnail: image,
    })
      .then((res) => {
        setUpdateThumbnailInfo((prevState) => {
          return {
            ...prevState,
            showUploadBtn: !prevState.showUploadBtn,
            room_to_update: null
          };
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className={classes.utMain}>
        <form onSubmit={updateThumbnailHandler} className={classes.UTform}>
          <input
            className={classes.UTinput}
            type="file"
            accept="image/*"
            name=""
            id=""
            onChange={imageUploadHandler}
          ></input>
          <button className={classes.UTbtn} type="submit">
            Upload
          </button>
        </form>
      </div>
    </>
  );
};

export default UpdateThumbnail;
