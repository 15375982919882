import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  userDetails: null,
  userIP: null,
};

const authenticationSlice = createSlice({
  name: "authenticationSlice",
  initialState,
  reducers: {
    setAuthentication: (state, action) => {
      state.isAuthenticated = action.payload;
    },

    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },

    setIPAddress: (state, action) => {
      state.userIP = action.payload;
    },

    updateMyRooms: (state, action) => {
      state.userDetails.rooms_created_by_me = [
        ...state.userDetails?.rooms_created_by_me,
        action.payload,
      ];
    },
  },
});

export const {
  setAuthentication,
  setUserDetails,
  setIPAddress,
  updateMyRooms,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
