import React from "react";
import styles from "./ExploreWorld.module.css";
import { useSelector } from "react-redux";
import { callDeactivateRoomVisitor } from "../../../common/axiosCalls";
import { useNavigate } from "react-router";

const ExploreWorld = ({ setIsOpen, room_name }) => {
  const authentication = useSelector((store) => store.authentication);
  const navigate = useNavigate();

  const ConfirmClose = () => {
    if (authentication.isAuthenticated) {
      callDeactivateRoomVisitor({ room_name }).then(() => {
        navigate("/explore-metaverse");
      });
    } else {
      navigate("/explore-metaverse");
    } 
  };

  const ConfirmNOclose = () => {
    setIsOpen(null);
  };

  return (
    <>
      <div className={styles["main_1"]}>
        <div className={styles["explore_wrapper"]}>
          <div className={styles["main_section"]}>
            <h3 className={styles["confirm_Box_heading"]}>
              Are you sure you want to leave this room ?
            </h3>
            <div className={styles["confirm__box_buttons"]}>
              <button className={styles["confirm_yes"]} onClick={ConfirmClose}>
                Yes
              </button>
              <button
                className={styles["confirm_cancel"]}
                onClick={ConfirmNOclose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExploreWorld;
