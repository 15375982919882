import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { callAnalytics } from "../../../common/axiosCalls";
import styles from "./CDashboard.module.css";

function CDashboard({ setIsOpen, isOpen }) {
  const [dashboardData, setDashboardData] = useState();
  const roomInfo = useSelector((store) => store.roomInfo);

  useEffect(() => {
    async function getAnalytics() {
      try {
        const analytics = await callAnalytics({
          room_name: roomInfo.room_name.replaceAll("%20", " "),
        });
        setDashboardData(analytics.data.analytics);
      } catch (error) {
        console.log(error);
      }
    }

    getAnalytics();
  }, []);

  return (
    <>
      <div className={styles["circle-container"]}>
        <div className={styles["circle"]}>
          <div className={styles["inner-circle"]}>
            {dashboardData && dashboardData.myMetaVisitors}
          </div>
          <div className={styles["name"]}>My Visitors</div>
        </div>

        <div className={styles["circle"]}>
          <div className={styles["inner-circle"]}>
            {dashboardData && dashboardData.myMetaLiveVisitors}
          </div>
          <div className={styles["name"]}>Live Visitors</div>
        </div>

        <div className={styles["circle"]}>
          <div className={styles["inner-circle"]}>
            {dashboardData && dashboardData.myMetaLikes}
          </div>
          <div className={styles["name"]}>World Likes</div>
        </div>

        <div className={styles["circle"]}>
          <div className={styles["inner-circle"]}>
            {dashboardData && dashboardData.myMetaverse}
          </div>
          <div className={styles["name"]}>My World</div>
        </div>
      </div>
    </>
  );
}
export default CDashboard;
