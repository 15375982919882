import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showModal: false,
  room_name: null,
  room_type: null,
  room_id: null,
  modalFor: {
    purpose: "",
    data: {},
  },
};

const enterWorldSlice = createSlice({
  name: "enterWorldSlice",
  initialState,
  reducers: {
    openModal: (state) => {
      state.showModal = true;
    },

    closeModal: (state) => {
      state.showModal = false;
    },
    setRoomName: (state, action) => {
      state.room_name = action.payload;
    },
    setRoomId: (state, action) => {
      state.room_id = action.payload;
    },

    setRoomType: (state, action) => {
      state.room_type = action.payload;
    },

    setModalFor: (state, action) => {
      state.modalFor = action.payload;
    },
  },
});

export const {
  openModal,
  closeModal,
  setRoomName,
  setRoomType,
  setModalFor,
  setRoomId,
} = enterWorldSlice.actions;

export default enterWorldSlice.reducer;
