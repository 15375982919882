import {
  glassMaterialNameArray,
  sittingMaterialNameArray,
  transparentMaterialNameArray,
} from "./UpdateMaterialArray";
import { sittingMeshArray } from "../../experience/Experience";

const UpdateMaterial = (allMeshArray, sceneName) => {
  Object.values(allMeshArray).map((obj) => {
    if (sittingMaterialNameArray[sceneName].includes(obj.material.name)) {
      // if (sceneName !== "r3fmr") {
      //   obj.rotation.y += Math.PI;
      // }
      // obj.rotation.y += Math.PI;
      sittingMeshArray.push(obj);
    }
    if (glassMaterialNameArray[sceneName].includes(obj.material.name)) { 
      obj.material.transparent = true;
      obj.material.opacity = 0.1;
      obj.material.roughness = 1;
      obj.material.metalness = 0;
    }
    if (transparentMaterialNameArray[sceneName].includes(obj.material.name)) {
      obj.material.transparent = true;
      obj.material.opacity = 0;
    }
  });
};

export { UpdateMaterial };
