import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../layouts/header/Header";

import styles from "./MyMetaverse.module.css";
import { callMultiRooms, callVisitRoom } from "../../common/axiosCalls";
import { setRoomInfo } from "../../features/RoomInfo/RoomInfoDataSlice";
import { screens } from "../../common/mediaConstants"; 
 

const MyMetaverse = () => {
  //Jotai 

  const authentication = useSelector((store) => store.authentication);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rooms, setRooms] = useState([]);
  const isAuthenticated = localStorage.getItem("token");
  useEffect(() => {
    callMultiRooms()
      .then((res) => {
        setRooms(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [isAuthenticated]);
  const roomVisitHandler = (item) => {
    callVisitRoom({ room_name: item.room_name });

    dispatch(
      setRoomInfo({
        avtar_id: authentication.userDetails.avtar_id,
        avtar_name: authentication.userDetails.avtar_name,
        room_type: item.room_type,
        room_name: item.room_name,
        room_id: item.room_id,
        is_admin: true,
      })
    );

  

    navigate(`/room/${item.room_type}/${item.room_name}/${item.room_id}`);
  };

  return (
    <>
      <Header />
      <div className={styles["body_wrapper"]}>
        <div className={styles["MM_container"]}>
          <p className={styles["RC_heading"]}>My World</p>
          <main className={styles["MM_wrapper"]}>
            {rooms?.length === 0 && (
              <div className={styles["MM_noWorld"]}>
                <p>No worlds yet! Please Create a new one.</p>
                <button
                  title="Create Your Metaverse it`s Free"
                  className={styles["MM_createBtn"]}
                  onClick={() => {
                    navigate("/create-room");
                  }}
                >
                  Create World
                </button>
              </div>
            )}
            {rooms?.length > 0 &&
              rooms.map((item, i) => {
                let imageSrc;
                !item.profile_thumbnail
                  ? (imageSrc = screens[item.room_type])
                  : (imageSrc = item.profile_thumbnail);

                return (
                  <div key={i} className={styles["MM_rooms_div"]}>
                    <p>{item.room_name}</p>
                    <div
                      className={styles["MM_rooms"]}
                      onClick={() => roomVisitHandler(item)}
                    >
                      <img src={imageSrc} alt="roomtype" />
                    </div>
                  </div>
                );
              })}
          </main>
        </div>
      </div>
    </>
  );
};

export default MyMetaverse;
