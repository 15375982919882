import React, { useState } from "react";
import "./Footer.css";
import logo from "../../assets/logo/logo-white.svg";
import facebook from "../../assets/images/social/facebook-01.png";
import youtube from "../../assets/images/social/social-2.png";
import insta from "../../assets/images/social/insta-01.png";
import linked from "../../assets/images/social/in-01.png";
import iso from "../../assets/images/footer/ISO.png";
import ISO_27 from "../../assets/images/footer/ISO-27.png";
import GDPR from "../../assets/images/footer/GDPR.png";
import Microsoft from "../../assets/images/footer/Microsoft.png";
import DUNS from "../../assets/images/footer/DUNS.png";
import plogo from "../../assets/images/footer/payment-logo.png";
import and from "../../assets/images/footer/Playstore.png";
import ios from "../../assets/images/footer/Appstore.png";
import meta from "../../assets/images/footer/Meta.png";
import germany from "../../assets/images/footer/germany.png";
import dubai from "../../assets/images/footer/dubai.png";
import india from "../../assets/images/footer/india.png";
import Marstercard from "../../assets/images/footer/Marstercard.jpg";
import Paypal from "../../assets/images/footer/Paypal.jpg";
import American_Express from "../../assets/images/footer/American_Express.jpg";
import Visa from "../../assets/images/footer/Visa.jpg";
import {
  FooterLocationIcon,
  FooterMailIcon,
  FooterPhoneIcon,
  FooterSocialIcon,
} from "../../common/svgIcon";
import { Link } from "react-router-dom";
import { callWebsiteSubscriber } from "../../common/axiosCalls";
import { useDispatch } from "react-redux";
import { setToast } from "../../features/ErrorHandling/ErrorHandlingSlice";

const Footer = () => {
  const dispatch = useDispatch();

  const [isSubscribe, setSubscribe] = useState({
    subscriber: "",
  });
  const [emailIsValid, setEmailIsValid] = useState(false);

  // const handleChange = (e) => {
  //   setSubscribe({ ...isSubscribe, [e.target.name]: e.target.value });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const showToast = (type, message) => {
      dispatch(setToast({ type, message }));
    };
    try {
      const res = await callWebsiteSubscriber(isSubscribe);
      showToast("success", "Email sent succsefully");
    } catch (error) {
      showToast("error", error.message);
    }
    setSubscribe({ subscriber: "" });
  };

  return (
    <>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-cta pt-5 pb-3">
            <div className="row">
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <FooterPhoneIcon />
                  <div className="cta-text">
                    <h4>Call us</h4>
                    <span>+918149976493</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <FooterMailIcon />
                  <div className="cta-text">
                    <h4>Mail us</h4>
                    <a href="mailto:hello@worldbeyond.live">
                      {" "}
                      hello@worldbeyond.live
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <FooterSocialIcon />
                  <div className="cta-text">
                    <h4>Follow us</h4>
                    <a
                      href="https://www.facebook.com/WorldBeyondMet"
                      target="_blank"
                    >
                      <img
                        src={facebook}
                        className="s-img"
                        alt="facebook logo"
                      />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCnKCtehMrD___8cRXdE1W7Q"
                      target="_blank"
                    >
                      <img src={youtube} className="s-img" alt="youtube" />
                    </a>
                    <a
                      href="https://www.instagram.com/accounts/login/?next=%2Fworld_beyondmetaverse%2F"
                      target="_blank"
                      alt="instagram"
                    >
                      <img src={insta} className="s-img" alt="instagram" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/digi-metaverse/"
                      target="_blank"
                    >
                      <img src={linked} className="s-img" alt="linkedin" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-content pt-5 pb-3">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <a href="index.html">
                      <img src={logo} className="img-fluid" alt="logo" />
                    </a>
                  </div>
                  <div className="footer-text">
                    <p>
                      World Beyond Metaverse: Pioneering Metaverse Solutions for
                      Governments & Corporates
                    </p>
                  </div>
                  <div className="footer-text">
                    <div className="app-icon">
                      <img src={and} className="app-img" alt="application" />

                      <img src={ios} className="app-img" alt="application" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                <div className="footer-widget footer-widget-2">
                  <div className="footer-widget-heading">
                    <h3>Find us</h3>
                  </div>
                  <div className="country_name">
                    <div className="footer-widget-logo">
                      <img src={india} className="p-img" alt="india logo" />
                      <h4>India</h4>
                    </div>
                    <div className="footer-widget-logo">
                      <img src={dubai} className="p-img" alt="Dubai logo" />
                      <h4>Dubai</h4>
                    </div>
                    <div className="footer-widget-logo">
                      <img src={germany} className="p-img" alt="Germany logo" />
                      <h4>Germany</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div className="footer-widget footer-widget-2">
                  <div className="footer-widget-heading">
                    <h3>Subscribe</h3>
                  </div>
                  <div className="footer-text mb-25">
                    <p>
                      Don’t miss to subscribe to our new feeds, kindly fill the
                      form below.
                    </p>
                  </div>
                  <div className="subscribe-form">
                    <div className="search">
                      <input
                        placeholder="Enter Email Address"
                        type="text"
                        name="subscriber"
                        value={isSubscribe.subscriber}
                        onChange={(e) => {
                          setSubscribe({
                            ...isSubscribe,
                            subscriber: e.target.value.toLowerCase(),
                          });

                          const emailRegex =
                            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                          setEmailIsValid(emailRegex.test(e.target.value));
                        }}
                      />
                      <button type="submit" onClick={handleSubmit}>
                        Subscribe
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}

        <div className="Logo_footer mb-2">
          <div>
            <div className="footer-social-icon p1-icon">
              <img src={iso} className="logo-img" alt="iso" />

              <img src={ISO_27} className="logo-img" alt="ISO 27" />

              <img src={GDPR} className="logo-img" alt="GDPR" />

              <img src={Microsoft} className="logo-img" alt="Microsoft" />
            </div>
          </div>

          <div className="footer-social-icon p2-icon">
            <img src={Marstercard} className="logo-img" alt="Marstercard" />
            <img src={Visa} className="logo-img" alt="Visa" />
            <img
              src={American_Express}
              className="logo-img"
              alt="American_Express"
            />
            <img src={Paypal} className="logo-img" alt="Paypal" />
          </div>
        </div>
        {/*  */}
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                <div className="copyright-text">
                  <p>Copyright &copy; 2023, All Right Reserved</p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <Link to="/privacy-policy"> Privacy policy</Link>
                    </li>
                    <li>
                      <Link to="/content-policy"> Content policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-of-use">Terms of Use</Link>
                    </li>
                    <li>
                      <Link to="/terms-conditions">Terms and conditions</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
