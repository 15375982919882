import { Joystick } from "react-joystick-component";
import "./Joystick.css";
import { playerTranslateAtom, walkWithJoystickAtom } from "../../../Files/Jotai";
import { useAtom } from "jotai";
import { useCallback } from "react";

const JoystickC = () => { 
  //jotai
  const [walkWithJoystickA, setWalkJoystickA] = useAtom(walkWithJoystickAtom);
  const [playerTranslateA, setPlayerTranslateA] = useAtom(playerTranslateAtom);

  const handleMove = (e) => {
    setWalkJoystickA(true);
    setPlayerTranslateA({ x: e.x, y: e.y, direction: e.direction });
  };
  const handleStop = (e) => {
    setWalkJoystickA(false);
    setPlayerTranslateA({ x: 0, y: 0, direction: e.direction });
  };
  return (
    <>
      <div className="joystick">
        <Joystick
          className="joystick"
          size={100}
          sticky={false}
          baseColor="0xffffff45"
          stickColor="white"
          move={handleMove}
          stop={handleStop}
        ></Joystick>
        {/* <div className="stick"></div> */}
      </div>
    </>
  );
};

export { JoystickC };
