import { Html } from "@react-three/drei"; 
import { useDispatch, useSelector } from "react-redux";  

const ScreenShareScreen = ({ ...props }) => {   
  let {imageScreen}=props; 
  const roomInfo = useSelector((store) => store.roomInfo);
  const dispatch = useDispatch(); 
  return ( 
    <Html
      zIndexRange={[201, 0]}
      layer={1}
      position={[imageScreen.p.x, imageScreen.p.y, imageScreen.p.z]}
      rotation={[imageScreen.r.x, imageScreen.r.y, imageScreen.r.z]}
      castShadow
      receiveShadow
      occlude="blending"
      distanceFactor={3.0}
      transform
    >
      <div
        className="imageScreenContainer"
        style={{
          height: imageScreen.height,
          width: imageScreen.width,
          userSelect: "none",
        }}
      >
        <div className="imageScreenContainerInside">
        <iframe
          allow="display-capture"
          title="embed"
          src={`https://digimetaverse.online?admin=${roomInfo.is_admin ? "true" : "false"
            }&room_name=rupamtest`}
          frameBorder={0}
          style={{
            height: imageScreen.height,
            width: imageScreen.width,
          }}
        />
        </div>
      </div>
    </Html>
  );
};
export { ScreenShareScreen };
