import React, { useState } from "react";
import { AvtarDetails } from "../../../common/mediaConstants";
import styles from "./EnterWorldPopup.module.css";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  setModalFor,
} from "../../../features/EnterWorldPopUp/EnterWorldSlice";
import { generateRandomUserInfo } from "../../../common/commonFunctions";
import {
  callDecodeToken,
  callUpdateAvtarDetails,
  callVisitorRegistration,
} from "../../../common/axiosCalls";
import {
  setAuthentication,
  setUserDetails,
} from "../../../features/Authentication/AuthenticationSlice";
import { setRoomInfo } from "../../../features/RoomInfo/RoomInfoDataSlice";
import { setToast } from "../../../features/ErrorHandling/ErrorHandlingSlice";
import useGetNameCookie from "../../../common/getNameCookie";

const EnterWorldPopup = (props) => {
  //Jotai
  const enterWorld = useSelector((store) => store.enterWorld);
  const authentication = useSelector((store) => store.authentication);
  const roomInfo = useSelector((store) => store.roomInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const defaultUserInfo = generateRandomUserInfo();
  const cookies = useGetNameCookie();

  const [formData, setFormData] = useState({
    avtar_name:
      enterWorld?.modalFor?.purpose === "edit_info"
        ? authentication?.userDetails?.avtar_name
        : enterWorld?.modalFor?.purpose === "update_info" ||
          enterWorld?.modalFor?.purpose === "edit_avtar"
        ? cookies?.avtar_name
        : defaultUserInfo.avtar_name,
    avtar_id:
      enterWorld?.modalFor?.purpose === "edit_info"
        ? authentication?.userDetails?.avtar_id
        : enterWorld?.modalFor?.purpose === "update_info" ||
          enterWorld?.modalFor?.purpose === "edit_avtar"
        ? cookies?.avtar_id
        : defaultUserInfo.avtar_id,
  });

  const goBackHandler = () => {
    if (enterWorld.modalFor.purpose === "edit_info") {
      dispatch(setModalFor(null));
    }
    dispatch(closeModal());
  };

  const inputChangeHandler = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        avtar_name: e.target.value,
      };
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!formData.avtar_name || !formData.avtar_id) {
      const message = formData.avtar_name
        ? "Select your avtar"
        : "Enter Avtar Name";

      dispatch(
        setToast({
          type: "error",
          message,
        })
      );

      return;
    }
    //Validation Ended

    if (enterWorld?.modalFor?.purpose === "R3F entry") {
      document.cookie = `avtar_name=${formData.avtar_name}; expires=${new Date(
        Date.now() + 1 * 1 * 60 * 60 * 1000
      ).toUTCString()}`;
      document.cookie = `avtar_id=${formData.avtar_id}; expires=${new Date(
        Date.now() + 1 * 1 * 60 * 60 * 1000
      ).toUTCString()}`;

      dispatch(closeModal());

      dispatch(
        setRoomInfo({
          avtar_id: formData.avtar_id,
          avtar_name: formData.avtar_name,
          room_type: "r3f_hall",
          room_name: "defaultmr",
          room_id: 0,
          is_admin: false,
          socket_name: formData.avtar_name,
          scene_name: "r3f_mr2",
        })
      );
      navigate("/room/r3f_mr2/defaultmr/0");
      return;
    }

    if (enterWorld?.modalFor.purpose === "edit_avtar") {
      document.cookie = `avtar_name=${formData.avtar_name}; expires=${new Date(
        Date.now() + 1 * 1 * 60 * 60 * 1000
      ).toUTCString()}`;
      document.cookie = `avtar_id=${formData.avtar_id}; expires=${new Date(
        Date.now() + 1 * 1 * 60 * 60 * 1000
      ).toUTCString()}`;

      dispatch(
        setRoomInfo({
          ...roomInfo,
          avtar_id: formData.avtar_id,
          avtar_name: formData.avtar_name,
        })
      );
      dispatch(closeModal());

      return;
    }

    if (enterWorld?.modalFor?.purpose === "edit_info") {
      try {
        const userDetails = await callUpdateAvtarDetails(formData);
        localStorage.setItem("token", userDetails.data.data);
        const decodeTokenData = await callDecodeToken();
        dispatch(setAuthentication(true));
        dispatch(setUserDetails(decodeTokenData.data.Data));
        dispatch(closeModal());
        dispatch(setModalFor(null));

        dispatch(
          setRoomInfo({
            ...roomInfo,
            avtar_id: formData.avtar_id,
            avtar_name: formData.avtar_name,
          })
        );

        dispatch(closeModal());
        dispatch(
          setModalFor({
            purpose: "",
            data: {},
          })
        );
        return;
      } catch (error) {
        console.log(error);
      }
      return;
    }

    document.cookie = `avtar_name=${formData.avtar_name}; expires=${new Date(
      Date.now() + 1 * 1 * 60 * 60 * 1000
    ).toUTCString()}`;
    document.cookie = `avtar_id=${formData.avtar_id}; expires=${new Date(
      Date.now() + 1 * 1 * 60 * 60 * 1000
    ).toUTCString()}`;

    if (enterWorld?.modalFor?.purpose === "update_info") {
      dispatch(closeModal());
      return;
    }

    if (enterWorld?.modalFor?.purpose === "meeting_update_info") {
      dispatch(closeModal());
      return;
    }

    if (enterWorld?.modalFor?.purpose === "turkey-visit") {
      navigate("/welcome-to-turkey");
      dispatch(closeModal());
      return;
    }

    if (enterWorld?.modalFor?.purpose === "night-club-visit") {
      navigate("/night-club");
      dispatch(closeModal());
      return;
    }

    if (enterWorld?.modalFor?.purpose === "create-room-modal") {
      dispatch(closeModal());
      navigate("/create-room");
      return;
    }

    if (!enterWorld.room_name) {
      dispatch(closeModal());
      navigate("/register");
      return;
    }

    callVisitorRegistration({
      room_name: enterWorld.room_name,
      avtar_name: formData.avtar_name,
      avtar_id: formData.avtar_id,
      ip_address: authentication.userIP,
    }).catch((err) => {
      console.log(err);
    });

    dispatch(
      setRoomInfo({
        avtar_id: formData.avtar_id,
        avtar_name: formData.avtar_name,
        room_type: enterWorld.room_type,
        room_name: enterWorld.room_name,
        room_id: enterWorld.room_id,
        is_admin: false,
      })
    );

   

    navigate(
      `/room/${enterWorld.room_type}/${enterWorld.room_name}/${enterWorld.room_id}`
    );
    dispatch(closeModal());
  };

  return (
    <>
      <form onSubmit={submitHandler}>
        <div className={styles["wrapper"]}>
          <div className={styles["back-icon"]}>
            <svg
              className={styles["back-icon_svg"]}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              onClick={goBackHandler}
            >
              <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
            </svg>
            <b className={styles["look_heading"]}>Select Your Look</b>
          </div>

          <input
            value={formData.avtar_name}
            onChange={inputChangeHandler}
          ></input>
          <div className={styles["avtar_wrapper"]} id={styles["style-3"]}>
            {AvtarDetails.map((item, i) => (
              <label key={i}>
                <input
                  type="radio"
                  name={"avatarId"}
                  value={item.value}
                  className={styles["formInputs"]}
                  checked={
                    item.value === formData?.avtar_id?.toString() ? true : false
                  }
                  onChange={() =>
                    setFormData((prevState) => {
                      return {
                        ...prevState,
                        avtar_id: item.value,
                      };
                    })
                  }
                />
                <div className={styles["avtarImage"]}>
                  <img src={item.img} alt="avtar"></img>
                </div>
              </label>
            ))}
          </div>
          <button className={styles["step_btn"]}>Submit</button>
        </div>
      </form>
    </>
  );
};

export default EnterWorldPopup;
