import React from "react";
import Header from "../../layouts/header/Header";
import Footer from "../../layouts/footer/Footer";
import "../policyCSS/policy.css";

const TermsAndConditions = () => {
  return (
    <>
      <Header />
      <section className="d-section position-relative" id="">
        <div className="container">
          <div className="row row-30">
            <div className="col-md-12 section-content">
              <h2 className="text-center title"> Terms & Conditions </h2>
            </div>
          </div>
          <div className="row row-30 ">
            <div className="section-content1">
              <p>
                <strong>Updated on : 14 February 2022.</strong>
              </p>
            </div>
            <div className="section-content1">
              <h5>
                <strong>1. Terms of Use</strong>
              </h5>

              <p>
                Access to and use of (https://www.worldbeyond.live/) is provided
                by CWW LTD on the terms and conditions set out below. By
                accessing and using the Website, you will be bound by these
                terms. Please note that these terms are subject to change from
                time to time, so should be checked regularly. Continued use of
                this Website will be deemed acceptance of the updated or amended
                terms and conditions.
              </p>
            </div>

            <div className="section-content1">
              <h5>
                <strong>2. Use of the Website</strong>
              </h5>
              <p>
                You acknowledge that you shall use the Website only for lawful
                purposes, for your own personal or internal business use.
              </p>
            </div>

            <div className="section-content1">
              <h5>
                <strong>3. Copyright</strong>
              </h5>
              <p>
                All copyright, trademarks, Logo, design rights, patents and
                other intellectual property rights on this Website are vested in
                CWW LTD or its licensors. All copyright and trademark notices in
                any original materials must be retained.
              </p>
              <p>
                Except for information related to your personal data (e.g.
                information provided in contact forms), you grant CWW LTD a
                worldwide, non-exclusive, royalty free license to use, copy,
                reproduce, process, modify, publish, transmit and display any
                content that you submit on the Website for any purposes.
              </p>
              <p>
                This Website may not be modified, disassembled, decompiled or
                reverse engineered in any way. No part of the Website may be
                reproduced without our express permission.
              </p>
            </div>

            <div className="section-content1">
              <h5>
                <strong>4. Availability </strong>
              </h5>
              <p>
                While CWW LTD strive to ensure that the availability of the
                Website remains high and maintain the continuity of the Website,
                the internet is not always a stable environment and CWW LTD
                shall not be liable if, for whatsoever reason, the Website is
                unavailable at any time. Access to the Website may be suspended
                temporarily and without notice in the case of system failure,
                maintenance, or repair or for reasons beyond CWW LTD control.
                CWW LTD shall not be held liable for any failure to provide or
                delay in us providing a service through this Website resulting
                from any occurrence beyond our reasonable control including
                (without limitation) strikes, break-down of systems or network
                access, fire, explosion or accident failure of any third-party
                telecommunications or service provider.
              </p>
            </div>

            <div className="section-content1">
              <h5>
                <strong>5. Permitted use </strong>
              </h5>
              <p>
                CWW LTD hereby grant you permission to access and use CWW LTD
                website, subject to the following conditions: Not to distribute
                any part or parts of the Website, including but not limited to
                any website content, in any medium without CWW LTD prior
                authorization; Not to after or modify any part of the Website;
                Not to disable or interfere with any security related features
                of the Website; Not to use Website in any way that is, or may be
                impacting user access to this Website; Not use, the Website for
                any purpose that is unlawful, defamatory, harassing, abusive,
                fraudulent, or obscene way or in any other inappropriate. Any
                breach of the above-mentioned conditions shall constitute a
                breach of the Website Terms & Conditions.
              </p>
            </div>

            <div className="section-content1">
              <h5>
                <strong>6. Breach of Website Terms & Conditions </strong>
              </h5>
              <p>
                If, CWW LTD consider that you have breached any of these Website
                acceptable use terms, CWW LTD may take such action that CWW LTD
                consider appropriate including, but not to be limited to:
                <ul className="ml-3">
                  <li>Issue a warning to you.</li>
                  <li>
                    Legal proceedings against you for reimbursement of all costs
                    on an indemnity basis resulting from the breach.
                  </li>
                  <li>
                    If you are a registered user immediate, temporary or
                    permanent withdrawal of your right to use our site.
                  </li>
                  <li>
                    Disclosure of such information to law enforcement
                    authorities as CWW LTD reasonably feel is necessary.
                  </li>
                </ul>
              </p>
              <p>
                CWW LTD exclude liability for actions taken in response to
                breaches of these Website acceptable use terms. The responses
                described above are not limited, and CWW LTD may take any other
                action CWW LTD reasonably deem appropriate.{" "}
              </p>
            </div>

            <div className="section-content1">
              <h5>
                <strong>7. Disclaimers </strong>
              </h5>
              <p>
                Your access to and use of the Website is at your own risk. The
                Website is provided on an 'as-is' basis. CWW LTD makes no
                warranties, representations or undertakings about any of the
                content of this Website. Where appropriate, your statutory
                rights are not affected by these terms. In particular, CWW LTD
                takes all necessary measures to protect our users from any
                malware or bugs or other items of a destructive nature however
                CWW LTD cannot warrant that this Website is free of malware or
                bugs and other items of a destructive nature. You are
                responsible for implementing sufficient procedures and virus
                checks (including anti-virus and other security checks) to
                satisfy requirements for the accuracy and security of the data
                input and output.
              </p>
              <p>
                CWW LTD shall not be liable for damages, losses (whether direct,
                indirect or consequential), expenses, liabilities, loss of
                profits or costs resulting from the use of, access to, or
                reliance upon the information given by its employees, agents or
                subcontractors in relation to, contained in or available
                through, its Website.
              </p>
            </div>

            <div className="section-content1">
              <h5>
                <strong>8. Links to and from Other Websites </strong>
              </h5>
              <p>
                In all cases where our website contains links to other websites
                and resources provided by third parties, these links are
                provided for your information only and do not represent an
                endorsement. We have no control over the contents of those
                websites or resources and, as such, make no warranties or
                assertions as to their content, nor does CWW LTD have any
                liability in connection with any of them (including, but not
                limited to, liability arising out of any allegation that the
                content of any third-party site infringes any law or the rights
                of any person or is obscene, defamatory or scandalous).
              </p>
            </div>

            <div className="section-content1">
              <h5>
                <strong>9. Personal data </strong>
              </h5>
              <p>
                CWW LTD will be processing your personal data according to the
                General Data Protection Regulation (“GDPR”) and additional data
                protection laws. To find out more about how CWW LTD use your
                personal data you can access our website Privacy Policy here: [{" "}
                <a
                  href="https://www.worldbeyond.live/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </a>{" "}
                ]
              </p>
            </div>

            <div className="section-content1">
              <h5>
                <strong>10. Law and Jurisdiction </strong>
              </h5>
              <p>
                This agreement and any dispute arising out of it will be
                governed by Indian law. Any dispute or claim arising out of or
                in connection with this agreement or its formation (including
                non-contractual disputes or claims) shall be subject to the
                exclusive jurisdiction of the courts of Pune Only.
              </p>
            </div>

            <div className="section-content1">
              <h5>
                <strong>11. Contact Us</strong>
              </h5>
              <p>
                You can contact us at:{" "}
                <a href="mailto:hello@worldbeyond.live">
                  {" "}
                  hello@worldbeyond.live
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
