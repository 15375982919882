import React from 'react'
import './ErrorPage.css'
import { useNavigate } from 'react-router'

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
   <>
   <div class="wrapper">
  <div class="landing-page">
    
    
    <h1 className='error-title'> 404 Error.</h1>
    <p className='error-msg'> We can't find the page you're looking for.</p>
    <button className='error-btn' onClick={() => navigate('/')}>Back to home</button>
  </div>
</div>
   
   </>
  )
}

export default ErrorPage