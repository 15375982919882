import React, { useState } from 'react'
import { JoystickC } from '../Common/joystick/JoystickC'
import InstructionComponent from '../InstructionComponent/InstructionComponent'
import RoomFooterMenu from '../../../Web/components/room/RoomFooterMenu'; 

const OtherHtml = ({ isModelLoadedA, sceneName }) => {
  const [focus, setFocus] = useState();

  if (!isModelLoadedA) {
    return null
  }
  return (
    <>
      <RoomFooterMenu setFocus={setFocus} />
      {/* <SmallMap sceneName={sceneName}/> */}
      <JoystickC />
      {/* <InstructionComponent /> */}
    </>
  )
}

export { OtherHtml }