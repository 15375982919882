import React, { useState } from "react";
import "./Header.css";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { generateRandomUserInfo } from "../../common/commonFunctions";
import { setRoomInfo } from "../../features/RoomInfo/RoomInfoDataSlice";
import { emptyLikesData } from "../../features/UserLikes/UserLikesSlice";
import { CloseBars, MenuBars } from "../../common/svgIcon";
import {
  callEnterMetaBtnClick,
  callLogout,
  callVisitorRegistration,
} from "../../common/axiosCalls";
import logo from "../../assets/logo/logo-white.svg";
import RoomProfile from "../../components/room/roomProfile/RoomProfile";
import useGetNameCookie from "../../common/getNameCookie";
import {
  setAuthentication,
  setUserDetails,
} from "../../features/Authentication/AuthenticationSlice";
import {
  openModal,
  setModalFor,
} from "../../features/EnterWorldPopUp/EnterWorldSlice";

function Header({ isMeetingRoom, setIsOpen ,forRoom }) {
  const cookies = useGetNameCookie();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authentication = useSelector((store) => store.authentication);
  const [isMobile, setIsMobile] = useState(false);
  const [showProfileInNav, setShowProfileInNav] = useState(false);

  const diveInHandler = async () => {
    const userInfo = generateRandomUserInfo();

    document.cookie = `avtar_name=${userInfo.avtar_name}; expires=${new Date(
      Date.now() + 1 * 1 * 60 * 60 * 1000
    ).toUTCString()}`;
    document.cookie = `avtar_id=${userInfo.avtar_id}; expires=${new Date(
      Date.now() + 1 * 1 * 60 * 60 * 1000
    ).toUTCString()}`;

    dispatch(
      setRoomInfo({
        avtar_id: userInfo.avtar_id,
        avtar_name: userInfo.avtar_name,
        room_type: "r3f_mr2",
        room_name: "",
        socket_name: userInfo.avtar_name,
        scene_name: "r3f_mr2",
        is_admin: false,
        socket_name: userInfo.avtar_name,
      })
    );
    setIsOpen(true);
  };
  const logoutHandler = () => {
    document.cookie = `avtar_name=avtarName; expires=${new Date(
      2000,
      0,
      1
    ).toUTCString()}`;
    document.cookie = `avtar_id=15; expires=${new Date(
      2000,
      0,
      1
    ).toUTCString()}`;
    document.cookie = `interest_intent_email_id=fake; expires=${new Date(
      2000,
      0,
      1
    ).toUTCString()}`;

    callLogout()
      .then((response) => {
        localStorage.removeItem("token");
        dispatch(setAuthentication(false));
        dispatch(setUserDetails(null));
        dispatch(emptyLikesData());
      })
      .then(() => {
        navigate("/");
      });

    setShowProfileInNav(false);
  };

  const showProfileHandler = () => {
    setShowProfileInNav(!showProfileInNav);
    setIsMobile(false);
  };

  const enterMetaverseHandler = () => {
    callEnterMetaBtnClick();
    if (forRoom === "meeting-room") {
      diveInHandler();
      return;
    }
    if (!cookies) {
      dispatch(
        setModalFor({
          purpose: "R3F entry",
          data: {},
        })
      );
      dispatch(openModal());
    } else {
      navigate("/room/r3f_mr2/defaultmr/0");
    }
  };

  return (
    <>
      <nav className="navbar">
        <div className="logo logo_link" onClick={() => navigate("/")}>
          <img src={logo} className="logo" alt="logo" />
        </div>

        <ul
          className={
            isMobile ? "nav-links-mobile " : "nav-links-mobile nav-links "
          }
        >
          <li>
            <div
              onClick={() => navigate("/explore-metaverse")}
              className="home"
              title="Explore Metaverse"
            >
              Explore Metaverse
            </div>
          </li>
          {!isMeetingRoom && (
            <li>
              <div
                title="About World Beyond"
                onClick={() => navigate("/about")}
                className="Services"
              >
                About
              </div>
            </li>
          )}

          {/* <li>
            <div title="Pricing" onClick={() => navigate("/Pricing")} className="about">
              Pricing
            </div>
          </li> */}
          {!authentication.isAuthenticated && (
            <li>
              <button
                title="Sign in"
                className="login-btn1 sign"
                onClick={() => navigate("/login")}
              >
                Sign in
              </button>
            </li>
          )}
          {!authentication.isAuthenticated && (
            <li className="list_sign">
              <button
                title="Create Your Metaverse it`s Free"
                className="login-btn Contact"
                onClick={enterMetaverseHandler}
              >
                {!isMeetingRoom ? " Try for free" : "Create Meeting"}
              </button>
            </li>
          )}
          {authentication.isAuthenticated && (
            <li className="list_sign">
              <button
                title="Profile"
                className="login-btn Contact"
                onClick={showProfileHandler}
              >
                Profile
              </button>
            </li>
          )}
        </ul>
        <button
          title="Menu"
          className="mobile-menu-icon"
          onClick={() => {
            setIsMobile(!isMobile);
            setShowProfileInNav(null);
          }}
        >
          {isMobile ? <CloseBars /> : <MenuBars />}
        </button>
      </nav>
      {showProfileInNav && (
        <RoomProfile
          logoutHandler={logoutHandler}
          outSideRoom={true}
          setShowProfileInNav={setShowProfileInNav}
        />
      )}
    </>
  );
}

export default Header;
