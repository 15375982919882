import { sceneObject } from "../../../Files/CommonVariables";
/*-----------------sit-------------------*/
const sittingMaterialNameArray = {
  [sceneObject["mr"]]: [
    "conferance seat",
    "chair7",
    "LH_chair_1.001",
    "LH_chair_2.001",
    "RH_chair1",
    "RH_chair2",
    "Sofa_Seat",
  ],
  [sceneObject["mr2"]]: [
    "Seat"
  ],
  [sceneObject["mr3"]]: ["Seat"],
  [sceneObject["dr"]]: [],
  [sceneObject["school"]]: [],
  [sceneObject["office1"]]: [],
  [sceneObject["showroom"]]: ["Seat"],
  [sceneObject["showroom2"]]: [],
  [sceneObject["hr"]]: [],
  [sceneObject["office2"]]: [""],
  [sceneObject["turkey"]]: [
    "Blue_Leather_Seat",
    "Red_Leather_Seat",
    "Meeting_Chair",
    "arm_Chair_G",
    "arm_Chair_B",
    "arm_Chair_DB",
    "Sofa_Seat_Trance",
  ],
  [sceneObject["hall"]]: [],
  [sceneObject["hall2"]]: [],
  [sceneObject["tourism"]]: [],

};

/*-----------------walk / teleport-------------------*/
const walkMaterialNameArray = {
  [sceneObject["mr"]]: [
    "Main_Base",
    "MultiMat_2",
    "Round_base",
    "RH_wall1",
    "LH_wall.001",
    "MB_base",
    "Hallipad_Base",
    "stair_plane_02",
  ],
  [sceneObject["mr2"]]: [
    "Floor",
    "Steps",
    "Floor02",
    "Carpet",
    "Grass.jpg",
    "01 - Default",
    "Sofa_Seating_Carpet",
    "Ground.001",
    "Auditorium_Rounds_Wood",
    "Auditorium_Rounds",
    "Auditorium_Grass",
    "24 - Default.001",
    "Base_Circle02",
    "Base_Circle01",
    "Base_Circle",
    "Stone",
    "Born_Fire_Base",
    "Leaf_01_Base",
    "Hidden_Path",
  ],
  [sceneObject["mr3"]]: [
    "main_Paving",
    "base_Grass.001",
    "Prpule_Velvet",
    "Graas_outer_border",
    "Graas",
    "base_White"
  ],
  [sceneObject["dr"]]: [
    "Base",
    "Fire_Step02",
    "Fire_Step01",
    "Road",
    "Mountain_Sand",
    "Bridge",
    "Pool",
    "Mountain_Sand",
  ],
  [sceneObject["school"]]: [
    "Paving_Texture.jpg",
    "Gray Pavement _.jpg",
    "Football_01.jpg",
    "Exrernal_Stears_1",
    "floor",
    "carpet_001",
    "Parking.jpg",
    "Material #2146958247",
    "Floor_Reception_Beach_Marble",
    "Grass.jpg",
    "caroetBrown.jpg",
  ],
  [sceneObject["office1"]]: [
    "118",
    "SiteWork.Planting.Grass.StAugustine1",
    "Bad_Grass",
    "landscape paving",
    "floor",
    "Sencer",
    "Parking copy",
    "Marble_Carrara_003",
  ],
  [sceneObject["showroom"]]: [
    "Material #60",
    "Material #58",
    "Material #55",
    "Material #53",
    "Material #59",
    "Material #61",
    "Material #79",
    "carpet dark gray.jpg",
  ],
  [sceneObject["showroom2"]]: ["landscape paving.jpg.001","Paving_Texture","Material #130","Material #116","floor_marble"],
  [sceneObject["hr"]]: ["GRASS", "Road", "main_middle"],
  [sceneObject["office2"]]: [
    "Grass",
    "Road",
    "Carpet",
    "Parking",
    "tile_marble",
    "Material #256",
    "marble_texture",
    "auditorium_Carpet",
    "3d66Model-1068468-files-37",
    "Sitework.Paving - Surfacing.Pavers.Interlocking",
  ],
  [sceneObject["turkey"]]: [
    "vray_Road",
    "vray_Paving",
    "Road_Paving",
    "vray_Carpet",
    "vray_Grass01",
    "Center_Roof_Base",
    "vray_Center_Paving02",
    "Center_Paving01",
    "Center_Paving03",
  ],
  [sceneObject["hall"]]: ['floor tiles cloth01.jpg', '3d66Model-1068468-files-118.jpg', "Marble paving outdoor hexagonal texture seamless 05985.jpg", "download (1).jpg", "Cooncrete", "Cooncrete", "Zig_zag", "Material #2146957826","carpet dark red.jpg","24 - Default"],
  [sceneObject["hall2"]]: ["Concrete.jpg","24 - Default"],
  [sceneObject["tourism"]]: ["terain.001","road Tex.001"],

};
/*-----------------tree-------------------*/
const treeMaterialNameArray = {
  [sceneObject["mr"]]: [],
  [sceneObject["mr2"]]: [
    "tronco",
    "foglia 1",
    "foglia 2",
    "grass_high_quality_color.jpg",
    "Coconut_Tree_Leaf01",
    "Coconut_Tree_Bark01",
  ],
  [sceneObject["hr"]]: ["Bark","Leaves","color_part","Leaves_purple","Leaves_pink"],
  [sceneObject["mr3"]]: [
    "tronco",
    "foglia 1",
    "foglia 2",
    "grass_high_quality_color.jpg",
    "Coconut_Tree_Leaf01",
    "Coconut_Tree_Bark01",

  ],
  [sceneObject["dr"]]: [
    "Banana_Tree_Leaf_02",
    "Banana_Tree_Bark_02",
    "Tree_Leaf_01",
    "erger5h5e5hiogr4h89g4h84gg4",
    "Tree_Bark_01",
    "Show_Tree03",
    "small_Tree_03",
    "vray_Areca palm2.002",
    "Banana_Tree_Bark_01",
    "Coconut_Tree_Bark01",
    "vray_Areca palm.002",
    "hut",
  ],
  [sceneObject["school"]]: [
    ""
  ],
  [sceneObject["office1"]]: [
    "Material.001",
    "Tree"
  ],
  [sceneObject["showroom"]]: ["Material #201", "Material #200"],
  [sceneObject["showroom2"]]: ["tree_10","tree_07","tree_09","tree_06"], 
  [sceneObject["office2"]]: ["Material.001", "Material #0"],
  [sceneObject["turkey"]]: [
    "Tree_Leaf_01",
    "Tree_Leaf_02",
    "Tree_Bark_01",
    "Material #135",
    "Hoding_Screen",
    "Dustbeen",
    "Hall_Glass.001",
    "Hellogen_Polle",
    "holding",
    "vray_Sculpter_02",
    "Material #46405",
    "Material #46404",
    "Material #46403",
    "METAL",
    "Material #139",
    "Hoding_Border",
    "02 - Default",
    "pole_Circle_Sculpture_02",
    "Material #48836",
    "Pipe_Circle_Sculptre",
    "holding.003",
    "hording.003",
    "Hording_Border"
  ],
  [sceneObject["hall"]]: [],
  [sceneObject["hall2"]]: [],
  [sceneObject["tourism"]]: ["tree3.001","tree_1.001","tree5.001","tree8.001","tree7.001","tree6.001"],

};
/*------------------do not walk------------------*/
const doNotWalkMaterialArray = {
  [sceneObject["mr"]]: [],
  [sceneObject["mr2"]]: [
    "water",
    "tronco",
    "foglia 1",
    "foglia 2",
    "grass_high_quality_color.jpg",
    "Coconut_Tree_Leaf01",
    "Coconut_Tree_Bark01",
  ],
  [sceneObject["mr3"]]: [
    "Dinning_Chair_Wood.002",
    "Gazebo",
    "Dinning_Chair_Cution.001"
  ],
  [sceneObject["dr"]]: [],
  [sceneObject["school"]]: [],
  [sceneObject["office1"]]: [],
  [sceneObject["showroom"]]: [],
  [sceneObject["showroom2"]]: [],
  [sceneObject["hr"]]: ["Bark101","Bark104","Leaves105"],
  [sceneObject["office2"]]: [],
  [sceneObject["turkey"]]: [
    "Tree_Leaf_01",
    "Tree_Leaf_02",
    "Tree_Bark_01"
  ],
  [sceneObject["hall"]]: [],
  [sceneObject["hall2"]]: [],
  [sceneObject["tourism"]]: [],

}
/*-----------------transparent-------------------*/
const transparentMaterialNameArray = {
  [sceneObject["mr"]]: [
    "seat_01",
    "seat_02",
    "seat_03",
    "stair_plane_02",
    "stair plane_01",
    "stair plane_01.001",
    "screen2",
  ],
  [sceneObject["mr2"]]: [
    "seat_01",
    "seat_02",
    "seat_03",
    "stair_plane_02",
    "stair plane_01",
    "stair plane_01.001",
    "screen2",
  ],
  [sceneObject["mr3"]]: [
    "seat_01",
    "seat_02",
    "seat_03",
    "stair_plane_02",
    "stair plane_01",
    "stair plane_01.001",
    "screen2",
  ],
  [sceneObject["dr"]]: [],
  [sceneObject["school"]]: [],
  [sceneObject["office1"]]: [""],
  [sceneObject["showroom"]]: ["16 - Matte Plastic", "collider", "Rectangle001"],
  [sceneObject["showroom2"]]: [],
  [sceneObject["hr"]]: ["COLLAIDER", "STOPER_UP"],
  [sceneObject["office2"]]: [""],
  [sceneObject["turkey"]]: [],
  [sceneObject["hall"]]: ["door_base"],
  [sceneObject["hall2"]]: [],
  [sceneObject["tourism"]]: [],

};

/*-----------------glass-------------------*/
const glassMaterialNameArray = {
  [sceneObject["mr"]]: [
    "Glass_Wall",
    "Glass1",
    "Material #133.001",
    "Glass",
    "Gate",
  ],
  [sceneObject["mr2"]]: [
    "Glass_Wall",
  ],
  [sceneObject["mr3"]]: [
    "Glass_Wall",
  ],
  [sceneObject["dr"]]: [],
  [sceneObject["school"]]: ["glass", "Glass", "Principle_Back_Shelf_glass"],
  [sceneObject["office1"]]: [
    "Translucent_Glass_Blue1",
    "Translucent_Glass_Blue2",
    "Translucent__Glass_Blue3",
  ],
  [sceneObject["showroom"]]: [
    "Material #62",
    "Material #25",
    "Material #68",
    "Material #67",
  ],
  [sceneObject["showroom2"]]: ["outer_glass","vray_window glass","divider_glass"],
  [sceneObject["hr"]]: ["vray_glass"],
  [sceneObject["office2"]]: [
    "1st_floor_glass",
    "2nd_floor_glass",
    "glass",
    "glass_frosted",
    "Material #2146958051",
  ],
  [sceneObject["turkey"]]: [
    "glass",
    "vray_Glasss",
    "D1_table",
    "Material #46360",
    "tunnel",
    "Material #46358",
  ],
  [sceneObject["hall"]]: ["door_glass"],
  [sceneObject["hall2"]]: ["glass1"],
  [sceneObject["tourism"]]: [],

};

export {
  sittingMaterialNameArray,
  walkMaterialNameArray,
  glassMaterialNameArray,
  transparentMaterialNameArray,
  treeMaterialNameArray,
  doNotWalkMaterialArray
};
