import * as THREE from "three";
import { useEffect, useRef, useState } from "react";
import "./AllHtmlScreens.css";
import { ImageScreen } from "./ImageScreen";
import { useSelector } from "react-redux";
import { useThree } from "@react-three/fiber";
import { VideoScreen } from "./VideoScreen";
import { ScreenShareScreen } from "./ScreenShareScreen";

const ImageScreenC = ({ item }) => {
  const sourceRef = useRef();

  return <>
    <group>
      <mesh ref={sourceRef} position={[item.p.x, item.p.y, item.p.z]} rotation={[item.r.x, item.r.y, item.r.z]} scale={[1, 1, 0.01]}>
        <planeGeometry args={[item.height, item.width]} />
        <meshStandardMaterial color={'blue'} side={THREE.DoubleSide} />
      </mesh>
    </group>
  </>
}
const DynamicAllHtmlScreen = () => {
  const { scene: worldScene } = useThree()
  const roomInfo = useSelector((store) => store.roomInfo);
  const [imageArray, setImageArray] = useState({});
  const [videoArray, setVideoArray] = useState({});
  const [screenShareArray, setScreenShareArray] = useState({});

  let imageScreenCube, videoScreenCube, screenShareScreenCube;

  const callBannerPositions = (imageScreenCube, setMethod) => {
    const updatedImageArray = {};
    imageScreenCube &&
      imageScreenCube.children.map((item, index) => {
        item.material.opacity = 0,
          item.material.transparent = true,

          updatedImageArray[index] = {
            p: {
              x: item.position.x,
              y: item.position.y,
              z: item.position.z,
            },
            r: {
              x: item.rotation.x,
              y: item.rotation.y,
              z: item.rotation.z,
            },
            width: item.scale.x * 267 + "px",
            height: item.scale.z * 267 + "px",
            name: "s" + (index + 1),
          };
      })
    setMethod(updatedImageArray);
  }
  useEffect(() => {
    imageScreenCube = worldScene.getObjectByName('imageScreen');
    imageScreenCube && callBannerPositions(imageScreenCube, setImageArray);
    videoScreenCube = worldScene.getObjectByName('videoScreen');
    videoScreenCube && callBannerPositions(videoScreenCube, setVideoArray);
    screenShareScreenCube = worldScene.getObjectByName('screenShareScreen');
    screenShareScreenCube && callBannerPositions(screenShareScreenCube, setScreenShareArray);
  }, [])

  return <>
    {
      Object.values(imageArray).map((item, index) => {
        return <ImageScreen key={index} imageScreen={item} item={item} />
      })
    }
    {
      Object.values(videoArray).map((item, index) => {
        return <VideoScreen key={index} imageScreen={item} item={item} />
      })
    }
    {
      Object.values(screenShareArray).map((item, index) => {
        return <ScreenShareScreen key={index} imageScreen={item} item={item} />
      })
    }
  </>
}

export { DynamicAllHtmlScreen };
