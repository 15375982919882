import React, { useState } from "react";
import "./Pricingregistration.css";
import { callPricingResgistration } from "../../../common/axiosCalls";
import { useDispatch } from "react-redux";
import { setToast } from "../../../features/ErrorHandling/ErrorHandlingSlice";

const Pricingregistration = ({ setOpen, isopen }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company_name: "",
    phone: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await callPricingResgistration({
        ...formData,
        category: isopen,
      });

      dispatch(setToast({
        type: 'success',
        message: 'Your From has been submitted successfully !'
      }));
    } catch (error) {
      console.log(error);
    }
    setOpen(null);
  };

  return (
 <div className="main_pricing">
     <div className="form-container">
      <div className="svg_wrap" onClick={() => setOpen(null)}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <path d="M12 4.5v15m7.5-7.5h-15" />
        </svg>
      </div>
      <h2 className="form-title">Register Now</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-section">
          <div className="form-input-group">
            <label htmlFor="name" className="label_data">
              Name:
            </label>
            <input
              className="input_pricing"
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>

          <div className="form-input-group">
            <label htmlFor="email" className="label_data">
              Email:
            </label>
            <input
              className="input_pricing"
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-section">
          <div className="form-input-group">
            <label htmlFor="phone" className="label_data">
              Phone:
            </label>
            <input
              type="tel"
              className="input_pricing"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <div className="form-input-group">
            <label htmlFor="companyName" className="label_data">
              Company Name:
            </label>
            <input
              type="text"
              className="input_pricing"
              id="company_name"
              name="company_name"
              value={formData.company_name}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-section1">
          <button title="submit" type="submit" className="submit-btn">
            Submit
          </button>
        </div>
      </form>
    </div>
 </div>
  );
};

export default Pricingregistration;
