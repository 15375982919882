import { useEffect } from "react"; 
import { remoteUser } from "../../voice/voice";
import { useAtom } from "jotai";
import {
  adminDataAtom,
  allPlayer,
  otherPlayerListAtom,
  voiceAtom,
  voiceIdArrayAtom,
} from "../../Files/Jotai";
import { useSelector } from "react-redux";

export const socket = io(process.env.REACT_APP_SOCKET_PATH_PLESK_SAAS);
export const socketAdmin = io(process.env.REACT_APP_SOCKET_ADMIN_PATH_PLESK_SAAS);

export const SocketManager = () => {
  //Jotai
  const [allPlayerM, setAllPlayerM] = useAtom(allPlayer);
  const [otherPlayerListA, setOtherPlayerListA] = useAtom(otherPlayerListAtom);
  const [adminDataA, setAdminDataA] = useAtom(adminDataAtom);
  const [voiceRadiusA] = useAtom(voiceAtom);
  const [voiceIdArrayA, setVoiceIdArrayA] = useAtom(voiceIdArrayAtom);
  const {
    avtar_id: avtarId,
    socket_name: socketName,
    room_name: roomName,
    scene_name: sceneName,
  } = useSelector((store) => store.roomInfo);
  useEffect(() => {
    const onConnect = () => {
      // createMyPlayer();
    };

    /*------------------addMeToOther------------------*/
    const addMeToOther = (data) => { 
      setAllPlayerM((prevState) => {
        return {
          ...prevState,
          [data.socketName]: data,
        };
      });
      setOtherPlayerListA((prevState) => {
        return {
          ...prevState,
          [data.socketName]: data,
        };
      });
    };

    /*------------------getAllPlayer------------------*/
    const getAllPlayer = (data) => { 
      if (socketName) {
        //add players including me - setAllPlayerM
        setAllPlayerM(data);
        let data2 = data;
        //add players excluding me - setOtherPlayerListA
        delete data2[socketName];
        setOtherPlayerListA(data2);
      }
    };
    /*------------------updatePlayer------------------*/
    let tempUpdateObj = {};
    const calculateDistance = (vector1, vector2) => {
      const dx = vector2.x - vector1.x;
      const dy = vector2.y - vector1.y;
      const dz = vector2.z - vector1.z;

      const distance = Math.sqrt(dx * dx + dy * dy + dz * dz);
      return distance;
    };
    const updatePlayer = (data) => {
      setAllPlayerM((prevState) => {
        // tempUpdateObj = {
        //   ...prevState,
        // };
        return {
          ...prevState,
          [data.socketName]: data,
        };
      });
      // if (Object.keys(tempUpdateObj).length && remoteUser[data.voiceId]) {
      //   let distance = calculateDistance(
      //     tempUpdateObj[socketName].position,
      //     data.position
      //   );
      //   if (distance < voiceRadiusA) {
      //     if (!remoteUser[data.voiceId]._played) {
      //       remoteUser[data.voiceId].play();
      //     }
      //   } else {
      //     if (remoteUser[data.voiceId]._played) {
      //       remoteUser[data.voiceId].stop();
      //     }
      //   }
      // }
    };

    /*------------------teleport------------------*/
    const onSittingMove = (data) => {
      setAllPlayerM((prevState) => {
        return {
          ...prevState,
          [data.socketName]: data,
        };
      });
    };

    const teleportMove = (data) => {
      setAllPlayerM((prevState) => {
        return {
          ...prevState,
          [data.socketName]: data,
        };
      });
    };

    /*------------------voice------------------*/
    const addMyVoiceId = (data) => {
      setVoiceIdArrayA(data);
    };
    const voiceMuteUmnute = (data) => {
      if (data.socketName !== socketName) {
        if (data.isMuted) {
          remoteUser[data.voiceId].stop();
        } else {
          remoteUser[data.voiceId].play();
        }
      }
    };
    const allVoiceMute = (data) => {
      if (remoteUser) {
        Object.values(remoteUser).map((user) => {
          if (!user) return;
          data.isAllMuted ? user.stop() : user.play();
        });
        //for admin
        remoteUser[data.adminVoiceId] && remoteUser[data.adminVoiceId].play();
      }
    };
    /*------------------deleteMyPlayer------------------*/
    //admin
    const removePlayer = (data) => {
      setAllPlayerM((prevState) => {
        prevState[data.socketName].isActive = false;
        return {
          ...prevState,
        };
      });
      setOtherPlayerListA((prevState) => {
        prevState[data.socketName].isActive = false;
        return {
          ...prevState,
        };
      });
      setVoiceIdArrayA((prevState) => {
        delete prevState[data.socketName];
        return {
          ...prevState,
        };
      });
    };

    //user
    const deleteMyPlayer = (data) => {
      if (data.socketName === socketName) return;
      setAllPlayerM((prevState) => {
        //isActive false - prevent component from rerendering
        prevState[data.socketName].isActive = false;
        return {
          ...prevState,
        };
      });
      setOtherPlayerListA((prevState) => {
        prevState[data.socketName].isActive = false;
        return {
          ...prevState,
        };
      });
      setVoiceIdArrayA((prevState) => {
        delete prevState[data.socketName];
        return {
          ...prevState,
        };
      });
    };
    /*-----------------admin-------------------*/
    const adminData = (data) => {
      setAdminDataA(data);
    };

    socket.on("connect", onConnect);
    socket.on("addMeToOther", addMeToOther);
    socket.on("getAllPlayer", getAllPlayer);
    socket.on("updatePlayer", updatePlayer);
    socket.on("sittingMove", onSittingMove);
    socket.on("teleportMove", teleportMove);
    socket.on("deleteMyPlayer", deleteMyPlayer);
    
    socket.on("addMyVoiceId", addMyVoiceId);
    socket.on("voiceMuteUmnute", voiceMuteUmnute);
    socket.on("allVoiceMute", allVoiceMute);
    socket.on("removePlayer", removePlayer);
    socketAdmin.on("adminData", adminData);

    return () => {
      socket.off("connect", onConnect);
      socket.off("sittingMove", onSittingMove);
      socket.off("teleportMove", teleportMove);
      socket.off("getAllPlayer", getAllPlayer);
      socket.off("addMeToOther", addMeToOther);
      socket.off("updatePlayer", updatePlayer);
      socket.off("addMyVoiceId", addMyVoiceId);
      socket.off("voiceMuteUmnute", voiceMuteUmnute);
      socket.off("allVoiceMute", allVoiceMute);
      socket.off("removePlayer", removePlayer);
      socket.off("deleteMyPlayer", deleteMyPlayer);
      socketAdmin.off("adminData", adminData);
    };
  }, [socketName]);
};
