import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import "./ChatSideBar.css";
import axios from "axios";
import { avatars } from "../../../../common/mediaConstants";
import {
  LIVE_ROOM_VISITOR,
  VISITOR_CHAT_GET,
  VISITOR_CHAT_POST,
} from "../../../../utils/api";
import { useNavigate } from "react-router";

const ChatSideBar = ({ isOpen, setIsOpen, room_name, isDefaultRoom }) => {
  const [liveRoomVisiters, setLiveRoomVisitors] = useState([]);
  const [isChatting, setIsChatting] = useState(false);
  const messageRef = useRef(null);

  const [toUser, setToUser] = useState();
  const [toUserName, setToUserName] = useState("");

  const [msg, setMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);

  const authentication = useSelector((store) => store.authentication);
  const navigate = useNavigate();
  useEffect(() => {
    if (isDefaultRoom) {
      navigate("/login", {
        state: {
          isRoomLoggedIn: null,
        },
      });
    } else if (!authentication.isAuthenticated) {
      navigate("/login", {
        state: {
          isRoomLoggedIn: true,
        },
      });
    } else {
      axios
        .post(
          LIVE_ROOM_VISITOR,
          { room_name: room_name },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",

              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setLiveRoomVisitors(response.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isChatting, authentication]);

  useEffect(() => {
    const divElement = messageRef?.current;
    setTimeout(() => {
      if (divElement) {
        divElement.scrollTop = divElement?.scrollHeight;
      }
    }, 500);

    let interval;
    if (isChatting) {
      interval = setInterval(() => {
        axios
          .post(
            VISITOR_CHAT_GET,
            { to_user_id: toUser },
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",

                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            setChatMessages(response.data.data);
          });
      }, 2000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isChatting]);

  const userChatHandler = (id, avtar_name) => {
    axios
      .post(
        VISITOR_CHAT_GET,
        { to_user_id: id },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",

            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setChatMessages(response.data.data);
      })
      .catch((error) => console.log(error));
    setIsChatting(true);
    const divElement = messageRef?.current;
    if (divElement) {
      divElement.scrollTop = divElement?.scrollHeight;
    }

    setToUser(id);
    setToUserName(avtar_name);
  };

  const setMessageHandler = (e) => {
    setMessage(e.target.value);
  };

  const sendMessageHandler = (message) => {
    axios
      .post(
        VISITOR_CHAT_POST,
        { to_user_id: toUser, chat_message: msg },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",

            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setChatMessages(response.data.data);
      })
      .then(() => {
        setMessage("");
      });
  };

  const SidebarClassName = `sidebar ${isOpen ? "sidebarVisible" : ""}`;
  console.log(liveRoomVisiters.length);

  return (
    <>
      <div className={SidebarClassName}>
        {!isChatting ? (
          <div className="quiz-window">
            <div className="quiz-window-body">
              <div className="gui-window-awards">
                <ul className="guiz-awards-row guiz-awards-header">
                  <li className="guiz-awards-header-star">Profile</li>
                  <li className="guiz-awards-header-title">Live Users</li>
                  <li className="guiz-awards-header-track">Action</li>
                  <button
                    className="chat-close-button"
                    onClick={() => {
                      setIsOpen(null);
                    }}
                  >
                    <svg
                      className="close-button-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                  </button>
                </ul>

                {liveRoomVisiters.length > 1 ? (
                  liveRoomVisiters
                    .filter(
                      (item, i) =>
                        item.avtar_name !==
                        authentication?.userDetails?.avtar_name
                    )
                    .map((item, i) => (
                      <ul
                        className="guiz-awards-row guiz-awards-row-even"
                        key={i}
                      >
                        <li className="guiz-awards-star">
                          <img
                            src={avatars[item.avtar_id - 1]}
                            className="face-img"
                            alt="avatar"
                          />
                        </li>
                        <li className="guiz-awards-title">{item.avtar_name}</li>
                        <li className="guiz-awards-title2">
                          &nbsp; &nbsp;
                          <i
                            className="material-icons nav__icon chat-icon"
                            onClick={() =>
                              userChatHandler(item.id, item.avtar_name)
                            }
                          >
                            chat
                          </i>
                        </li>
                      </ul>
                    ))
                ) : (
                  <p className="NoliveVisiter">No live user.</p>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="chathead">
              <h1>{toUserName}</h1>
              <span onClick={() => setIsChatting(false)} className="back-btn">
                Back
              </span>
            </div>
            <div
              ref={messageRef}
              className="messages live-user-box scrollbar"
              id="style-4"
            >
              {chatMessages &&
                chatMessages.map((item, i) => (
                  <div
                    className={
                      toUser === item.from_user_id
                        ? "from_chat_message"
                        : "my_chat_message "
                    }
                  >
                    <p>{item.chat_message}</p>
                  </div>
                ))}
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                sendMessageHandler(msg);
              }}
              className="c-i-b"
            >
              <input
                type="text"
                value={msg}
                onChange={setMessageHandler}
                className="chatinput"
                placeholder="Enter message"
              />
              <button type="submit" className="c-btn">
                Send
              </button>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatSideBar;
