import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callCreateVisitList } from "../../common/axiosCalls";
import { setPostcardData } from "../../features/PaginationData/PaginationDataSlice";
import styles from "./pagination.module.css";
import PaginationNumber from "./PaginationNumber";
import SliderButton from "./SliderButton";
import moreIcon from ".././../assets/images/Icons/more.png";
import { useState } from "react";

const Pagination = () => {
  const pagination = useSelector((store) => store.pagination);
  const dispatch = useDispatch();
  const [middleNumberTransform, setMiddleNumberTransform] = useState({
    transform: "translateX(0px)",
  });
  const [advancedPagination, setAdvancedPagination] = useState(true);
  const [minState, setMinState] = useState(false);
  const [maxState, setMaxState] = useState(false);

  let pages = [];
  for (let i = 3; i <= pagination.totalPages - 2; i++) {
    pages.push(i);
  }

  let smallPages = [];

  for (let i = 1; i <= pagination.totalPages; i++) {
    smallPages.push(i);
  }

  useEffect(() => {
    if (pagination.totalPages <= 9) {
      setAdvancedPagination(false);
      let smallPages = [];
      for (let i = 1; i <= pagination.totalPages; i++) {
        smallPages.push(i);
      }
      return;
    }

    setAdvancedPagination(true);

    if (pagination.pageNo <= 6) {
      setMiddleNumberTransform({
        transform: "translateX(0px)",
      });
      setMinState(true);
      setMaxState(false);
    } else if (
      pagination.pageNo > 6 &&
      pagination.pageNo < pagination.totalPages - 4
    ) {
      setMiddleNumberTransform({
        transform: `translateX(-${(pagination.pageNo - 5) * (25 + 15)}px)`,
      });
      setMinState(false);
    } else if (pagination.pageNo >= pagination.totalPages - 5) {
      setMiddleNumberTransform({
        transform: `translateX(-${(pagination.totalPages - 9) * (25 + 15)}px)`,
      });
      setMaxState(true);
      setMinState(false);
    } else {
      console.log("case default");
    }
  }, [pagination.pageNo, pagination.totalPages]);

  useEffect(() => {
    callCreateVisitList({
      room_type: pagination.currentCategory,
      page: pagination.pageNo,
    })
      .then((res) => {
        dispatch(
          setPostcardData({
            totalPages: Math.ceil(+res.data.category_count.total_count / 12),
            postCards: res.data.data,
          })
        );
      })
      .catch((err) => {
        dispatch(
          setPostcardData({
            totalPages: null,
            postCards: null,
          })
        );
      });
  }, [pagination.currentCategory, pagination.pageNo]);

  return (
    <>
      <div className={styles["wrapper"]}>
        <SliderButton type="decrease">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className={styles["slider_icon"]}
          >
            <path d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
          </svg>
        </SliderButton>

        {advancedPagination && pagination?.totalPages >= 9 && (
          <PaginationNumber value={1}>1</PaginationNumber>
        )}
        {advancedPagination && minState && (
          <PaginationNumber value={2}>2</PaginationNumber>
        )}

        {/* {pagination?.totalPages >= 7 && (
          <div className={styles["moreIcon"]}>
            <img src={moreIcon} alt="moreicon" />
          </div>
        )} */}
        {advancedPagination && !minState && (
          <div className={styles["moreIcon"]}>
            <img src={moreIcon} alt="moreicon" />
          </div>
        )}

        {advancedPagination && pagination?.totalPages >= 9 && (
          <div className={styles["middleNumbersWrap"]}>
            <div
              className={styles["middleNumbers"]}
              style={middleNumberTransform}
            >
              {pages.map((page, i) => {
                return (
                  <PaginationNumber key={i} value={page}>
                    {page}
                  </PaginationNumber>
                );
              })}
            </div>
          </div>
        )}

        {pagination?.totalPages <= 9 &&
          smallPages.map((page, i) => {
            return (
              <PaginationNumber key={i} value={page}>
                {page}
              </PaginationNumber>
            );
          })}

        {/* {pagination?.totalPages >= 7 && (
          <div className={styles["moreIcon"]}>
            <img src={moreIcon} alt="moreicon" />
          </div>
        )} */}
        {advancedPagination && !maxState && (
          <div className={styles["moreIcon"]}>
            <img src={moreIcon} alt="moreicon" />
          </div>
        )}

        {advancedPagination && maxState && (
          <PaginationNumber value={pagination.totalPages - 1}>
            {pagination.totalPages - 1}
          </PaginationNumber>
        )}

        {advancedPagination && pagination?.totalPages >= 7 && (
          <PaginationNumber value={pagination.totalPages}>
            {pagination.totalPages}
          </PaginationNumber>
        )}

        <SliderButton type="increase">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className={styles["slider_icon"]}
          >
            <path d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
          </svg>
        </SliderButton>
      </div>
    </>
  );
};

export default Pagination;
