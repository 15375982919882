import React, { useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../../../layouts/header/Header";
import styles from "./CreateRoom.module.css";
import RegistrationPopup from "./RegistrationPopup/RegistrationPopup";
import MetaverseNamePopup from "./MetaverseNamePopup/MetaverseNamePopup";
import {WorldTypeData} from '../../../../common/mediaConstants'

const CreateRoom = () => {
  const authentication = useSelector((store) => store.authentication);
  const [showRegistrationPopup, setShowRegistrationPopup] = useState(false);
  const [showMetaNamePopup, setShowMetaNamePopup] = useState(null);
  const [formData, setFormData] = useState({
    roomType: false,
  });

  return (
    <>
      <Header />
      {showRegistrationPopup && (
        <RegistrationPopup
          show={showRegistrationPopup}
          setShow={setShowRegistrationPopup}
          roomType={formData.roomType}
        />
      )}
      <div className="body_wrapper">
        <div className="container meta-box">
          <p className={styles["RC_heading_1"]}>Select Your Metaverse</p>

            <div className="row meta_height" id="style-3">
              {WorldTypeData.map((item, i) => {
                return (
                  <div
                    className="col-lg-3 col-xl-3 col-md-4 col-sm-6 col-6"
                    key={i}
                    style={{ position: "relative" }}
                  >
                    <label className="radio-img">
                      <input
                        className={styles["RC_input"]}
                        type="radio"
                        value={item.world_type}
                        name={"roomType"}
                        onChange={() => {
                          setFormData({ roomType: item.world_type });
                        }}
                        onClick={() => {
                          authentication.isAuthenticated ? setShowMetaNamePopup(item.world_type) : setShowRegistrationPopup(true);
                        }}
                      />
                      <div className={styles["metaverse_image"]}>
                        <picture>
                          <img
                            style={{ height: "100%", width: "100%" }}
                            src={item.img}
                            alt="metaverse"
                          />
                        </picture>
                        <figcaption>
                          <img
                            style={{ height: "100%", width: "100%" }}
                            src={item.img}
                            alt="metaverse"
                          />
                          <section className="adaptive-glass">
                            <h3>{item.title}</h3>
                          </section>
                        </figcaption>
                      </div>
                    </label>
                    {showMetaNamePopup === item.world_type && <MetaverseNamePopup room_type={showMetaNamePopup}/>}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
    </>
  );
};

export default CreateRoom;
