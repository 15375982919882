import * as THREE from "three";
import { useRef, useState, useEffect } from "react";
import { useThree } from "@react-three/fiber";
import {
  useGLTF,
  useAnimations,
  useProgress,
} from "@react-three/drei";
THREE.ColorManagement.enabled = false;
import { socket } from "../socketManager/SocketManager";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import {
  isModelLoadedAtom,
  showSittingIndicatorAtom,
  sittingObjectAtom,
  teleportPositionAtom,
} from "../../Files/Jotai";
import { useAtom } from "jotai";
import { UpdateMaterial } from "../different/updateMaterial/UpdateMaterial";
import {
  sittingMaterialNameArray,
  walkMaterialNameArray,
} from "../different/updateMaterial/UpdateMaterialArray";
import { useSelector } from "react-redux";
import { dotPath } from "../../Files/CommonVariables";
import { maleIdArray } from "../../Files/CommonVariables";
import { Sitting } from "../different/sitting/Sitting";
import UpdateMesh from "../different/updateMaterial/updateMesh";
import { allMeshArray, tempEnv } from "../experience/Experience";
import TeleportRing from "./TeleportRing";
// import { MeshBVHVisualizer } from "three-mesh-bvh";

function LoadModel({ assetName }) {
  const {
    avtar_id: avtarId,
    socket_name: socketName,
    room_name: roomName,
    scene_name: sceneName,
  } = useSelector((store) => store.roomInfo);

  //scene
  const { scene: threejsScene } = useThree();

  //Jotai hooks
  const [, setTeleportPositionA] = useAtom(teleportPositionAtom);
  const [, setSittingObjectA] = useAtom(sittingObjectAtom);
  const [showSittingIndicatorA, setShowSittingIndicatorA] = useAtom(
    showSittingIndicatorAtom
  );
  //state
  const [onFloor, setOnFloor] = useState(false);
  const [isModelLoadedA, setIsModelLoadedA] = useAtom(isModelLoadedAtom);

  //ref
  const ringRef = useRef();

  // progress
  const { progress } = useProgress();
  //let
  let sittingType = "Sitting";
  /*------------------scene------------------*/
  const { scene, nodes, materials, animations } = useGLTF(
    `${dotPath}/R3F/asset/${assetName}/a1.glb`,
    (loader) => {
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath("./draco/");
      loader.setDRACOLoader(dracoLoader);
    }
  );
  /*------------------scene ani------------------*/
  const { actions } = useAnimations(animations, scene);
  useEffect(() => {
    actions &&
      Object.keys(actions).map((action) => {
        actions[action].play();
      });

    scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;
        child.material.specularColor = new THREE.Color(0x111111);
        child.material.specularColorMap = null;
        child.material.specularIntensityMap = null;
        child.material.specularIntensity = 0;
        allMeshArray.push(child);
      }
    });
    UpdateMaterial(allMeshArray, sceneName);
    UpdateMesh(threejsScene, allMeshArray, sceneName);

    // if (progress === 100) {
    //   setIsSiteLoaded(true);
    //   setShowSittingIndicatorA(true);
    // }
  }, []);

  useEffect(() => {
    setIsModelLoadedA(true)
  }, [])

  // useEffect(() => {
  // }, [isModelLoadedA])


  const teleportMoveM = (event) => {
    event.stopPropagation();  
    let moveType = "direct";
    if (walkMaterialNameArray[sceneName].includes(event.object.material.name)) {
      let teleportPositionPoint = event.point;
      let teleportPosition = {
        x: teleportPositionPoint.x,
        y: teleportPositionPoint.y + 1.0,
        z: teleportPositionPoint.z,
      };
      //myside
      setTeleportPositionA({ position: teleportPosition, moveType: moveType });
      //sending event to otherside
      socket.emit("teleportMove", {
        roomName: roomName,
        socketName: socketName,
        animationName: "Idle",
        position: teleportPosition,
        moveType: moveType,
      });
    }
  };
  const sittingMoveM = (event) => {
    event.stopPropagation();
    if (
      sittingMaterialNameArray[sceneName].includes(event.object.material.name)
    ) {
      let seatPosition = {
        x: event.object.position.x,
        y: event.object.position.y + 0.6,
        z: event.object.position.z,
      };
      if (!maleIdArray.includes(avtarId)) {
        sittingType = "Sitting_female";
      }
      setSittingObjectA({
        position: seatPosition,
        rotation: event.object.rotation,
        animationName: sittingType,
      });
      setShowSittingIndicatorA(false);
      socket.emit("sittingMove", {
        avtarId: avtarId,
        direction: "",
        socketName: socketName,
        roomName: roomName,
        animationName: sittingType,
        position: seatPosition,
        rotation: event.object.rotation,
        socketId: socket.id,
        moveType: "normal",
      });
      setTimeout(() => {
        setSittingObjectA({});
      }, 100);
    }
  };
  const startTeleportRing = (event) => {
    event.stopPropagation();
    if (walkMaterialNameArray[sceneName].includes(event.object.material.name)) {
      ringRef.current.position.copy(event.point);
      ringRef.current.position.y += 0.1;
    }
  };

  return (
    <>
      {showSittingIndicatorA && <Sitting />}
      <TeleportRing name="teleportRing" ringRef={ringRef} />
      <group name="modelGroup" renderOrder={1}>
        <primitive
          name="worldModel"
          // ref={sceneModelRef}
          object={scene}
          onClick={sittingMoveM}
          onDoubleClick={teleportMoveM}
          onPointerMove={startTeleportRing}
        ></primitive>
        {/* <mesh
          onClick={sittingMoveM}
          onDoubleClick={teleportMoveM}
          onPointerEnter={() => setOnFloor(true)}
          onPointerLeave={() => setOnFloor(false)}
          onPointerMove={startTeleportRing}
          rotation-x={-Math.PI / 2}
          position-y={-0.001}
        >
          <planeGeometry args={[100, 100]} />
          <meshStandardMaterial color="#f0f0f0" />
        </mesh> */}
      </group>
    </>
  );
}

export { LoadModel };
