import { useState } from "react";
import "./VoiceList.css";
import { remoteUser } from "../../../voice/voice";
import { socket } from "../../socketManager/SocketManager";
import { voiceIdArrayAtom } from "../../../Files/Jotai";
import { useAtom } from "jotai";
import { useSelector } from "react-redux";

const VoiceList = ({ isModelLoadedA, isAdmin }) => {
  if (!isModelLoadedA) {
    return null
  }
  if (!isAdmin) {
    return null
  }
  const {
    is_admin: isadmin,
    avtar_id: avtarId,
    socket_name: socketName,
    room_name: roomName,
    scene_name: sceneName,
  } = useSelector((store) => store.roomInfo);
  //Jotai
  const [voiceIdArrayA, setVoiceIdArrayA] = useAtom(voiceIdArrayAtom);

  const [isAllMuted, setIsAllMuted] = useState(false);

  const muteUnmuteM = (personData) => {
    if (remoteUser[personData.voiceId]._played) {
      socket.emit("voiceMuteUmnute", {
        isMuted: true,
        socketName: personData.socketName,
        voiceId: personData.voiceId,
        roomName: personData.roomName,
      });
      remoteUser[personData.voiceId].stop();
      setVoiceIdArrayA((prevState) => {
        prevState[personData.socketName].isMuted = true;
        return {
          ...prevState,
        };
      });
    } else {
      socket.emit("voiceMuteUmnute", {
        isMuted: false,
        socketName: personData.socketName,
        voiceId: personData.voiceId,
        roomName: personData.roomName,
      });
      remoteUser[personData.voiceId].play();
      setVoiceIdArrayA((prevState) => {
        prevState[personData.socketName].isMuted = false;
        return {
          ...prevState,
        };
      });
    }
  };
  const allVoiceMuteM = (status) => {
    socket.emit("allVoiceMute", {
      isAllMuted: status,
      roomName: roomName,
      socketName: socketName,
      adminVoiceId: voiceIdArrayA[socketName].voiceId,
    });
    setIsAllMuted(status);
    setVoiceIdArrayA((prevState) => {
      Object.values(prevState).map((item) => (item.isMuted = status));
      return {
        ...prevState,
      };
    });
    Object.values(remoteUser).map((user) => {
      if (!user) {
        return;
      }
      status ? user.stop() : user.play();
    });
  };
  
  return (
    <>
      <div className="voiceListContainer">
        {/* <div className="allVoice">
          <button
            className={isAllMuted ? "mutedBackground" : "unmutedBackground"}
            onClick={() => allVoiceMuteM(!isAllMuted)}
          >
            {isAllMuted ? "Unmute All" : "Mute All"}
          </button>
        </div> */}
        {Object.values(voiceIdArrayA).map((item, index) => {
          if (item.socketName === socketName) {
            return;
          }
          return (
            <>
              <div key={index} className="voiceListPerson">
                <button
                  className={
                    item.isMuted ? "mutedBackground" : "unmutedBackground"
                  }
                  onClick={() => muteUnmuteM(item)}
                >
                  {item.isMuted ? "Unmute" : "Mute"}
                </button>
                <div className="voiceListPersonName">{item.socketName}</div>
              </div>
              <div className="divider"></div>
            </>
          );
        })}
      </div>
    </>
  );
};
export { VoiceList };
