import {  useGLTF } from "@react-three/drei";
import { dotPath } from "../../Files/CommonVariables";
import { useEffect } from "react";
import { useAtom } from "jotai";
import { allAnimationsAtom } from "../../Files/Jotai";

let allAnimations = [];
const LoadAnimations = () => {
  //jotai
  const [allAnimationsA, setAllAnimationA] = useAtom(allAnimationsAtom)
  let idleAni, walking, running, sittingAni, sittingAni_female, walking_female, handRaiseAni, handShakeAni;

  idleAni = useGLTF(`${dotPath}/R3F/animations/Idle.glb`);
  walking = useGLTF(`${dotPath}/R3F/animations/Walking.glb`);
  running = useGLTF(`${dotPath}/R3F/animations/Running.glb`);
  walking_female = useGLTF(`${dotPath}/R3F/animations/Walking_female.glb`);
  sittingAni = useGLTF(`${dotPath}/R3F/animations/Sitting.glb`);
  sittingAni_female = useGLTF(`${dotPath}/R3F/animations/Sitting_female.glb`);
  handRaiseAni = useGLTF(`${dotPath}/R3F/animations/Hand_Raise.glb`);
  handShakeAni = useGLTF(`${dotPath}/R3F/animations/Hand_Shake.glb`);
  useEffect(() => {
    idleAni.animations[0].name = "Idle";
    walking.animations[0].name = "Walking";
    running.animations[0].name = "Running";
    walking_female.animations[0].name = "Walking_female";
    sittingAni.animations[0].name = "Sitting";
    sittingAni_female.animations[0].name = "Sitting_female";
    handRaiseAni.animations[0].name = "HandRaise";
    handShakeAni.animations[0].name = "HandShake";

    setAllAnimationA([
      idleAni.animations[0],
      walking.animations[0],
      running.animations[0],
      walking_female.animations[0],
      sittingAni.animations[0],
      sittingAni_female.animations[0],
      handRaiseAni.animations[0],
      handShakeAni.animations[0],
    ]);
  }, [])
  return null;
};

export { LoadAnimations, allAnimations };
