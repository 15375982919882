import React from 'react'
import './MeetAbout.css'

const MeetingRoomCTA = ({ diveInHandler }) => {
  return (
    <>
      <section className="lawyer-cta-section">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="cta-text">
                <h3>
               <span className="download-btn">Unlock your</span> free metaverse meeting room by registering today!
                </h3>
                <button onClick={diveInHandler} className="lawyer-theme-btn-s2">Create Meeting Room</button>

              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default MeetingRoomCTA