import { useSelector } from "react-redux";
import { myPlayerLandPosition } from "../different/PlayerPositions";
import { socket } from "./SocketManager";

const useCreateMyPlayer = () => {
  const {
    avtar_id: avtarId,
    socket_name: socketName,
    room_name: roomName,
    scene_name: sceneName,
  } = useSelector((store) => store.roomInfo);

  let myData = {
    avtarId: avtarId,
    socketId: socket.id,
    roomName: roomName,
    socketName: socketName,
    animationName: "Idle",
    direction: null,
    position: myPlayerLandPosition[sceneName],
    rotation: null,
    voiceId: null,
    moveType: "direct",
    isActive: true,
  };
  return () => {
    if (socketName && roomName) { 
      socket.emit("addMeToOther", myData);
    }
  };
};

export { useCreateMyPlayer };
