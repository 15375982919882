import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { uid } from "uid";

const initialState = {
  toastArray: [
    // {
    //   type: "error",
    //   message:
    //     "Thank you for your valuable feedback. World Beyond team will contact you soon.",
    // },
    // {
    //   type: "success",
    //   message:
    //     "Thank you for your valuable feedback. World Beyond team will contact you soon.",
    // },
    // {
    //   type: "success",
    //   message: "Rupam is a React Developer",
    // },
  ],
};

export const setToast = createAsyncThunk(
  "errorHandlingSlice/setToast",
  async (data, thunkAPI) => {
    const id = uid();
    thunkAPI.dispatch(
      displayToast({ type: data.type, message: data.message, id: id })
    );
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        thunkAPI.dispatch(deleteToast(id));
      }, 5000);
    });
  }
);

const ErrorHandlingSlice = createSlice({
  name: "errorHandlingSlice",
  initialState,
  reducers: {
    displayToast: (state, action) => {
      state.toastArray = [
        ...state.toastArray,
        {
          id: action.payload.id,
          type: action.payload.type,
          message: action.payload.message,
        },
      ];
    },

    deleteToast: (state, action) => {
      state.toastArray = state.toastArray.filter(
        (toast) => toast.id !== action.payload
      );
    },
  },
  extraReducers: {
    [setToast.fulfilled]: (state, action) => {},
  },
});
export const { displayToast, deleteToast } = ErrorHandlingSlice.actions;

export default ErrorHandlingSlice.reducer;
