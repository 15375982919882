import React from "react";
import { MeetBenefits } from "../../../../common/mediaConstants";
import Styles from '../features/Features.module.css'
import img1 from "../../../../assets/images/MeetingRoom/Benefits.jpg";

const Benefits = () => {
  return (
    <>
      <div className={Styles["card-wrapper"]}>
        <div className={Styles["features-img"]}>
          <img src={img1} alt="" />
        </div>
        <div className={Styles["text-section-wrap"]}>
          {/* <p className={Styles["meet-title"]}>""</p> */}
          <div className={Styles["meet-list"]}>
            {MeetBenefits.map((feature) => {
              return (
                <div className={Styles["text-list"]}>
                  <div className={Styles["list-bullet"]}></div>
                  <p className="">{feature}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Benefits;
