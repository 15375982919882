const adjective = [
  "Able",
  "Acid",
  "Avid",
  "Bald",
  "Bold",
  "Calm",
  "Cold",
  "Cool",
  "Cute",
  "Dark",
  "Deep",
  "Dull",
  "Easy",
  "Evil",
  "Fair",
  "Fast",
  "Firm",
  "Flat",
  "Free",
  "Full",
  "Good",
  "Great",
  "Grey",
  "Hard",
  "High",
  "Huge",
  "Idle",
  "Juicy",
  "Just",
  "Kind",
  "Lame",
  "Last",
  "Late",
  "Lean",
  "Lush",
  "Mild",
  "Neat",
  "Nice",
  "Odd",
  "Open",
  "Pale",
  "Pink",
  "Pure",
  "Rich",
  "Ripe",
  "Safe",
  "Salt",
  "Scar",
  "Soft",
  "Sole",
  "Sore",
  "Sour",
  "Tall",
  "Tame",
  "Tiny",
  "True",
  "Ugly",
  "Vain",
  "Vast",
  "Weak",
  "Wide",
  "Wise",
  "Wood",
  "Worn",
  "Akin",
  "Bony",
  "Brag",
  "Calf",
  "Calm",
  "Cane",
  "Clan",
  "Damp",
  "Dark",
  "Dear",
  "Duel",
  "Each",
  "Epic",
  "Eyed",
  "Felt",
  "Foul",
  "Fuzz",
  "Gory",
  "Gush",
  "Half",
  "Hazy",
  "Jolt",
  "Keen",
  "Lank",
  "Leap",
  "Leek",
  "Lush",
  "Mate",
  "Mere",
  "Moss",
  "Near",
  "Numb",
  "Oily",
  "Puff",
  "Quir",
  "Rare"
];

 
const noun = [
  "Zara",
  "Kian",
  "Liam",
  "Maya",
  "Alec",
  "Nova",
  "Leon",
  "Luna",
  "Cole",
  "Jade",
  "Finn",
  "Iris",
  "Owen",
  "Zoey",
  "Evan",
  "Lana",
  "Gage",
  "Nola",
  "Jace",
  "Mira",
  "Aria",
  "Ezra",
  "Kira",
  "Axel",
  "Ruby",
  "Zane",
  "Emma",
  "Noah",
  "Lily",
  "Mia",
  "Max",
  "Zoe",
  "Finn",
  "Ella",
  "Jack",
  "Maya",
  "Leo",
  "Nia",
  "Seth",
  "Ivy",
  "Luke",
  "Nora",
  "Alex",
  "Jade",
  "Cole",
  "Ruby",
  "Drew",
  "Luna",
  "Jake",
  "Gwen",
  "Evan",
  "Lucy",
  "Ryan",
  "Ada",
  "Owen",
  "Zoe",
  "Troy",
  "Mira",
  "Leon",
  "June",
  "Kyle",
  "Lexi",
  "Noah",
  "Cara",
  "Alex",
  "Jade",
  "Reed",
  "Zara",
  "Kobe",
  "Milo",
  "Nina",
  "Zack",
  "Gina",
  "Liam",
  "Cleo",
  "Kai",
  "Mara",
  "Zion",
  "Ella",
  "Nico",
  "Isla",
  "Jude",
  "Aria",
  "Zeke",
  "Nora",
  "Kian",
  "Mira",
  "Evan",
  "Lara",
  "Knox",
  "Ruby",
  "Cole",
  "Zara",
];

export const generateRandomUserInfo = () => {
  return {
    avtar_name: `${
      adjective[Math.ceil(Math.random() * (adjective.length - 1))]
    } ${noun[Math.ceil(Math.random() * (noun.length - 1))]}`,
    avtar_id: Math.ceil(Math.random() * 20).toString(),
  };
};
