import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import img12 from "../../../assets/images/screen/new/USA.jpg";
import img11 from "../../../assets/images/screen/new/Germnay.jpg";
import img5 from "../../../assets/images/screen/new/Tourism.jpg";
import img13 from "../../../assets/images/screen/new/India.jpg";
import img14 from "../../../assets/images/screen/new/Theater.jpg";
import img2 from "../../../assets/images/screen/new/G-town.jpg";
import img10 from "../../../assets/images/screen/new/P-Town.jpg";
import img7 from "../../../assets/images/screen/new/Showroom.jpg";
import img1 from "../../../assets/images/screen/new/Exhibition.jpg";
import img8 from "../../../assets/images/screen/new/Trade_show.jpg";
import img9 from "../../../assets/images/screen/new/Workspace.jpg";
import img3 from "../../../assets/images/screen/new/Classroom.jpg";
import img6 from "../../../assets/images/screen/new/Office.jpg";
import img15 from "../../../assets/images/screen/new/uae.png";
import img16 from "../../../assets/images/screen/new/uae.png";
import { useNavigate } from "react-router";

import Header from "../../../layouts/header/Header";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./RoomCreationNew.module.css";
import { callCreateRoom } from "../../../common/axiosCalls";
import { setRoomInfo } from "../../../features/RoomInfo/RoomInfoDataSlice";

const data = [
  {
    img: img15,
    name: "UAE",
    screenname: "UAE",
    value: "uae",
  },
  {
    img: img16,
    name: "HR",
    screenname: "HR",
    value: "hr",
  },
  {
    img: img12,
    name: "screen",
    screenname: "USA",
    value: "usa",
  },
  {
    img: img11,
    name: "screen",
    screenname: "Germany",
    value: "germany",
  },

  {
    img: img5,
    name: "screen",
    screenname: "Tourism",
    value: "tourism",
  },
  {
    img: img14,
    name: "screen",
    screenname: "Theater",
    value: "theatre",
  },
  {
    img: img13,
    name: "screen",
    screenname: "India",
    value: "india",
  },
  {
    img: img2,
    name: "screen",
    screenname: "G Town",
    value: "gic1",
  },
  {
    img: img10,
    name: "screen",
    screenname: "P Town",
    value: "gic2",
  },
  {
    img: img7,
    name: "screen",
    screenname: "Showroom",
    value: "showroom",
  },

  {
    img: img1,
    name: "screen",
    screenname: "Exhibition",
    value: "hall",
  },
  {
    img: img8,
    name: "screen",
    screenname: "Trade Show",
    value: "hall2",
  },
  {
    img: img6,
    name: "screen",
    screenname: "Office",
    value: "office",
  },
  {
    img: img9,
    name: "screen",
    screenname: "Workspace",
    value: "office2",
  },
  {
    img: img3,
    name: "screen",
    screenname: "Education",
    value: "school",
  },

  // {
  //   img: img4,
  //   name: "screen",
  //   screenname: "Conference",
  //   value: "conference",
  // },
];

const sliderSetting = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  responsive: [
    {
      breakpoint: 500,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
      },
    },
  ],
};

const RoomCreation = () => {
  const authentication = useSelector((store) => store.authentication);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [validation, setValidation] = useState({
    metaverseNameIsValid: null,
    metaverseIsValid: null,
  });

  const [formData, setFormData] = useState({
    metaverseName: "",
    roomType: false,
  });

  const submitHandler = (e) => {
    e.preventDefault();

    if (!formData.metaverseName || !formData.roomType) {
      if (!formData.metaverseName) {
        setValidation((prevState) => {
          return {
            ...prevState,
            metaverseNameIsValid: false,
          };
        });
      } else {
        setValidation((prevState) => {
          return {
            ...prevState,
            metaverseNameIsValid: true,
          };
        });
      }

      if (!formData.roomType) {
        setValidation((prevState) => {
          return {
            ...prevState,
            metaverseIsValid: false,
          };
        });
      } else {
        setValidation((prevState) => {
          return {
            ...prevState,
            metaverseIsValid: true,
          };
        });
      }

      return;
    } else {
      setValidation({
        metaverseNameIsValid: true,
        metaverseIsValid: true,
      });
    }

    if (authentication.isAuthenticated) {
      callCreateRoom({
        room_name: formData.metaverseName,
        room_type: formData.roomType,
      }).then(() => {
        dispatch(
          setRoomInfo({
            avtar_id: authentication.userDetails.avtar_id,
            avtar_name: authentication.userDetails.avtar_name,
            room_type: formData.roomType,
            room_name: formData.metaverseName.replaceAll("%20", " "),
            is_admin: true,
          })
        );

        navigate(`/room/${formData.roomType}/${formData.metaverseName}`);
      });
    } else {
      navigate("/register", { state: formData });
    }
  };

  const changeHandler = (e) => {
    setFormData({
      ...formData,
      metaverseName: e.target.value,
    });
  };

  let metaverseNameDisplay =
    validation.metaverseNameIsValid === false ? "visible" : "hidden";
  let metaverseDisplay =
    validation.metaverseIsValid === false ? "visible" : "hidden";

  return (
    <>
      <Header />
      <form onSubmit={submitHandler}>
        <div className="body_wrapper">
          <div className="container meta-box">
            <p className={styles["RC_heading_1"]}>Select Your Metaverse</p>

            <div className="row meta_height" id="style-3">
              {data.map((item, i) => {
                return (
                  <div className="col-md-3 col-6" key={i}>
                    <label className="radio-img">
                      <input
                        className={styles["RC_input"]}
                        type="radio"
                        value={item.value}
                        name={"roomType"}
                        onChange={() =>
                          setFormData({ ...formData, roomType: item.value })
                        }
                      />
                      <div className={styles["metaverse_image"]}>
                        <picture>
                          <img
                            style={{ height: "100%", width: "100%" }}
                            src={item.img}
                            alt="metaverse"
                          />
                        </picture>
                        <figcaption>
                          <img
                            style={{ height: "100%", width: "100%" }}
                            src={item.img}
                            alt="metaverse"
                          />
                          <section className="adaptive-glass">
                            <h3>{item.screenname}</h3>
                          </section>
                        </figcaption>
                      </div>
                    </label>
                  </div>
                );
              })}
            </div>

            <div className="input_div">
              <input
                value={formData.metaverseName}
                className="room_creation_input-m"
                type="text"
                placeholder="Enter Metaverse Name"
                onChange={changeHandler}
                required
              ></input>

              <button title="Next" type="submit" className="step_btn">
                Next
              </button>
            </div>

            <p
              className="RC_validation_warning"
              style={{ visibility: metaverseNameDisplay }}
            >
              Please enter world name !
            </p>
            <p
              className="RC_validation_warning"
              style={{ visibility: metaverseDisplay }}
            >
              Please select your space !
            </p>
          </div>
        </div>
      </form>
    </>
  );
};

export default RoomCreation;
