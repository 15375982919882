import React from "react";
import Styles from "../features/Features.module.css";
import { meetAbout } from "../../../../common/mediaConstants";
import img1 from '../../../../assets/images/MeetingRoom/About.png'

const MeetAbout = ({diveInHandler}) => {

  return (
    <>
      <div className={Styles["card-wrapper"]}>
        <div className={Styles["features-img"]}>
          <img className={Styles["about-img"]}
            src={img1}
            alt=""
          />
        </div>

        <div className={Styles["text-section-about"]}>
          <h2 className={Styles["meet-title"]}>Explore Metaverse Where Innovation meets Imagination </h2>
          <h3>
          Immerse, Innovate, and Create in the Metaverse 
          </h3>

          <div className={Styles["meet-list"]}>
        {meetAbout.map((feature) => {
          return (
            <div className={Styles["text-list"]}>
              <div className={Styles["list-bullet"]}></div>
              <p className="">{feature}</p>
            </div>
          );
        })}
      </div>

      
          <button className={`${Styles["meeting-reg-btn"]} ms-4` } onClick={diveInHandler}>Create Meeting Room</button>
        </div>
      </div>

   
    </>
  );
};

export default MeetAbout;
