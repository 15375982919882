import "./Shortcut.css";
import { socket } from "../../socketManager/SocketManager";
import { 
  infoPopupAtom, 
  myAnimationAtom,
  takeScreenshotAtom,
} from "../../../Files/Jotai";
import { useAtom } from "jotai";
import { useSelector } from "react-redux";
const Shortcut = () => {
  const {
    avtar_id: avtarId,
    socket_name: socketName,
    room_name: roomName,
    scene_name: sceneName,
  } = useSelector((store) => store.roomInfo);
  //Jotai
  const [myAnimationA, setMyAnimationA] = useAtom(myAnimationAtom);
  const [takeScreenshotA, setTakeScreenshotA] = useAtom(takeScreenshotAtom);
  const [infoPopupA, setInfoPopupA] = useAtom(infoPopupAtom);

  const setAnimationNameM = (animationName) => {
    setMyAnimationA(animationName);
    socket.emit("otherActivity", {
      roomName: roomName,
      socketName: socketName,
      animationName: animationName,
    });
  };

  const takeScreenshotM = () => {
    setTakeScreenshotA(true);
  };

  /*-----------------fullscreen-------------------*/
  const fullscreenM = () => {
    const fullscreenElement =
      document.fullscreenElement || document.webkitFullscreenElement;
    let canvas = document.getElementById("root");
    if (!fullscreenElement) {
      if (canvas.requestFullscreen) {
        canvas.requestFullscreen();
      } else if (canvas.webkitRequestFullscreen) {
        canvas.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  };
  /*-----------------info popup-------------------*/
  const openInfoPopupM = () => {
    setInfoPopupA(!infoPopupA);
  };
  return (
    <>
      <div className="screenButtonContainer">
        <div
          className="screenButton"
          onClick={() => setAnimationNameM("HandRaise")}
        >
          <i className="fas fa-hand-paper w-500"></i>
        </div>
        <div
          className="screenButton"
          onClick={() => setAnimationNameM("HandShake")}
        >
          <i className="fas fa-handshake w-500"></i>
        </div>
        <div className="screenButton screenshot" onClick={takeScreenshotM}>
          <i className="fas fa-camera w-700"></i>
        </div>
        <div className="screenButton" onClick={fullscreenM}>
          <i className="fas fa-expand"></i>
        </div>
        <div className="screenButton selfmute">
          <i className="fas fa-microphone"></i>
        </div>
        <div className="screenButton" onClick={openInfoPopupM}>
          <i className="fas fa-info"></i>
        </div>
      </div>
    </>
  );
};
export { Shortcut };
