import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import styles from "./PostCard.module.css";
import { avatars, screens } from "../../common/mediaConstants";
import LikeButton from "./LikeButton";
import { Circles } from "react-loader-spinner";
import useGetNameCookie from "../../common/getNameCookie";
import {
  openModal,
  setRoomName,
  setRoomType,
  setRoomId,
  setModalFor,
} from "../../features/EnterWorldPopUp/EnterWorldSlice";
import {
  callCreateMetaverseClickCheck,
  callDeactivateRoomVisitor,
  callVisitorRegistration,
  callVisitRoom,
} from "../../common/axiosCalls";
import { setRoomInfo } from "../../features/RoomInfo/RoomInfoDataSlice";

const PostCard = ({ screen }) => {
  //Jotai

  const myRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const cookie = useGetNameCookie();
  const authentication = useSelector((store) => store.authentication);
  const roomInfo = useSelector((store) => store.roomInfo);
  const pagination = useSelector((store) => store.pagination);
  const [postCards, setPostCards] = useState(null);

  useEffect(() => {
    setPostCards(pagination.postCards);

    if (!pagination.postCards) {
      setShowSpinner(true);
    } else {
      setPostCards(pagination.postCards);

      setTimeout(() => {
        setShowSpinner(false);
      }, 500);
    }
  }, [pagination.postCards]);

  const postVisitHandler = async (room_name, room_type, avtar_name, id) => {
    if (authentication.isAuthenticated) {
      callVisitRoom({ room_name }).catch((err) => {
        console.log(err);
      });
      dispatch(
        setRoomInfo({
          avtar_id: authentication.userDetails.avtar_id,
          avtar_name: authentication.userDetails.avtar_name,
          room_type: room_type,
          room_name: room_name.replaceAll("%20", " "),
          room_id: id,
          socket_name: authentication.userDetails.avtar_name,
          scene_name: room_type,
          is_admin:
            authentication.userDetails.avtar_name === avtar_name ? true : false,
        })
      );

      navigate(`/room/${room_type}/${room_name}/${id}`);
    } else {
      if (cookie) {
        callVisitorRegistration({
          room_name: room_name,
          avtar_name: cookie.avtar_name,
          avtar_id: cookie.avtar_id,
          ip_address: authentication.userIP,
        }).catch((err) => {
          console.log(err);
        });

        dispatch(
          setRoomInfo({
            avtar_id: cookie.avtar_id,
            avtar_name: cookie.avtar_name,
            room_type: room_type,
            room_name: room_name,
            room_id: id,
            is_admin: false,
            socket_name: cookie.avtar_name,
            scene_name: room_type,
          })
        );
        navigate(`/room/${room_type}/${room_name}/${id}`);

      } else {
        dispatch(setRoomName(room_name));
        dispatch(setRoomType(room_type));
        dispatch(setRoomId(id));
        dispatch(openModal());
      }
    }
  };
  const createWorldHandler = () => {
    if (authentication.isAuthenticated) {
      callDeactivateRoomVisitor({ room_name: roomInfo.room_name }).then(
        (response) => {
          navigate("/create-room");
        }
      );
    } else {
      if (!cookie) {
        dispatch(
          setModalFor({
            purpose: "create-room-modal",
            data: {},
          })
        );
        dispatch(openModal());
      } else {
        let registrationID = 10000000;
        const cookies = document.cookie
          .split(";")
          .map((item) => {
            return item.split("=");
          })
          .filter((item) => item[0].trim() === "registration_id");
        registrationID = cookies.length === 0 ? 10000000 : cookies[0][1];
        callCreateMetaverseClickCheck({
          id: registrationID,
        });

        navigate("/create-room");
      }
    }
  };

  return (
    <React.Fragment>
      <div className="cards" id="cards" ref={myRef}>
        <div className="row">
          {postCards?.length > 0 ? (
            showSpinner ? (
              <div className={styles["spinnerDiv"]}>
                <Circles
                  height="80"
                  width="80"
                  color="#000e1f"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  className="spinner"
                />
              </div>
            ) : (
              postCards.map((item, i) => {
                let imageSrc;
                !item.profile_thumbnail
                  ? (imageSrc = screens[item.room_type])
                  : (imageSrc = item.profile_thumbnail);
                return (
                  <div
                    className={` ${styles["fadeInCard"]} col-xl-3 col-lg-4 col-md-6 d-flex flex-column`}
                    key={i}
                  >
                    <div
                      className={` ${styles["content"]}  ${styles["content-1"]} align-self-center`}
                    >
                      <div className={styles["poster_image"]}>
                        <img src={imageSrc} alt="background" />
                      </div>

                      <div className={styles["card-info"]}>
                        <div className={styles["r-name"]}>
                          <b>{item.room_name}</b>
                        </div>
                        <hr className={styles["hr-c"]} />
                        <div
                          className={` ${styles["card-name"]} d-flex justify-content-between`}
                        >
                          <div className={styles["profile"]}>
                            <img
                              src={avatars[item.avtar_id - 1]}
                              alt="profile"
                            />
                            {item.avtar_name}
                          </div>
                          <div className={styles["i-gap"]}>
                            <LikeButton
                              room_id={item.id}
                              room_name={item.room_name}
                              like_count={item.room_like_count}
                              isExplore={true}
                            />
                            <span
                              className={`${styles["icon-c"]} material-icons`}
                            >
                              visibility
                            </span>
                            {+item.visits + +item.visitor_reg}
                          </div>
                        </div>
                        <hr className={styles["hr-c"]} />
                        <div className={styles["card-btn"]}>
                          <div
                            className={`${styles["exp-btn"]} ${styles["i-gap"]}`}
                          >
                            <button
                              title="Enter Metaverse"
                              className={styles["vlike"]}
                              onClick={() =>
                                postVisitHandler(
                                  item.room_name,
                                  item.room_type,
                                  item.avtar_name,
                                  item.id
                                )
                              }
                            >
                              Enter Metaverse
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )
          ) : (
            <div className={styles["MM_noWorld"]}>
              <p>No worlds yet! Please Create a new one.</p>
              <button
                title="Create Your Metaverse it`s Free"
                className={styles["MM_createBtn"]}
                onClick={createWorldHandler}
              >
                Create World
              </button>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PostCard;