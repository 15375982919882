import React, { useEffect, useState } from "react";
import styles from "./About.module.css";
import Header from "../../layouts/header/Header";
import userflow from "../../assets/Videos/userflow.webm";
import whyvideo from "../../assets/images/Why-WB.webm";
import {
  callCreateMetaverseClickCheck,
  callDeactivateRoomVisitor,
  callVisitorRegistration,
} from "../../common/axiosCalls";
import {
  openModal,
  setModalFor,
} from "../../features/EnterWorldPopUp/EnterWorldSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useGetNameCookie from "../../common/getNameCookie";
import { companyLogos, sectorImages } from "./ContentLogo";
import Footer from "../../layouts/footer/Footer";
import { setRoomInfo } from "../../features/RoomInfo/RoomInfoDataSlice";
import { generateRandomUserInfo } from "../../common/commonFunctions";
import { LeftSideSvgIcon, RightSideSvgIcon } from "../../common/svgIcon";

const About = () => {
  const authentication = useSelector((store) => store.authentication);
  const roomInfo = useSelector((store) => store.roomInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookie = useGetNameCookie();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleCards, setVisibleCards] = useState(4);
  const [isEmptyRoom, setIsEmptyRoom] = useState(true);

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const nextSlide = () => {
    if (currentIndex < sectorImages.length - visibleCards) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const updateVisibleCards = () => {
    if (window.innerWidth <= 800) {
      setVisibleCards(2);
    } else if (window.innerWidth <= 1000) {
      setVisibleCards(3);
    } else {
      setVisibleCards(4);
    }
  };

  useEffect(() => {
    updateVisibleCards();
    window.addEventListener("resize", updateVisibleCards);
    return () => {
      window.removeEventListener("resize", updateVisibleCards);
    };
  }, []);

  const createWorldHandler = () => {
    if (authentication.isAuthenticated) {
      navigate("/create-room");
    } else {
      if (!cookie) {
        dispatch(
          setModalFor({
            purpose: "create-room-modal",
            data: {},
          })
        );
        dispatch(openModal());
      } else {
        let registrationID = 10000000;
        const cookies = document.cookie
          .split(";")
          .map((item) => {
            return item.split("=");
          })
          .filter((item) => item[0].trim() === "registration_id");
        registrationID = cookies.length === 0 ? 10000000 : cookies[0][1];
        callCreateMetaverseClickCheck({
          id: registrationID,
        });

        navigate("/create-room");
      }
    }
  };

  const tryForFreeHandler = async () => {
    const userInfo = generateRandomUserInfo();

    document.cookie = `avtar_name=${userInfo.avtar_name}; expires=${new Date(
      Date.now() + 1 * 1 * 60 * 60 * 1000
    ).toUTCString()}`;
    document.cookie = `avtar_id=${userInfo.avtar_id}; expires=${new Date(
      Date.now() + 1 * 1 * 60 * 60 * 1000
    ).toUTCString()}`;

    callVisitorRegistration({
      room_name: `wbm`,
      avtar_name: userInfo.avtar_name,
      avtar_id: userInfo.avtar_id,
      ip_address: authentication.userIP,
    })
      .then((res) => {
        document.cookie = `registration_id=${
          res.data.data[0].id
        }; expires=${new Date(9999, 0, 1).toUTCString()}`;
      })
      .catch((err) => {
        console.log(err);
      });

    dispatch(
      setRoomInfo({
        avtar_id: userInfo.avtar_id,
        avtar_name: userInfo.avtar_name,
        room_type: "r3fmr",
        room_name: `defaultmr`,
        room_id: 0,
        is_admin: false,
      })
    );

    navigate(`/room/r3fmr/defaultmr/0`);
  };

  const inlineStyles = {
    "--bs-gutter-x": "0rem",
  };

  return (
    <>
      <Header />
      <div className={styles["main_about_wrapper"]}>
        <div className={styles["sub_about_wrapper"]}>
          <section className={styles["section_user_journey"]}>
            <h1>User Journey & Experience</h1>
            <p className={styles["pragrap_user_Journey"]}>
              World Beyond users can explore personalized features for avatar
              selection based on social networks, age, interests, gender, etc in
              Metaverse. Users in the form of avatar can experience the
              innovative developments of the virtual world in a persistent 3D
              space. The environment of the World Beyond presents its user with
              immersive & interactive features for visiting tourist places,
              working spaces, colleges, fun zone, banks, etc. in the virtual
              world.
            </p>
            <button
              title="Create Your Metaverse it`s Free"
              className={styles["About-create-button"]}
              onClick={createWorldHandler}
            >
              Create World
            </button>
          </section>
        </div>
        <div className={styles["power_sector"]}>
          <div className={`${styles["mid-content3"]}row`}>
            <div className={styles["sec-section"]}>
              <h3>Powering Organization & Sector</h3>
            </div>

            <div className={`${styles["sector-sec"]} container`}>
              <button
                className={`${styles["slider-button"]} `}
                onClick={prevSlide}
                disabled={currentIndex === 0}
              >
                <LeftSideSvgIcon />
              </button>

              {sectorImages
                .slice(currentIndex, currentIndex + visibleCards)
                .map((item, index) => (
                  <div className={styles["content-sec"]}>
                    <div className={styles["content-overlay"]}></div>
                    <img src={item.img} alt="alternative" />

                    <div
                      className={`${styles["content-details"]} ${styles["fadeIn-bottom"]}`}
                    >
                      <h3 className="content-title">{item.name}</h3>
                    </div>
                  </div>
                ))}

              <button
                className={`${styles["slider-button"]}`}
                onClick={nextSlide}
                disabled={currentIndex === sectorImages.length - visibleCards}
              >
                <RightSideSvgIcon />
              </button>
            </div>
          </div>

          <section className={styles["main-video-section"]}>
            <div className={styles["video-section"]}>
              <video
                src={userflow}
                className={styles["flow-video"]}
                loop
                muted
                autoPlay
              ></video>
            </div>
            <div className={styles["Create-meta-about"]}>
              <h3>Share your Creations in Metaverse </h3>
              <ul>
                <li> Sign up for an account.</li>
                <li>Choose your free World Beyond space.</li>
                <li>Simply upload your creations</li>
                <li>Publish or share your space</li>
              </ul>

              <button
                title="Create Your Metaverse it`s Free"
                className={styles["About-create-button"]}
                onClick={createWorldHandler}
              >
                Create World
              </button>
            </div>
          </section>

          <section className={styles["Join_world_beyond_section"]}>
            <div className={styles["join-section"]}>
              <h3>
                Jine The <br />
                World Beyond Metaverse
              </h3>

              <div className={styles["join-btn"]}>
                <div>
                  {authentication.isAuthenticated ? (
                    <button
                      title="My metaverse"
                      className={styles["login-btn"]}
                      onClick={() => {
                        navigate("/my-metaverse");
                      }}
                    >
                      My Metaverse
                    </button>
                  ) : (
                    <button
                      title="sign up its Free"
                      className={styles["login-btn"]}
                      onClick={tryForFreeHandler}
                    >
                      Sign up its Free{" "}
                    </button>
                  )}
                </div>
                <div>
                  <a
                    href="https://app.popt.in/APIRequest/click/e7e5cf62bf3f6"
                    className={styles["login-btn1"]}
                  >
                    Become a Partner
                  </a>
                </div>
              </div>
            </div>
            <div className={styles["mobile_video"]}>
              <video
                src={whyvideo}
                className={styles["s-video"]}
                loop
                muted
                autoPlay
                playsInline
              ></video>
            </div>
          </section>

          <section>
            <div
              className={`${styles["mid-content2"]} row`}
              style={inlineStyles}
            >
              <div className="col-md-12">
                <h3 className={styles["business_heading"]}>
                  Businesses Use World Beyond
                </h3>
                <div className={`${styles["o-logo"]} row`}>
                  {companyLogos.map((item, i) => (
                    <div className="col-md-2 col-4" key={i}>
                      <div className={styles["i-logo"]}>
                        <img
                          className={styles["logo_img"]}
                          src={item}
                          alt="logo"
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <i className={styles["logo-note"]}>
                  <sup>*</sup>Participants including in Digi Expo, Global
                  Investment Convention (GIC), Global Trade Convention (GTC).
                </i>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default About;
