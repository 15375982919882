import React, { useRef, useState } from "react";
import styles from "./MeetingRoomCard.module.css";
import Features from "../features/Features";
import { meetingFeatures } from "../../../../common/mediaConstants";
import MeetAbout from "../meetAbout/MeetAbout";
import Benefits from "../benefits/Benefits";
import MeetingRoomCTA from "../meetAbout/MeetingRoomCTA";
import Footer from "../../../../layouts/footer/Footer";
import MeetingRoom from "../MeetingRoom";
import { generateRandomUserInfo } from "../../../../common/commonFunctions";
import { setRoomInfo } from "../../../../features/RoomInfo/RoomInfoDataSlice";
import { callEnterMetaBtnClick } from "../../../../common/axiosCalls";
import { useDispatch } from "react-redux";

const MeetingRoomCards = () => {
  const secondSectionRef = useRef(null);
  const dispatch = useDispatch()

  const handleSpanClick = () => {
    secondSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const [isOpen, setIsOpen] = useState(null);

  const diveInHandler = async () => {
    await callEnterMetaBtnClick({
      webpage_link: window.location.href
    });

    const userInfo = generateRandomUserInfo();
    document.cookie = `avtar_name=${userInfo.avtar_name}; expires=${new Date(
      Date.now() + 1 * 1 * 60 * 60 * 1000
    ).toUTCString()}`;
    document.cookie = `avtar_id=${userInfo.avtar_id}; expires=${new Date(
      Date.now() + 1 * 1 * 60 * 60 * 1000
    ).toUTCString()}`;

    dispatch(
      setRoomInfo({
        avtar_id: userInfo.avtar_id,
        avtar_name: userInfo.avtar_name,
        room_type: "r3f_mr2",
        room_name: "",
        socket_name: userInfo.avtar_name,
        scene_name: "r3f_mr2",
        is_admin: false,
        socket_name: userInfo.avtar_name,
      })
    );
    setIsOpen(true);
  };
  return (
    <>
      <section id={styles["section"]} className={styles["demo"]}>
        <span onClick={handleSpanClick}></span>
      </section>

      <div className={styles["meeting-main-page"]}>
        <section className={styles["main-section"]} ref={secondSectionRef}>
          <p className={styles["main-heading"]}>
            Explore Impressive Meeting Room <br />
            Features that Redefine Excellence
          </p>
          {meetingFeatures.map((feature, index) => {
            return (
              <>
                <Features
                  key={index}
                  index={index}
                  feature={feature}
                  diveInHandler={diveInHandler}
                />
              </>
            );
          })}
          <p className={styles["main-heading"]}>About Meeting Room</p>
          <MeetAbout meetingFeatures={meetingFeatures} diveInHandler={diveInHandler} />
          <MeetingRoomCTA diveInHandler={diveInHandler} />
          <p className={styles["main-heading"]}>Benefits of Meeting Rooms</p>
          <Benefits />
        </section>
      </div>
      <div className={styles["footer-meeting-room"]}>
        <Footer />
      </div>
      {isOpen && <MeetingRoom setIsOpen={setIsOpen} isOpen={isOpen} />}
    </>
  );
};

export default MeetingRoomCards;
