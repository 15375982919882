import React from "react";
import Styles from "./Features.module.css";
import { PlaycircleSvgIcon } from "../../../../common/svgIcon";



const TextFeature = ({ feature, diveInHandler }) => {
 
  const { featuresList, title, ButtonName } = feature;


  return (
    <div className={Styles["text-section-wrap"]}>
      <p className={Styles["meet-title"]}>{title}</p>
      <div className={Styles["meet-list"]}>
        {featuresList.map((feature) => {
          return (
            <div className={Styles["text-list"]}>
              <div className={Styles["list-bullet"]}></div>
              <p className="">{feature}</p>
            </div>
          );
        })}
      </div>
      <div className={Styles["main-meet-btn"]}>
        <button onClick={diveInHandler} className={Styles["meeting-reg-btn"]}>
          {ButtonName}
        </button>
        <button className={Styles["meeting-play-btn"]}>
          <PlaycircleSvgIcon /> <span>See How it works</span>
        </button>
      </div>
    </div>
  );
};

export default TextFeature;
