import React, { useEffect, useState } from "react";
import "./Toast.css";
import {
  ToastCloseIcon,
  ToastSnapIcon,
  ToastSuccessBlueCircleIcon,
  ToastSuccessGreenBubblesIcon,
  ToastSuccessIcon,
  ToastSuccessRedBubblesIcon,
  ToastSuccessRedCircleIcon,
} from "../../common/svgIcon";
import { deleteToast } from "../../features/ErrorHandling/ErrorHandlingSlice";
import { useDispatch } from "react-redux";

const ToastMessage = ({ toast }) => {
  const dispatch = useDispatch();
  return (
    <>
      {toast.type === "success" && (
        <div className="result-success-mode-dark-palette">
          <div className="message-frame">
            <span onClick={() => dispatch(deleteToast(toast.id))}>
              <ToastCloseIcon />
            </span>
            <ToastSuccessGreenBubblesIcon />
            <div className="message">
              <div className="header">Well done!</div>
              <div className="text">{toast.message}</div>
            </div>
          </div>
          <div className="icon-78-success">
            <ToastSuccessBlueCircleIcon />
            <ToastSuccessIcon />
          </div>
        </div>
      )}

      {toast.type === "error" && (
        <div
          className="result-success-mode-dark-palette"
          onClick={() => !toast.type === "error"}
        >
          <div className="message-frame-red">
            <span onClick={() => dispatch(deleteToast(toast.id))}>
              <ToastCloseIcon />
            </span>
            <ToastSuccessRedBubblesIcon />
            <div className="message">
              <div className="header">Oh snap!</div>
              <div className="text">{toast.message}</div>
            </div>
          </div>
          <div className="icon-78-success">
            <ToastSuccessRedCircleIcon />
            <ToastSnapIcon />
          </div>
        </div>
      )}
    </>
  );
};

export default ToastMessage;
