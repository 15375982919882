export default function TeleportRing({ ringRef }) {
    return (
        <>
            <mesh name="TeleportRing" ref={ringRef}
                rotation-x={-Math.PI / 2}
                position-y={-0.001}>
                <ringGeometry args={[0.1, 0.3, 32]} />
                <meshStandardMaterial
                    color="orange"
                    opacity={0.5}
                    transparent={true}
                />
            </mesh>
        </>
    )
}
