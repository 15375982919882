import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userLikes: [],
  roomIDs: [],
  roomNames: [],
};

const userLikesSlice = createSlice({
  name: "userLikes",
  initialState,
  reducers: {
    likeRoom: (state, action) => {
      let newRoomIDs = state?.roomIDs.map((item) => {
        return item;
      });
      newRoomIDs.push(+action.payload);
      state.roomIDs = newRoomIDs;
    },

    dislikeRoom: (state, action) => {
      let newRoomIDs = state?.roomIDs?.filter((item) => {
        return item !== +action.payload;
      });
      state.roomIDs = newRoomIDs;
    },

    setLikesArray: (state, action) => {
      state.userLikes = action.payload;
      state.roomIDs = action.payload?.map((item) => +item.room_id);
      state.roomNames = action.payload?.map((item) => item.room_name);
    },

    emptyLikesData: (state) => {
      state.userLikes = null;
      state.roomIDs = [];
      state.roomNames = [];
    },
  },
});

export const { likeRoom, dislikeRoom, setLikesArray, emptyLikesData } =
  userLikesSlice.actions;

export default userLikesSlice.reducer;
