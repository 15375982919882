import { useDispatch } from "react-redux";
import {
  setAuthentication,
  setIPAddress,
  setUserDetails,
} from "../features/Authentication/AuthenticationSlice";
import { useEffect } from "react";
import { setLikesArray } from "../features/UserLikes/UserLikesSlice";
import {
  callDecodeToken,
  callGeolocation,
  callWebsiteVisitors,
} from "./axiosCalls";

export const useInitialLoad = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    callGeolocation()
      .then((res) => {
        callWebsiteVisitors({
          ip_address: res.ip_address,
          ip_country: res.ip_country,
          ip_state: res.ip_state,
          tourkey_link: window.location.href,
        });
        dispatch(setIPAddress(res.ip_address));
      })
      .catch((err) => {
        callWebsiteVisitors({
          ip_address: "Not found",
          ip_country: "Not found",
          ip_state: "Not found",
          tourkey_link: "Not found",
        });
      });

    callDecodeToken()
      .then((response) => {
        dispatch(setAuthentication(true));
        dispatch(setUserDetails(response.data.Data));
        dispatch(setLikesArray(response.data.rooms_liked_by_me));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
};
