const baseURL = "https://www.digimetaverse.live/";

// ----------------------------AUTHENTICATION------------------------------------
export const REGISTRATION = `${baseURL}registration`; //registration
export const NEW_REGISTRATION = `${baseURL}new-registration`; //new registration
export const LOGIN = `${baseURL}login`; //login 
export const DECODE_TOKEN = `${baseURL}decode-token`; //decode-token 
export const LOGOUT = `${baseURL}logout`; //logout 

//----------------------------CREATE WORLD------------------------------------
export const CREATE_ROOM = `${baseURL}create-world`; //create-world 
export const CREATE_VISIT_LIST = `${baseURL}get-all-worlds-list`; //get-all-room-list 
export const MULTI_ROOMS = `${baseURL}get-worlds-userwise`; //get-worlds-userwise 
export const UPDATE_ROOM_THUMBNAIL = `${baseURL}edit-world-thumbnail`; //edit-world-thumbnail 
export const VISIT_ROOM = `${baseURL}enter-world`; //enter-world 
export const DEACTIVATE_ROOM_VISITOR = `${baseURL}leave-world`; //leave-world 
export const LIKE_DISLIKE_WORLD = `${baseURL}like-dislike-world`; // 
export const UPDATED_AVTAR_DETAILS = `${baseURL}update-avtar-details`; //update-avtar-details 
export const CHANGE_WORLD_STATUS = `${baseURL}world-status`; //change-world-status 
export const SHARE_WORLD_LINK = `${baseURL}share-world-link`; // 

// ------------------------------DASHBORAD------------------------------------------//
export const WEBSITE_VISITORS = `${baseURL}website-visitors`; //website-visitors 
export const VISITOR_REGISTRATION = `${baseURL}visitor-registration`;
export const CREATE_METAVERSE_CLICKS = `${baseURL}create-metaverse-clicks`; //
export const ENTER_META_BUTTON_CLICK = `${baseURL}enter-meta-button-click`;
export const ENTER_META_CLICK_DATA = `${baseURL}enter-metaclick-data`; //
export const MY_ANALYTICS = `${baseURL}my-analytics`;
export const LEAVE_WORLD_REASON = `${baseURL}leave-world-reason`;
export const WEBSITE_SUBSCRIBER = `${baseURL}website-subscriber `;

//--------------------------SOCIAL MEDIA LOGIN--------------------------------------
export const GOOGLE_LOGIN = `${baseURL}google-login`;
export const FACEBOOK_LOGIN = `${baseURL}facebook-login`;

// ----------------------------CHAT--------------------------------------//
export const LIVE_ROOM_VISITOR = `${baseURL}Live-Room-Visitor`; //Live-Room-Visitor
export const VISITOR_CHAT_GET = `${baseURL}Visitor-Chat-Get`; // Visitor-Chat-Get
export const VISITOR_CHAT_POST = `${baseURL}Visitor-Chat-Post`; // Visitor-Chat-Post

// -------------------------------IMAGE AND VIDEO UPLOAD--------------------------------------------//

export const GET_ROOM_MEDIA = `${baseURL}get-room-media`; // get room media
export const ROOM_MEDIA = `${baseURL}room-media`; // POST room media

//------------------------------no use api----------------------------------

export const GET_WORLD_CATEGORIES = `${baseURL}get-world-categories`; //get-world-category 
export const MY_ROOM_VISIT = `${baseURL}myroom-visit`; //myroom-visit  
export const LIVE_USER = `${baseURL}liveuser`; //liveuser   
export const CHECK_WORLD_STATUS = `${baseURL}check-world-status`; //check-world-status 
export const VALIDATE_WORLD_PASSWORD = `${baseURL}validate-world-password`;
export const PRICING_REGISTRATION = `${baseURL}pricing-registration`;
