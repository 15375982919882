import axios from "axios";
import {
  CHANGE_WORLD_STATUS,
  CREATE_METAVERSE_CLICKS,
  CREATE_ROOM,
  CREATE_VISIT_LIST,
  DEACTIVATE_ROOM_VISITOR,
  DECODE_TOKEN,
  GOOGLE_LOGIN,
  LIKE_DISLIKE_WORLD,
  LOGIN,
  LOGOUT,
  MULTI_ROOMS,
  REGISTRATION,
  UPDATED_AVTAR_DETAILS,
  UPDATE_ROOM_THUMBNAIL,
  VISITOR_REGISTRATION,
  VISIT_ROOM,
  SHARE_WORLD_LINK,
  VALIDATE_WORLD_PASSWORD,
  CHECK_WORLD_STATUS,
  NEW_REGISTRATION,
  PRICING_REGISTRATION,
  MY_ANALYTICS,
  WEBSITE_VISITORS,
  LEAVE_WORLD_REASON,
  FACEBOOK_LOGIN,
  ENTER_META_CLICK_DATA,
  ENTER_META_BUTTON_CLICK,
  GET_ROOM_MEDIA,
  ROOM_MEDIA,
  WEBSITE_SUBSCRIBER,
} from "../utils/api";

const getMultipartHeader = (withToken = true) => {
  const multipartFormData = {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const multipartFormDataWithoutToken = {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  };

  return withToken ? multipartFormData : multipartFormDataWithoutToken;
};

export const callGeolocation = () => {
  return new Promise((resolve, reject) => {
    let locationDetails = {
      ip_address: "",
      ip_city: "",
      ip_country: "",
      ip_latitude: "",
      ip_longitude: "",
      ip_state: "",
    };

    axios
      .get("https://geolocation-db.com/json/")
      .then((response) => {
        locationDetails.ip_address = response.data.IPv4;
        locationDetails.ip_city = response.data.city;
        locationDetails.ip_country = response.data.country_name;
        locationDetails.ip_latitude = response.data.latitude;
        locationDetails.ip_longitude = response.data.longitude;
        locationDetails.ip_state = response.data.state;

        resolve(locationDetails);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const callRegistration = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const geoLocation = await callGeolocation();
      const registrationData = await axios.post(REGISTRATION, {
        ...data,
        ...geoLocation,
      });

      resolve(registrationData);
    } catch (err) {
      reject(err.response.data);
    }
  });
};

export const callDecodeToken = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const decodeTokenData = await axios.post(
        DECODE_TOKEN,
        "",
        getMultipartHeader()
      );
      resolve(decodeTokenData);
    } catch (err) {
      reject(err.message);
    }
  });
};

export const callVisitRoom = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const VisitRoomData = axios.post(VISIT_ROOM, data, getMultipartHeader());
      resolve(VisitRoomData);
    } catch (err) {
      reject(err);
    }
  });
};

export const callCreateRoom = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const createRoomData = await axios.post(
        CREATE_ROOM,
        data,
        getMultipartHeader()
      );
      resolve(createRoomData);
    } catch (err) {
      reject(err.response.data);
    }
  });
};

export const callLogin = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const loginData = await axios.post(
        LOGIN,
        data,
        getMultipartHeader(false)
      );
      resolve(loginData);
    } catch (error) {
      reject(error.response.data);
    }
  });
};

export const callLikeDislikeWorld = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const likeDislikeData = axios.post(
        LIKE_DISLIKE_WORLD,
        data,
        getMultipartHeader()
      );
      resolve(likeDislikeData);
    } catch (error) {
      reject(error.message);
    }
  });
};

export const callCreateVisitList = (data) => {
  return new Promise((resolve, reject) => {
    try {
      const createVisitListData = axios.post(
        CREATE_VISIT_LIST,
        data,
        getMultipartHeader(false)
      );
      resolve(createVisitListData);
    } catch (error) {
      reject(error);
    }
  });
};

export const callVisitorRegistration = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let locationDetails = await callGeolocation();
      const visitorRegistrationData = await axios.post(VISITOR_REGISTRATION, {
        ...locationDetails,
        ...data,
      });
      document.cookie = `registration_id=${
        visitorRegistrationData.data.data[0].id
      }; expires=${new Date(9999, 0, 1).toUTCString()}`;
      resolve(visitorRegistrationData);
    } catch (error) {
      reject(error);
    }
  });
};

export const callMultiRooms = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const multiRoomsData = await axios.post(
        MULTI_ROOMS,
        "",
        getMultipartHeader()
      );
      resolve(multiRoomsData);
    } catch (error) {
      reject(error);
    }
  });
};

export const callDeactivateRoomVisitor = (data) => {
  return new Promise((resolve, reject) => {
    try {
      const deactivateRoomVisitorData = axios.post(
        DEACTIVATE_ROOM_VISITOR,
        data,
        getMultipartHeader()
      );
      resolve(deactivateRoomVisitorData);
    } catch (error) {
      reject(error);
    }
  });
};

export const callUpdateRoomThumbnail = (data) => {
  return new Promise((resolve, reject) => {
    try {
      const updateRoomThumbnailData = axios.post(
        UPDATE_ROOM_THUMBNAIL,
        data,
        getMultipartHeader()
      );
      resolve(updateRoomThumbnailData);
    } catch (error) {
      reject(error);
    }
  });
};

export const callCreateMetaverseClickCheck = (data) => {
  return new Promise((resolve, reject) => {
    try {
      const apiData = axios.post(
        CREATE_METAVERSE_CLICKS,
        data,
        getMultipartHeader(false)
      );
      resolve(apiData);
    } catch (error) {
      reject(error);
    }
  });
};

export const callLogout = () => {
  return new Promise((resolve, reject) => {
    try {
      const apidata = axios.post(LOGOUT, "", getMultipartHeader());
      resolve(apidata);
    } catch (error) {
      reject(error);
    }
  });
};

export const callChangeWorldStatus = (data) => {
  return new Promise((resolve, reject) => {
    try {
      const statusData = axios.post(
        CHANGE_WORLD_STATUS,
        data,
        getMultipartHeader()
      );
      resolve(statusData);
    } catch (error) {
      reject(error);
    }
  });
};

export const callGoogleLogin = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const geoLocationData = await callGeolocation();
      const googleData = await axios.post(
        GOOGLE_LOGIN,
        {
          ...data,
          ...geoLocationData,
        },
        getMultipartHeader()
      );
      resolve(googleData);
    } catch (error) {
      reject(error);
    }
  });
};

export const callUpdateAvtarDetails = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const updatedUserDetails = await axios.post(
        UPDATED_AVTAR_DETAILS,
        data,
        getMultipartHeader()
      );
      resolve(updatedUserDetails);
    } catch (error) {
      reject(error);
    }
  });
};

export const callsharePassword = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const updatedUrlDetails = await axios.post(
        SHARE_WORLD_LINK,
        data,
        getMultipartHeader()
      );
      resolve(updatedUrlDetails);
    } catch (error) {
      reject(error.response.data);
    }
  });
};

export const callValidatePassword = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const updatedUrlDetails = await axios.post(
        VALIDATE_WORLD_PASSWORD,
        data,
        getMultipartHeader()
      );
      resolve(updatedUrlDetails);
    } catch (error) {
      reject(error.response.data);
    }
  });
};

export const callCheckworldstatus = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const updatedUrlDetails = await axios.post(
        CHECK_WORLD_STATUS,
        data,
        getMultipartHeader()
      );
      resolve(updatedUrlDetails);
    } catch (error) {
      reject(error);
    }
  });
};

export const callNewRegistration = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const geolocationData = await callGeolocation();
      const newRegistrationData = await axios.post(NEW_REGISTRATION, {
        ...geolocationData,
        ...data,
      });
      resolve(newRegistrationData);
    } catch (error) {
      reject(error.response.data);
    }
  });
};

export const callPricingResgistration = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const updatedUrlDetails = await axios.post(
        PRICING_REGISTRATION,
        data,
        getMultipartHeader()
      );
      resolve(updatedUrlDetails);
    } catch (error) {
      reject(error);
    }
  });
};

export const callAnalytics = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const analyticsData = await axios.post(
        MY_ANALYTICS,
        data,
        getMultipartHeader()
      );
      resolve(analyticsData);
    } catch (error) {
      reject(error.response.data);
    }
  });
};

export const callWebsiteVisitors = (data) => {
  return new Promise((resolve, reject) => {
    try {
      const apiData = axios.post(WEBSITE_VISITORS, data, getMultipartHeader());
      resolve(apiData);
    } catch (error) {
      reject(error.response.data);
    }
  });
};
export const callClickRegData = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const geoLocationData = await callGeolocation();
      const apiData = axios.post(
        ENTER_META_CLICK_DATA,
        {
          ...geoLocationData,
          ...data,
        },
        getMultipartHeader()
      );
      resolve(apiData);
    } catch (error) {
      reject(error.response.data);
    }
  });
};

export const callLeaveWorldReason = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const geoLocationData = await callGeolocation();
      const apiData = await axios.post(
        LEAVE_WORLD_REASON,
        {
          ...data,
          ...geoLocationData,
        },
        getMultipartHeader(false)
      );
      resolve(apiData);
    } catch (error) {
      reject(error);
    }
  });
};

export const callFacebookLogin = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const geolocationData = await callGeolocation();
      const facebookloginData = await axios.post(
        FACEBOOK_LOGIN,
        {
          ...data,
          ...geolocationData,
        },
        getMultipartHeader()
      );
      resolve(facebookloginData);
    } catch (error) {
      reject(error.response.data);
    }
  });
};

export const callEnterMetaBtnClick = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const geoLocationData = await callGeolocation();
      const enterEnterMetaData = await axios.post(
        ENTER_META_BUTTON_CLICK,
       
        {
          ... data,
          ...geoLocationData,
        },
        getMultipartHeader()
      );
      resolve(enterEnterMetaData);
    } catch (error) {
      reject(error);
    }
  });
};

export const callGetRoomMedia = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const media = await axios.post(
        GET_ROOM_MEDIA,
        { ...data },
        getMultipartHeader(false)
      );
      resolve(media);
    } catch (error) {
      reject(error);
    }
  });
};

export const callRoomMedia = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.post(
        ROOM_MEDIA,
        { ...data },
        getMultipartHeader(false)
      );
      
      resolve(result);
    } catch (error) {
      reject(error.response.data);
    }
  });
};

export const callWebsiteSubscriber = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const geoLocationData = await callGeolocation();
      const apiData = await axios.post(
        WEBSITE_SUBSCRIBER,
        {
          ...data,
          ...geoLocationData,
        },
        getMultipartHeader(false)
      );
      resolve(apiData);
    } catch (error) {
      reject(error.response.data);
    }
  });
};
