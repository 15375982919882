import { Html } from "@react-three/drei"; 
import { useDispatch, useSelector } from "react-redux"; 
import ImageUpload from "../../../../../Web/components/file/ImageUpload";
import { setToast } from "../../../../../Web/features/ErrorHandling/ErrorHandlingSlice";

const ImageScreen = ({ ...props }) => {   
  let {imageScreen}=props; 
  const roomInfo = useSelector((store) => store.roomInfo);
  const dispatch = useDispatch(); 
  return ( 
    <Html
      zIndexRange={[201, 0]}
      layer={1}
      position={[imageScreen.p.x, imageScreen.p.y, imageScreen.p.z]}
      rotation={[imageScreen.r.x, imageScreen.r.y, imageScreen.r.z]}
      castShadow
      receiveShadow
      occlude="blending"
      distanceFactor={3.0}
      transform
    >
      <div
        className="imageScreenContainer"
        style={{
          height: imageScreen.height,
          width: imageScreen.width,
          userSelect: "none",
        }}
      >
        <div className="imageScreenContainerInside">
          <ImageUpload
            roomInfo={roomInfo}
            screen={imageScreen.name}
            isFileProps={false}
            dispatch={dispatch}
            setToast={setToast}
          />
        </div>
      </div>
    </Html>
  );
};
export { ImageScreen };
