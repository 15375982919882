import React, { useEffect, useState } from "react";
import {
  callChangeWorldStatus,
  callVisitRoom,
} from "../../../../common/axiosCalls";
import styles from "./WorldDetails.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setRoomInfo } from "../../../../features/RoomInfo/RoomInfoDataSlice";
import { useNavigate } from "react-router";

const WorldDetails = (props) => {
  const authentication = useSelector((store) => store.authentication);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [status, setStatus] = useState(props.room_status);



  const publicHandler = () => {
    callChangeWorldStatus({
      room_id: props.room_id,
      room_status: "public",
    })
      .then((res) => {
        setStatus("Public");
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
  };

  const privateHandler = () => {
    callChangeWorldStatus({
      room_id: props.room_id,
      room_status: "private",
    })
      .then((res) => {
        setStatus("Private");
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
  };

  const roomVisitHandler = () => {
    callVisitRoom({ room_name: props.room_name });

    dispatch(
      setRoomInfo({
        avtar_id: authentication.userDetails.avtar_id,
        avtar_name: authentication.userDetails.avtar_name,
        room_type: props.room_type,
        room_name: props.room_name,
        room_id: props.room_id,
        is_admin: true,
        scene_name: props.room_type,
        socket_name: authentication.userDetails.avtar_name,
      })
    );

    navigate(`/room/${props.room_type}/${props.room_name}/${props.room_id}`);
    if (!props.outSideRoom) {
      window.location.reload();
    }
  };

  return (
    <section className={styles["wrapper"]}>
      <button
        title={`Visit ${props.room_type} room`}
        className={styles["Visit_btn"]}
        onClick={roomVisitHandler}
      >
        Visit
      </button>
      <div
        className={styles["left_section"]}
        onClick={() => {
          props.setUpdateThumbnailInfo((prevState) => {
            return {
              ...prevState,
              room_to_update: props.room_name,
              showUploadBtn: !prevState.showUploadBtn,
            };
          });
        }}
      >
        <img src={props.img} alt="world" />
      </div>
      <div className={styles["right_section"]}>
        <p className={styles["room_name"]}>{props.room_name}</p>
        <div className={styles["status_div"]}>
          <div className={styles["radio-wrap"]}>
            <div
              className={
                status === "Public"
                  ? styles["radio-btn-active"]
                  : styles["radio-btn"]
              }
              onClick={publicHandler}
            />
            <p>Public</p>
          </div>
          <div className={styles["radio-wrap"]}>
            <div
              className={
                status === "Private"
                  ? styles["radio-btn-active"]
                  : styles["radio-btn"]
              }
              onClick={privateHandler}
            />
            <p>Private</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WorldDetails;
