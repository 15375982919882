import React, { useEffect, useState } from "react";
import styles from "./MetaverseWorld.module.css";
import WorldDetails from "./WorldDetails/WorldDetails";
import {
  callCreateMetaverseClickCheck,
  callDeactivateRoomVisitor,
  callMultiRooms,
} from "../../../common/axiosCalls";
import UpdateThumbnail from "../../profileCard/updateThumbnail/UpdateThumbnail";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { AddWorld } from "../../../common/svgIcon";

const MetaverseWorld = ({ setIsOpen, isOpen, outSideRoom }) => {
  const authentication = useSelector((store) => store.authentication);
  const roomInfo = useSelector((store) => store.roomInfo);
  const [worlds, setWorlds] = useState([]);
  const [updateThumbnailInfo, setUpdateThumbnailInfo] = useState({
    showUploadBtn: false,
    room_to_update: null,
  });
  const navigate = useNavigate();

  useEffect(() => {
    callMultiRooms()
      .then((res) => {
        setWorlds(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [updateThumbnailInfo.showUploadBtn]);

  const createWorldHandler = () => {
    if (outSideRoom) {
      navigate("/create-room");
    } else {
      if (authentication.isAuthenticated) {
        callDeactivateRoomVisitor({ room_name: roomInfo.room_name }).then(
          (response) => {
            navigate("/create-room");
          }
        );
      } else {
        let registrationID = 10000000;
        const cookies = document.cookie
          .split(";")
          .map((item) => {
            return item.split("=");
          })
          .filter((item) => item[0].trim() === "registration_id");
        registrationID = cookies.length === 0 ? 10000000 : cookies[0][1];

        callCreateMetaverseClickCheck({
          id: registrationID,
        });
        navigate("/create-room");
      }
    }
  };


  const worldCardMobileView = () => {
    if (window.innerWidth < 450) {
      const exrtasmallScreen = worlds.length >= 0;
      return exrtasmallScreen;
    } else if (window.innerWidth < 794) {
      const smallScreen = worlds.length >= 2;
      return smallScreen;
    } else {
      const largeScreen = worlds.length >= 3;
      return largeScreen;
    }
  };

  const shouldDisplaySmallScreenView = worldCardMobileView();

 

  return (
    <div className={styles["main_metaverseWorld"]}>
      <section className={styles["wrapper"]}>
        <div className={shouldDisplaySmallScreenView ? `${styles["world-details-wrapper-scroll"]}` : `${styles["world-details-wrapper"]}`}>
          {
            worlds.length > 0 && (
              <section className={styles["wrapper-first"]}>
                <div className={styles["meta_create"]} onClick={createWorldHandler}>
                  <span>
                    <AddWorld />
                  </span>
                  <span className={styles["create_btn_world"]}>Create World</span>
                </div>
              </section>
            )
          }

          {/*  */}
          {worlds.length > 0 ? (
            worlds.map((world) => {
              return (
                <WorldDetails
                  key={world.room_id}
                  img={world.profile_thumbnail}
                  room_name={world.room_name}
                  room_status={world.room_status}
                  updateThumbnailInfo={updateThumbnailInfo}
                  setUpdateThumbnailInfo={setUpdateThumbnailInfo}
                  worlds={worlds}
                  room_id={world.room_id}
                  room_type={world.room_type}
                  setIsOpen={setIsOpen}
                  outSideRoom={outSideRoom}
                />
              );
            })
          ) : (
            <div className={styles["MM_noWorld"]}>
              <p>No worlds yet! Please Create a new one.</p>
              <button
                title="Create Your Metaverse it`s Free"
                className={styles["MM_createBtn"]}
                onClick={createWorldHandler}
              >
                Create World
              </button>
            </div>
          )}
        </div>
        {updateThumbnailInfo.showUploadBtn && (
          <UpdateThumbnail
            setUpdateThumbnailInfo={setUpdateThumbnailInfo}
            updateThumbnailInfo={updateThumbnailInfo}
          />
        )}
      </section>
    </div>
  );
};

export default MetaverseWorld;
