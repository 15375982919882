import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import "./RegistrationForm.css";
import Header from "../../../layouts/header/Header";
import useGetNameCookie from "../../../common/getNameCookie";
import { avtarBodies } from "../../../common/mediaConstants";
import {
  setAuthentication,
  setUserDetails,
} from "../../../features/Authentication/AuthenticationSlice";
import {
  callCreateRoom,
  callDecodeToken,
  callLogin,
  callRegistration,
  callVisitRoom,
} from "../../../common/axiosCalls";
import { generateRandomUserInfo } from "../../../common/commonFunctions";
import { setLikesArray } from "../../../features/UserLikes/UserLikesSlice";
import { setRoomInfo } from "../../../features/RoomInfo/RoomInfoDataSlice";
import {
  EyeCloseSvgIcon,
  EyeOpenSvgIcon,
  PencileEdit,
  RegistrationEmailIconSvg,
  RegistrationPasswordIconSvg,

} from "../../../common/svgIcon";
import { setToast } from "../../../features/ErrorHandling/ErrorHandlingSlice";
import {
  openModal,
  setModalFor,
} from "../../../features/EnterWorldPopUp/EnterWorldSlice";

const RegistrationForm = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = useGetNameCookie();


  const roomInfo = useSelector((store) => store.roomInfo);
  const [formValue, setFormValue] = useState({
    email: cookies.interestIntentEmailId ? cookies.interestIntentEmailId : "",
    password: "",

    // ? cookies.avtar_id
    // : generateRandomUserInfo().avtar_id,

    // ? cookies.avtar_name
    // : generateRandomUserInfo().avtar_name,
  });
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [showPassword, setShowPassword] = useState(null);

  const inputhandleChange = (e) => {
    const { name, value } = e.target
    if (name === "email") {
      console.log(e.target.value.toLowerCase())
      setFormValue({ ...formValue, [name]: value.toLowerCase() })

      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      setEmailIsValid(emailRegex.test(value));

    } else {

      setFormValue({ ...formValue, [name]: value })
    }
  }

  const handleLogin = () => {
    navigate("/login");
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const showToast = (type, message) => {
      dispatch(setToast({ type, message }));
    };

    try {
      const registrationData = await callRegistration({ avtar_name: cookies.avtar_name, avtar_id: cookies.avtar_id, ...formValue });
      localStorage.setItem("token", registrationData.data.data);
      callLogin({ email: formValue.email, password: formValue.password });
      const decodeTokenData = await callDecodeToken();
      dispatch(setUserDetails(decodeTokenData.data.Data));
      dispatch(setLikesArray(decodeTokenData.data.rooms_liked_by_me));
      dispatch(setAuthentication(true));

      showToast("success", "Registration successful");

      if (location?.state?.isRoomLoggedIn) {
        const { room_name, room_type, avtarId, avatarname, room_id } = roomInfo;
        dispatch(
          setRoomInfo({
            avtar_id: avtarId,
            avtar_name: avatarname,
            room_type,
            room_id,
            room_name,
            is_admin: true,
          })
        );
        navigate(`/room/${room_type}/${room_name}/${room_id}`);
      } else {
        navigate("/");
      }
    } catch (err) {
      showToast("error", err.message);
    }
  };

  const editProfileInfo = () => {
    dispatch(
      setModalFor({
        purpose: "update_info",
        data: {},
      })
    );
    dispatch(openModal());

  };


  const togglePassword = () => {
    setShowPassword(!showPassword)
  }
  return (
    <>
      <Header />
      <div className="main_wrapper">
        <form className="form_main" onSubmit={submitHandler}>
          <div className="image-container">
            <img src={avtarBodies[cookies.avtar_id - 1]} alt="profile" />
            <div className="edit-icon" onClick={editProfileInfo}>
              <PencileEdit />
            </div>
          </div>
          <h5 className="heading"> {cookies.avtar_name}</h5>

          <div className="inputContainer">
            <RegistrationEmailIconSvg />
            <input
              type="email"
              id="email"
              name="email"
              value={formValue.email}

              onChange={inputhandleChange}
              placeholder="Enter Your Email"
              className="inputField"
            ></input>
          </div>
          <div className="inputContainer">
            <RegistrationPasswordIconSvg />
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"

              onChange={inputhandleChange}
              placeholder="Set Password"
              className="inputField"
            ></input>
            <span className="showPassword" onClick={togglePassword}>
              {showPassword ? <EyeCloseSvgIcon /> : <EyeOpenSvgIcon />}
            </span>
          </div>
          <label htmlFor="checkbox" className="cBox">
            <input id="checkbox" type="checkbox" required /> &nbsp; I accept the
            &nbsp;
            <a href="https://www.worldbeyond.live/terms-of-use" target="blank">
              Terms of Use.
            </a>
          </label>
          <button className="registration_submit_button">Submit</button>
          <div className="signupContainer">
            <p className="p">
              Already have an account?
              <span className="span" onClick={handleLogin}>
                Sign in
              </span>
            </p>
          </div>
        </form>
      </div>
    </>
  );
};

export default RegistrationForm;
