import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPageNo } from '../../features/PaginationData/PaginationDataSlice';
import styles from "./pagination.module.css";

const PaginationNumber = ({
  children,
  value,
}) => {
  const dispatch = useDispatch();
  const pagination = useSelector(store => store.pagination);

  const pageClickHandler = () => {
    dispatch(setPageNo(value));
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  

  let activePageNoClass = pagination.pageNo === value 
  ? `${styles["pagination_wrapper"]} ${styles["pagination_wrapper_active"]}`
  : `${styles["pagination_wrapper"]}`
  return (
    <>
      <div className={activePageNoClass} onClick={pageClickHandler}>
        <div className={styles["pagination_number"]}>{children}</div>
      </div>
    </>
  );
}

export default PaginationNumber;