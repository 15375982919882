import avatar1 from "../assets/images/face/1.jpg";
import avatar2 from "../assets/images/face/2.jpg";
import avatar3 from "../assets/images/face/3.jpg";
import avatar4 from "../assets/images/face/4.jpg";
import avatar5 from "../assets/images/face/5.jpg";
import avatar6 from "../assets/images/face/6.jpg";
import avatar7 from "../assets/images/face/7.jpg";
import avatar8 from "../assets/images/face/8.jpg";
import avatar9 from "../assets/images/face/9.jpg";
import avatar10 from "../assets/images/face/10.jpg";
import avatar11 from "../assets/images/face/11.jpg";
import avatar12 from "../assets/images/face/12.jpg";
import avatar13 from "../assets/images/face/13.jpg";
import avatar14 from "../assets/images/face/14.jpg";
import avatar15 from "../assets/images/face/15.jpg";
import avatar16 from "../assets/images/face/16.jpg";
import avatar17 from "../assets/images/face/17.jpg";
import avatar18 from "../assets/images/face/18.jpg";
import avatar19 from "../assets/images/face/19.jpg";
import avatar20 from "../assets/images/face/20.jpg";

import avtarBody1 from "../assets/images/avatar/new/new-png/1.png";
import avtarBody2 from "../assets/images/avatar/new/new-png/2.png";
import avtarBody3 from "../assets/images/avatar/new/new-png/3.png";
import avtarBody4 from "../assets/images/avatar/new/new-png/4.png";
import avtarBody5 from "../assets/images/avatar/new/new-png/5.png";
import avtarBody6 from "../assets/images/avatar/new/new-png/6.png";
import avtarBody7 from "../assets/images/avatar/new/new-png/7.png";
import avtarBody8 from "../assets/images/avatar/new/new-png/8.png";
import avtarBody9 from "../assets/images/avatar/new/new-png/9.png";
import avtarBody10 from "../assets/images/avatar/new/new-png/10.png";
import avtarBody11 from "../assets/images/avatar/new/new-png/11.png";
import avtarBody12 from "../assets/images/avatar/new/new-png/12.png";
import avtarBody13 from "../assets/images/avatar/new/new-png/13.png";
import avtarBody14 from "../assets/images/avatar/new/new-png/14.png";
import avtarBody15 from "../assets/images/avatar/new/new-png/15.png";
import avtarBody16 from "../assets/images/avatar/new/new-png/16.png";
import avtarBody17 from "../assets/images/avatar/new/new-png/17.png";
import avtarBody18 from "../assets/images/avatar/new/new-png/18.png";
import avtarBody19 from "../assets/images/avatar/new/new-png/19.png";
import avtarBody20 from "../assets/images/avatar/new/new-png/20.png";

// room creation images

import img1 from "../assets/images/screen/new/Meeting-room.png";
import img3 from "../assets/images/screen/new/Exhibition.jpg";
import img4 from "../assets/images/screen/new/Trade_show.jpg";
import img7 from "../assets/images/screen/new/Showroom.jpg";
import img8 from "../assets/images/screen/new/Classroom.jpg";
import img9 from "../assets/images/screen/new/Tourism.jpg";
import img10 from "../assets/images/screen/new/hr.jpg";
import img11 from "../assets/images/screen/new/Office.jpg";
import img12 from "../assets/images/screen/new/Germnay.jpg";
import img13 from "../assets/images/screen/new/USA.jpg";
import img14 from "../assets/images/screen/new/Theater.jpg";
import img15 from "../assets/images/screen/new/uae.png";
import img16 from "../assets/images/screen/new/Mix-city.jpg";
import img17 from "../assets/images/screen/new/showroom2.jpg";
import img18 from "../assets/images/screen/new/Workspace.jpg";
import img20 from "../assets/images/screen/new/turkeys.png";
import img21 from "../assets/images/screen/new/Meeting_room2.png";

// Features
import meetImg1 from "../assets/images/MeetingRoom/Aavtar.jpg";
import meetImg2 from "../assets/images/MeetingRoom/Voice-Chat.jpg";
import meetImg3 from "../assets/images/MeetingRoom/Text-Chat.jpg";
import meetImg4 from "../assets/images/MeetingRoom/Screen-Share.jpg";
import meetImg5 from "../assets/images/MeetingRoom/Dashboard.jpg";
import meetImg6 from "../assets/images/MeetingRoom/Reaction.jpg";

export const avatars = [
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
  avatar10,
  avatar11,
  avatar12,
  avatar13,
  avatar14,
  avatar15,
  avatar16,
  avatar17,
  avatar18,
  avatar19,
  avatar20,
];

export const avtarBodies = [
  avtarBody1,
  avtarBody2,
  avtarBody3,
  avtarBody4,
  avtarBody5,
  avtarBody6,
  avtarBody7,
  avtarBody8,
  avtarBody9,
  avtarBody10,
  avtarBody11,
  avtarBody12,
  avtarBody13,
  avtarBody14,
  avtarBody15,
  avtarBody16,
  avtarBody17,
  avtarBody18,
  avtarBody19,
  avtarBody20,
];

export const AvtarDetails = [
  {
    img: avtarBodies[0],
    name: "avatar",
    value: "1",
  },
  {
    img: avtarBodies[1],
    name: "avatar",
    value: "2",
  },
  {
    img: avtarBodies[2],
    name: "avatar",
    value: "3",
  },
  {
    img: avtarBodies[3],
    name: "avatar",
    value: "4",
  },
  {
    img: avtarBodies[4],
    name: "avatar",
    value: "5",
  },
  {
    img: avtarBodies[5],
    name: "avatar",
    value: "6",
  },
  {
    img: avtarBodies[6],
    name: "avatar",
    value: "7",
  },
  {
    img: avtarBodies[7],
    name: "avatar",
    value: "8",
  },
  {
    img: avtarBodies[8],
    name: "avatar",
    value: "9",
  },
  {
    img: avtarBodies[9],
    name: "avatar",
    value: "10",
  },
  {
    img: avtarBodies[10],
    name: "avatar",
    value: "11",
  },
  {
    img: avtarBodies[11],
    name: "avatar",
    value: "12",
  },
  {
    img: avtarBodies[12],
    name: "avatar",
    value: "13",
  },
  {
    img: avtarBodies[13],
    name: "avatar",
    value: "14",
  },
  {
    img: avtarBodies[14],
    name: "avatar",
    value: "15",
  },
  {
    img: avtarBodies[15],
    name: "avatar",
    value: "16",
  },
  {
    img: avtarBodies[16],
    name: "avatar",
    value: "17",
  },
  {
    img: avtarBodies[17],
    name: "avatar",
    value: "18",
  },
  {
    img: avtarBodies[18],
    name: "avatar",
    value: "19",
  },
  {
    img: avtarBodies[19],
    name: "avatar",
    value: "20",
  },
];

export const screens = {
  // 3.js
  // hall: img3,
  // hall2: img4,
  // tourism: img9,
  // germany: img12,
  // usa: img13,
  // theatre: img14,
  // uae: img15,
  // mixcity: img16,
  // hr:img10,
  
  // R3f
  r3fmr: img1,
  r3f_school: img8,
  r3f_office1: img11,
  r3f_office2: img18,
  r3f_showroom: img7,
  r3f_showroom2: img17,
  r3f_hr: img10,
  r3f_turkey: img20,
  r3f_mr2:img21,
  R3F_hall: img3,
  R3F_hall2: img4,
  R3F_tourism: img9,
};

export const WorldTypeData = [
  {
    img: img1,
    world_type: "r3fmr",
    title: "Meeting Room",
  },

  {
    img: img21,
    world_type: "r3f_mr2",
    title: "Meeting Hub",
  },

    {
    img: img10,
    world_type: "r3f_hr",
    title: "Company",
  },

  {
    img: img7,
    world_type: "r3f_showroom",
    title: "Showroom",
  },

  {
    img: img20,
    world_type: "r3f_turkey",
    title: "Turkey",
  },

  {
    img: img11,
    world_type: "r3f_office1",
    title: "Office",
  },

  {
    img: img18,
    world_type: "r3f_office2",
    title: "Workspace",
  },

  {
    img: img8,
    world_type: "r3f_school",
    title: "Education",
  },
  {
    img: img17,
    world_type: "r3f_showroom2",
    title: "Showroom 2",
  },

  {
    img: img3,
    world_type: "r3f_hall",
    title: "Exhibition",
  },
  {
    img: img4,
    world_type: "r3f_hall2",
    title: "Trade Show",
  },

  {
    img: img9,
    world_type: "r3f_tourism",
    title: "Tourism",
  },

  // {
  //   img: img12,
  //   world_type: "germany",
  //   title: "Germany",
  // },

  // {
  //   img: img13,
  //   world_type: "usa",
  //   title: "USA",
  // },

  // {
  //   img: img14,
  //   world_type: "theatre",
  //   title: "Theatre",
  // },

  // {
  //   img: img15,
  //   world_type: "uae",
  //   title: "UAE",
  // },

  // {
  //   img: img16,
  //   world_type: "mixcity",
  //   title: "Mix City",
  // },

  // {
  //   img: img10,
  //   world_type: "r3f_hr",
  //   title: "HR",
  // },

];

export const meetingFeatures = [
  {
    img: meetImg1,
    title: "Avatars",
    featuresList: [
      "	Personalize with unique Avatars",
      "Explore virtual self-to-life integration through Avatars",
      "Enhance visually expressive abilities in meetings",
      "Avatars make virtual interactions visually engaging",
      "Engagement and Connections are enhanced ",
    ],
    ButtonName: "Explore now",
  },
  {
    img: meetImg2,
    title: "Voice Chat",
    featuresList: [
      "Metaverse voice chats are similar to Zoom and Google Meet",
      "Foster collaborations in the virtual world",
      "Mirror real-life meetings through chat engagement",
      "Shift from text to spoken communication",
      "Enhanced voice chat functionalities for seamless collaborations",
    ],
    ButtonName: "Create now",
  },
  {
    img: meetImg3,
    title: "Text Chat",
    featuresList: [
      "Stay Connected for quick updates and conversations",
      "Seamless and self-paced interaction methods keep participants engaged",
      "Versatile voice channels complement text ",
      "Enhance collaboration, share ideas effortlessly",
      "Continuous text chats are available for free in Metaverse",
    ],
    ButtonName: "Explore now",
  },
  {
    img: meetImg4,
    title: "Screenshare",
    featuresList: [
      "Future presentations: 3D Screensharing transform ideas.",
      "Elevate experience: Share decks, and mockups in virtual space.",
      "Engage with immersive, dynamic content for effective communication.",
      "Ditch flat presentations, and embrace interactive 3D for impact.",
      "Capture attention, go beyond traditional screensharing limits.",
    ],
    ButtonName:"Explore now"
  },
  {
    img: meetImg5,
    title: "Dashboard",
    featuresList: [
      "Analyze datasets in real-time through Dashboards ",
      "Engage through communications and brainstorming sessions ",
      "Collective team engagement via interactive dashboards ",
      "Recreate a vibrant and industrious office environment ",
      "Enhanced collaboration through video conferencing platforms ",
    ],
    ButtonName: "Explore now",
  },
  {
    img: meetImg6,
    title: "Share Reaction",
    featuresList: [
      "Virtual reactions enhance communication",
      "Metaverse: Express with diverse Emojis",
      "Emojis convey emotions dynamically",
      "Foster camaraderie in metaverse meetings",
      "Enjoy expressive virtual interactions",
    ],
    ButtonName: "Explore now",
  },
];

export const meetAbout = [
  "Utilize virtual shared spaces with collaboration tools for seamless document and image sharing, as well as real-time audio and text chats, promoting dynamic movement, exploration, creativity, and innovation.",
  "Host virtual meetings for global participation, eliminating the need for physical travel and saving time and money.",
  "Enhance engagement by joining meetings through headsets, desktops, or mobile devices, fostering a sense of presence and connection among participants.",
];

export const MeetBenefits = [
  "Integrate efficient communication features such as voice, text chats, and screen sharing.",
  "Plan and optimize onboarding and training procedures",
  "Design, personalize, and schedule visually appealing office meetings",
  "Emphasize security and privacy considerations for business meets",
  "Establish collaborative workspaces",
];
