import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    totalPages: null,
    postCards: null,
    currentCategory: "all",
    pageNo: 1
}

const PaginationDataSlice = createSlice({
    name: "paginationSlice",
    initialState,
    reducers: {
        setPostcardData: (state, action) => {
            state.totalPages = action.payload.totalPages;
            state.postCards = action.payload.postCards;
        },
        setCurrentCategory: (state, action) => {
            state.currentCategory = action.payload;
        },
        setPageNo: (state, action) => {
            state.pageNo = action.payload;
        }
    }
})

export const {setPostcardData, setCurrentCategory, setPageNo} = PaginationDataSlice.actions;

export default PaginationDataSlice.reducer;