// last updated 18-1-24  //all right 1
import { Suspense, useEffect, useRef } from "react";
import { Canvas } from "@react-three/fiber";
import {
  Environment,
  Html,
  OrbitControls,
  KeyboardControls,
  Bvh,
} from "@react-three/drei";
import { Player } from "../myPlayer/Player";
import { LoadModel } from "../loadModel/LoadModel";
import { VoiceList } from "../Common/voiceList/VoiceList";
import { LoadAnimations } from "../Common/LoadAnimations";
import { OtherPlayer } from "../otherPlayer/OtherPlayer";
import { useSelector } from "react-redux";
import { environmentMapPosition } from "./ExperiencePosition";
import { dotPath } from "../../Files/CommonVariables";
import Wallpaper from "../different/wallPaper/Wallpaper";
import { socket } from "../socketManager/SocketManager";
import { useCreateMyPlayer } from "../socketManager/CreateMyPlayer";
import { DeletePlayer } from "../myPlayer/DeletePlayer";
import { OtherHtml } from "../otherHtml/OtherHtml";
import { useAtom } from "jotai";
import { isModelLoadedAtom } from "../../Files/Jotai";
// import { WaterShaders } from "../shaders/r3f_turkey/WaterShaders";
import { Lights } from "../environment/Lights"; 
// import { startBasicCall } from "../../voice/voice";
import { Shortcut } from "../Common/shortcut/Shortcut";
import { Perf } from "r3f-perf";
import { useControls } from "leva";
import { DynamicAllHtmlScreen } from "../different/htmlScreen/dynamic/DynamicAllHtmlScreen"; 
// import LoadSeqModel from "../temp/seq_lod/LoadSeqModel";

//let
let walkMeshArray = []; //for down
let walkFBMeshArray = [];
let allMeshArray = [];
let sittingMeshArray = [];
let tempEnv = [];

export default function Experience() {
  const createMyPlayer = useCreateMyPlayer();
  //let
  const {
    socket_name: socketName,
    scene_name: sceneName,
    room_name: roomName,
    is_admin: isAdmin,
    room_id: roomId,
  } = useSelector((store) => store.roomInfo);

  //ref
  let bvhRef = useRef();

  //state
  // const [dpr, setDpr] = useState(1.0);
  const [isModelLoadedA, setIsModelLoadedA] = useAtom(isModelLoadedAtom);

  //controls
  // const { envMapHeight, envMapRadius, envMapScale } = useControls('env map', {
  //   envMapHeight: { value: -155, min: -1000, max: 1000 },
  //   envMapRadius: { value: 1000, min: -1000, max: 1000 },
  //   envMapScale: { value: 440, min: -1000, max: 1000 }
  // })

  //hooks
  useEffect(() => {
    // socketName && startBasicCall(roomName, socketName);
  }, [socketName]);

  useEffect(() => {
    if (socket.connected) {
      createMyPlayer();
    }
  }, [socketName]);
  tempEnv = [
    `${dotPath}/R3F/environmentMaps/${sceneName}/px.png`,
    `${dotPath}/R3F/environmentMaps/${sceneName}/nx.png`,
    `${dotPath}/R3F/environmentMaps/${sceneName}/py.png`,
    `${dotPath}/R3F/environmentMaps/${sceneName}/ny.png`,
    `${dotPath}/R3F/environmentMaps/${sceneName}/pz.png`,
    `${dotPath}/R3F/environmentMaps/${sceneName}/nz.png`,
  ];

  return (
    <KeyboardControls
      map={[
        { name: "forward", keys: ["ArrowUp", "KeyW"] },
        { name: "backward", keys: ["ArrowDown", "KeyS"] },
        { name: "leftward", keys: ["ArrowLeft", "KeyA"] },
        { name: "rightward", keys: ["ArrowRight", "KeyD"] },
        { name: "shift", keys: ["Shift"] },
        { name: "key1", keys: ["Digit1"] },
        { name: "key2", keys: ["Digit2"] },
        { name: "jump", keys: ["Space"] },
      ]}
    >
      <Canvas
        shadows
        gl={{ preserveDrawingBuffer: true }}
        style={{
          height: "100vh",
          width: "100vw",
        }}
      // dpr={dpr}
      >
        {/* <PerformanceMonitor
            onIncline={() => setDpr(2)}
            onDecline={() => setDpr(1)}
          ></PerformanceMonitor> */}
        <Suspense
          fallback={
            <Html center>
              <Wallpaper sceneName={sceneName} />
            </Html>
          }
        >
          <LoadAnimations />
          {/* <Perf position="top-left" /> */}
          <Environment
            name="environment"
            files={tempEnv}
            // ground={{ height: envMapHeight, radius: envMapRadius, scale: envMapScale }}
            ground={environmentMapPosition[sceneName]}
          />
          <Lights sceneName={sceneName} />
          <Bvh name="bvhLoadModel" ref={bvhRef} firstHitOnly={true} enabled={true}>
            <LoadModel
              assetName={sceneName}
            />
          </Bvh>
          <Player bvhRef={bvhRef} />
          <DeletePlayer />
          <DynamicAllHtmlScreen />
          <OrbitControls
            name="OrbitControls"
            enableDamping={false}
            enableZoom={false}
            enablePan={false}
            minPolarAngle={Math.PI / 4}
            maxPolarAngle={Math.PI / 2}
          />
          <OtherPlayer />
        </Suspense>
        {/* <BakeShadowsC isModelLoadedA={isModelLoadedA} /> */}
        {/* <WaterShaders isModelLoadedA={isModelLoadedA} sceneName={sceneName} /> */}
      </Canvas>
      <Shortcut />
      <OtherHtml isModelLoadedA={isModelLoadedA} sceneName={sceneName} />
      <VoiceList isModelLoadedA={isModelLoadedA} isAdmin={isAdmin} />

    </KeyboardControls>
  );
}

export {
  walkMeshArray,
  walkFBMeshArray,
  allMeshArray,
  sittingMeshArray,
  tempEnv,
};
