import { useSelector } from "react-redux";
import { useFrame } from "@react-three/fiber";
import { useRef } from "react";
import { showSittingIndicatorAtom } from "../../../Files/Jotai";
import { useAtom } from "jotai";
import { sittingMeshArray } from "../../experience/Experience";

const SittingCube = ({ chairPosition }) => {
  const [showSittingIndicatorA, setShowSittingIndicatorA] = useAtom(
    showSittingIndicatorAtom
  );

  const chairRef = useRef();
  useFrame((state, delta) => {
    chairRef.current.position.y += 0.004 * Math.sin(Date.now() / 500);
  });

  return (
    <mesh ref={chairRef} rotation-x={-Math.PI / 2} position={chairPosition}>
      <ringGeometry args={[0.1, 0.2, 32]} />
      <meshStandardMaterial color="yellow" opacity={0.5} transparent={true} />
    </mesh>
  );
};

const Sitting = () => {
  const {
    avtar_id: avtarId,
    socket_name: socketName,
    room_name: roomName,
    scene_name: sceneName,
  } = useSelector((store) => store.roomInfo);

  return (
    <>
      {sittingMeshArray &&
        sittingMeshArray.map((chair, index) => {
          let chairPosition = chair.position;
          return <SittingCube key={index} chairPosition={chairPosition} />;
        })}
    </>
  );
};

export { Sitting };
