import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { callDecodeToken, callFacebookLogin, callGoogleLogin } from "../../common/axiosCalls";
import { setAuthentication, setUserDetails } from "../../features/Authentication/AuthenticationSlice";
import { setToast } from "../../features/ErrorHandling/ErrorHandlingSlice";
import { setLikesArray } from "../../features/UserLikes/UserLikesSlice";
import styles from "./LoadingScreen.module.css";

const LoadingScreen = ({loginFrom}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

    const loginHandler = async(token) => {

      try {
        localStorage.setItem('token', token);
        const decodeTokenData = await callDecodeToken();
        dispatch(setAuthentication(true));
        dispatch(setUserDetails(decodeTokenData.data.Data));
        dispatch(setLikesArray(decodeTokenData.data.rooms_liked_by_me));
        navigate("/my-metaverse");

      } catch (error) {
        
      }
    }   


  useEffect(() => {
    const url = new URL(window.location.href);
    const parameters = new URLSearchParams(url.search);
    const code = parameters.get("code");
    if(loginFrom === 'facebook') {
      callFacebookLogin({authorization_code: code
      }).then((res) => {
        loginHandler(res.data.data);
      }).catch((err) => {
        dispatch(setToast({
          type: 'error',
          message: err
        }))
      });
      return;
    } else {
      callGoogleLogin({ authorization_code: code }).then((res) => {
        loginHandler(res.data.data)
      });
    }
        
  }, []);

  return <div className={styles["loading-div"]} />;
};

export default LoadingScreen;
