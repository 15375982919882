import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  avtar_id: "",
  avtar_name: "",
  room_type: "",
  room_name: "",
  is_admin: "",
  room_id: "",
  scene_name: "",
  socket_name: "",
};

const RoomInfoDataSlice = createSlice({
  name: "roomInfoDataSlice",
  initialState,
  reducers: {
    setRoomInfo: (state, action) => {
      state.avtar_id = action.payload.avtar_id;
      state.avtar_name = action.payload.avtar_name;
      state.room_type = action.payload.room_type;
      state.room_name = action.payload.room_name;
      state.is_admin = action.payload.is_admin;
      state.room_id = action.payload.room_id;
      state.scene_name = action.payload.scene_name;
      state.socket_name = action.payload.socket_name;
    },
  },
});

export const { setRoomInfo } = RoomInfoDataSlice.actions;
export default RoomInfoDataSlice.reducer;
